import { css } from 'lit';

export const guildViewStyles = css`
  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 70%, var(--yellow-2) 100%);
  }

  .guild-container {
    margin: 0 auto;
    padding: 1rem;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }

  .guild-details {
    margin-top: 1rem;
  }

  .description {
    margin: 1rem 0;
    white-space: pre-wrap;
  }
`;