import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { memberInviteViewStyles } from './member-invite-view-styles.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';

export class MemberInviteView extends ViewBase {
  static styles = [
    ...super.styles,
    memberInviteViewStyles,
  ];

  static properties = {
    ...super.properties,
  };
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Invitation Builder - q1 Network');
  }

  get contentTemplate() {
    return html`
      <h1>Member Invite</h1>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
      </p>
    `;
  }
}

customElements.define('member-invite-view', MemberInviteView);
