import { html } from 'lit';

export const setupRoutes = (page, window) => {
  page('/', (c) => routeTo('home-public', 'public', c)); // FIXME >> Contemplate public-only
  page('/home/confirm', (c) => routeTo('home-confirm', 'members', c));
  page('/home/onboard', (c) => routeTo('home-onboard', 'members', c));
  page('/home', (c) => routeTo('home-signed-in', 'members', c));
  page('/signin', (c) => routeTo('sign-in', 'public-only', c));
  page('/join', (c) => routeTo('join', 'public', c));
  page('/register', (c) => routeTo('register', 'public-only', c));
  page('/claim', (c) => routeTo('claim', 'public-only', c));
  page('/invite', (c) => routeTo('member-invite', 'members', c));
  page('/settings', (c) => routeTo('settings', 'members', c));
  page('/settings/:key', (c) => routeTo('settings', 'members', c));
  page('/m/verify-email', (c) => routeTo('verify-email', 'public', c));
  page('/m/verify-phone', (c) => routeTo('verify-phone', 'public', c));
  page('/m/reset-pw', (c) => routeTo('reset-password', 'public', c));
  page('/a/guilds', (c) => routeTo('admin-guilds', 'admins', c));
  page('/a/projects', (c) => routeTo('admin-projects', 'admins', c));
  page('/a/places', (c) => routeTo('admin-places', 'admins', c));
  page('/a/updates', (c) => routeTo('admin-updates', 'admins', c));
  page('/a/roles', (c) => routeTo('admin-roles', 'admins', c));
  page('/a/deliverables', (c) => routeTo('admin-deliverables', 'admins', c));
  page('/a/members', (c) => routeTo('admin-members', 'admins', c));
  page('/a', (c) => routeTo('admin-index', 'admins', c));
  page('/members', (c) => routeTo('member-index', 'public', c));
  page('/guilds', (c) => routeTo('guild-index', 'public', c));
  page('/guilds/:key', (c) => routeTo('guild-view', 'public', c));
  page('/guilds/:key/join', (c) => routeTo('guild-join-view', 'public', c));
  page('/guilds/my', (c) => routeTo('profiles-manage-view', 'members', c));
  page('/projects', (c) => routeTo('project-index', 'public', c));
  page('/projects/:key', (c) => routeTo('project-view', 'public', c));
  page('/projects/:key/join', (c) => routeTo('project-join-view', 'public', c));
  page('/@:key', (c) => routeTo('member-profile-view', 'public', c));
  page('/:key/my', (c) => routeTo('profile-edit-view', 'members', c));
  page('/:key', (c) => routeTo('guild-view', 'public', c));

  page('*', (c) => {
    console.log('Unmatched route:', c.path);
    routeTo('not-found-view', 'public', c);
  });

  // Start the router
  return page({ window });
};

/**
 * Tells the app element to display a particular view.
 * 
 * @param {string} routeKey - The unique key of the route.
 * @param {string} access - Controls who can access this route.
 *   - `public`: For routes available when signed out.
 *   - `public-only`: For routes ONLY available when signed out.
 *   - `members`: For routes restricted to signed in members.
 *   - `admins`: For routes restricted to signed in admins.
 * @param {object} context - The Page.js context variable.
 */
const routeTo = (routeKey, access, context) => {
  console.log('routeTo, routeKey:', routeKey);

  const routeParams = context?.params || null;
  const appElement = document.querySelector('q1-home');
  const memberStatus = appElement.currentMember?.status;
  
  if (!appElement) return;

  // First detect all conditions where we would need to re-route

  if ((routeKey === 'home-confirm') && (memberStatus !== 'registered'))
    return sendTo('/home');
  
  if ((routeKey === 'home-onboard') && (memberStatus !== 'confirmed'))
    return sendTo('/home');

  if ((routeKey !== 'home-confirm') && (access === 'members') && (memberStatus === 'registered'))
    return sendTo('/home/confirm', 'Member confirmation needed to finalize your membership.');
  
  if ((routeKey !== 'home-onboard') && (access === 'members') && (memberStatus === 'confirmed'))
    return sendTo('/home/onboard', 'Please complete the new member onboarding steps.');
  
  if (!appElement.isSignedIn && ['members', 'admins'].includes(access))
    return sendTo('/signin', 'You must be signed in to access that page');
  
  if (!appElement.isAdmin && (access === 'admins'))
    return sendTo('/home'); // no toast for admin pages
  
  if (appElement.isSignedIn && (access === 'public-only'))
    return sendTo('/home', 'That page is not accessible while you\'re signed in');

  // Else render the specified view
  appElement.routeParams = routeParams;
  appElement.currentRoute = routeKey;
  appElement.updateComplete.then(() => {
    window.scrollTo({ top: 0 }); // important to snap to top of new view
  });
};

/**
 * Redirects to new path name and queues the toast message.
 * 
 * @param {string} path - Required. Example: `/projects/abc123`
 * @param {string} toastMessage - Optional. If included, a toast is queued for display.
 * 
 * IMPROVEME: The navigateTo() calls aren't changing the URL, so the queueToast() is a workaround
 * to show toast after a full page reload. It would be better to not requite a page reload.
 */
const sendTo = (path, toastMessage) => {
  if (toastMessage?.length) document.querySelector('q1-home').queueToast(toastMessage);
  
  window.location.pathname = path;

  return true;
};

// IMPROVEME >> Consider refactoring this structure. Having the router send the route to q1-home
//   then having the execution flow come back in here feels like it could be simplified.

export const appRender = ({
  currentRoute, routeParams, currentUser, currentMember, isSignedIn, isAdmin,
}) => {
  switch (currentRoute) {
    
    case 'home-public':
      return html`
        <home-public-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></home-public-view>`;
    
    case 'home-signed-in':
      return html`
        <home-signed-in-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></home-signed-in-view>`;
    
    case 'home-confirm':
      return html`
        <home-confirm-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></home-confirm-view>`;
    
    case 'home-onboard':
      return html`
        <home-onboard-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></home-onboard-view>`;
    
    case 'sign-in':
      return html`
        <sign-in-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></sign-in-view>`;
    
    case 'join':
      return html`
        <join-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></join-view>`;
    
    case 'register':
      return html`
        <register-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></register-view>`;
    
    case 'claim':
      return html`
        <claim-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></claim-view>`;
    
    case 'verify-email':
      return html`
        <verify-email-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></verify-email-view>`;
    
    case 'verify-phone':
      return html`
        <verify-phone-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></verify-phone-view>`;
    
    case 'reset-password':
      return html`
        <reset-password-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></reset-password-view>`;
    
    case 'member-invite':
      return html`
        <member-invite-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></member-invite-view>`;
    
    case 'settings':
      return html`
        <settings-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></settings-view>`;
    
    case 'admin-index':
      return html`
        <admin-index-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-index-view>`;
    
    case 'admin-guilds':
      return html`
        <admin-guilds-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-guilds-view>`;
    
    case 'admin-projects':
      return html`
        <admin-projects-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-projects-view>`;
    
    case 'admin-places':
      return html`
        <admin-places-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-places-view>`;
    
    case 'admin-updates':
      return html`
        <admin-updates-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-updates-view>`;
    
    case 'admin-roles':
      return html`
        <admin-roles-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-roles-view>`;
    
    case 'admin-deliverables':
      return html`
        <admin-deliverables-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-deliverables-view>`;
    
    case 'admin-members':
      return html`
        <admin-members-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></admin-members-view>`;
    
    case 'member-index':
      return html`
        <member-index-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></member-index-view>`;
    
    case 'guild-index':
      return html`
        <guild-index-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></guild-index-view>`;
    
    case 'guild-view':
      return html`
        <guild-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></guild-view>`;
    
    case 'guild-join-view':
      return html`
        <guild-join-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></guild-join-view>`;
    
    case 'profiles-manage-view':
      return html`
        <profiles-manage-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></profiles-manage-view>`;
    
    case 'member-profile-view':
      return html`
        <member-profile-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></member-profile-view>`;
    
    case 'profile-edit-view':
      return html`
        <profile-edit-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></profile-edit-view>`;
    
    case 'project-view':
      return html`
        <project-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></project-view>`;
    
    case 'project-join-view':
      return html`
        <project-join-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></project-join-view>`;
    
    case 'project-index':
      return html`
        <project-index-view
          .currentUser="${currentUser}" .currentMember="${currentMember}"
          .isSignedIn="${isSignedIn}" .isAdmin="${isAdmin}"
          .routeParams="${routeParams}"
        ></project-index-view>
      `;
    
    default:
      return html`<p>Page Not Found</p>`;

  }
}
