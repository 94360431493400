import { css } from 'lit';

export const viewStyles = css`

  /*=== VIEW HOST ===*/

  :host {
    display: block;
    background: var(--gray-1);

    font-family: 'system-ui', sans-serif;
    line-height: 1.5em;

    /* VIEW VARIABLES */
    --q1-view-width: var(--q1-content-width-normal);
  }

  /*=== UTILITY CLASSES ===*/

  .hide-until-bp0,
  .hide-until-bp1,
  .hide-until-bp2,
  .hide-until-bp3,
  .hide-until-bp4 {
    display: none;
  }
  @media(min-width: 400px) { .hide-until-bp0 { display: unset; } }
  @media(min-width: 500px) { .hide-until-bp1 { display: unset; } }
  @media(min-width: 650px) { .hide-until-bp2 { display: unset; } }
  @media(min-width: 1000px) { .hide-until-bp3 { display: unset; } }
  @media(min-width: 1500px) { .hide-until-bp4 { display: unset; } }

  /*=== LAYOUT ===*/

  view-content {
    display: block;
    margin: 0 auto;
    padding: 8rem 2rem 4rem 2rem;
    
    box-sizing: content-box;
    max-width: var(--q1-view-width);
    min-height: 60vh;
  }

  view-content hr {
    border: 0;
    border-top: 1px solid var(--gray-4);
    margin: 2.5rem 0;
    display: block;
  }

  view-content p.subtitle {
    margin: 2.5rem 0;
    display: block;
    font-size: 1.1rem;
  }

  view-content sl-alert {
    margin: 2.5rem 0;
  }
  @media(min-width: 1000px) {
    view-content sl-alert {
      margin: 3.5rem 0;
    }
  }

  /*=== HEADER STYLES ===*/

  view-content h1,
  view-content h2,
  view-content h3 {
    line-height: 1.3em;
  }


  /*=== FORM CUSTOM ELEMENTS ===*/

  field-spacer {
    display: block;
  }
  field-pair {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 0;
    margin: 1.5rem 0;
  }
  field-pair input-wrapper,
  field-pair sl-input,
  field-pair sl-textarea,
  field-pair q1-tel-input,
  field-pair q1-places-input,
  field-pair q1-image-upload-input,
  field-pair field-spacer {
    flex: 1 1 15rem;
    min-width: min(15rem, 100%);
  }


  /*=== STANDARD OVERRIDES ===*/

  a {
    color: var(--link-color-default, var(--blue-7));
    text-decoration: var(--link-text-decoration-default, underline);
    transition: all 0.2s ease-in-out;
  }
  a:visited {
    color: var(--link-color-visited, var(--blue-8));
  }
  a:focus, a:hover {
    color: var(--link-color-focus, var(--blue-9));
    text-decoration: var(--link-text-decoration-focus, underline);
  }

  /*=== TABLE STYLES ===*/

  table.q1 {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: none;

    /* Note: Borders are applied on cells not table */
    --table-border-radius: 7px;
    --table-border-width: 1px;
    --table-border-style: solid;
    --table-outer-border-color: var(--gray-3);
    --table-inner-border-color: var(--gray-2);

    --table-cell-padding: var(--sl-spacing-medium);
    
    --table-th-bg-color: var(--gray-3);
    --table-th-color: var(--gray-9);
    --table-th-font-size: var(--sl-font-size-medium);
    --table-th-font-weight: var(--sl-font-weight-semibold);
    
    --table-td-bg-color: var(--gray-0);
    --table-td-bg-stripe-color: var(--gray-1);
    --table-td-bg-focus-color: var(--yellow-0);
    --table-td-color: var(--gray-7);
    --table-td-font-size: var(--sl-font-size-medium);
    --table-td-font-weight: var(--sl-font-weight-normal);

    /* Internal Variables - Do not change */

    --table-outer-border:
      var(--table-border-width) var(--table-border-style) var(--table-outer-border-color);
    --table-inner-border:
      var(--table-border-width) var(--table-border-style) var(--table-inner-border-color);
  }

  table.q1 th:first-child { border-top-left-radius: var(--table-border-radius); }
  table.q1 th:last-child { border-top-right-radius: var(--table-border-radius); }
  table.q1 tr:last-child td:first-child { border-bottom-left-radius: var(--table-border-radius); }
  table.q1 tr:last-child td:last-child { border-bottom-right-radius: var(--table-border-radius); }

  table.q1 th:first-child,
  table.q1 td:first-child {
    border-left: var(--table-outer-border);
  }
  table.q1 th:last-child,
  table.q1 td:last-child {
    border-right: var(--table-outer-border);
  }

  table.q1 th {
    text-align: left;
    white-space: nowrap;
    
    padding: var(--table-cell-padding);
    border-top: var(--table-outer-border);
    border-bottom: var(--table-outer-border);

    background-color: var(--table-th-bg-color);
    color: var(--table-th-color);
    font-size: var(--table-th-font-size);
    font-weight: var(--table-th-font-weight);
  }

  table.q1 td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 15rem;

    padding: var(--table-cell-padding);
    border-bottom: var(--table-inner-border);

    background-color: var(--table-td-bg-color);
    color: var(--table-td-color);
    font-size: var(--table-td-font-size);
    font-weight: var(--table-td-font-weight);

    transition: all 0.2s ease-in-out;
  }
  table.q1.striped tbody tr:nth-child(even) td {
    background-color: var(--table-td-bg-stripe-color);
  }
  table.q1 tbody tr:focus td,
  table.q1 tbody tr:hover td {
    background-color: var(--table-td-bg-focus-color);
  }
  table.q1 tr:last-child td {
    border-bottom: var(--table-outer-border);
  }
  table.q1 td.full-width {
    max-width: none;
  }

  table.q1 td.actions {
    padding: var(--sl-spacing-x-small);
  }
  table.q1 td.actions sl-icon-button {
    font-size: 1.3rem;
  }

  /*=== FORM STYLES ===*/

  form.q1 h2 {
    margin: 2.5rem 0;
  }

  form.q1 sl-input,
  form.q1 sl-textarea {
    line-height: 1.1rem;
  }
  form.q1 sl-input:not(:last-child),
  form.q1 sl-textarea:not(:last-child),
  form.q1 q1-tel-input:not(:last-child),
  form.q1 input-wrapper:not(:last-child),
  form.q1 q1-image-upload-input:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  form.q1 label,
  form.q1 sl-input::part(form-control-label) {
    display: block;
    margin-bottom: 0.5rem;
  }
  form.q1 sl-input,
  form.q1 sl-textarea,
  form.q1 q1-tel-input,
  form.q1 q1-places-input,
  form.q1 q1-image-upload-input {
    --sl-spacing-3x-small: 0.5rem;
  }

  form.q1 sl-alert {
    margin: 2rem 0;
  }

  /*=== REUSABLE Q1 CUSTOM CLASSES & CSS ELEMENTS ====*/

  notice-panel {
    display: flex;
    align-items: center;
    justify-content: center;

    --np-border-color: var(--gray-4);
    --np-background: var(--gray-0);
    --np-color: var(--gray-7);
    --np-icon-color: var(--gray-5);
    --np-icon-font-size: 5rem;
    --np-h2-font-size: 1.5rem;
    --np-box-shadow: var(--sl-shadow-medium);
    --np-max-width: 23rem;
  }
  notice-panel.hero {
    --np-max-width: 35rem;
  }
  notice-panel .inner {
    padding: 1.5rem;
    text-align: center;
    border-radius: 12px;
    max-width: var(--np-max-width);

    border: 1px solid var(--np-border-color);
    background: var(--np-background);
    box-shadow: var(--np-box-shadow);
    color: var(--np-color);
  }
  notice-panel .inner sl-icon {
    font-size: var(--np-icon-font-size);
    color: var(--np-icon-color);
  }
  notice-panel.hero .inner sl-icon {
    --np-icon-font-size: 7rem;
  }
  notice-panel .inner h2 {
    margin: 1rem 0;
    font-size: var(--np-h2-font-size);
    line-height: 1.5em;
  }
  notice-panel .inner sl-button {
    margin: 1rem 0;
  }

  notice-panel.primary {
    --np-background: var(--yellow-0);
    --np-border-color: var(--yellow-3);
    --np-icon-color: var(--yellow-6);
  }
  notice-panel.secondary {
    --np-background: var(--teal-0);
    --np-border-color: var(--teal-3);
    --np-icon-color: var(--teal-6);
  }
  notice-panel.danger {
    --np-background: var(--red-0);
    --np-border-color: var(--red-3);
    --np-icon-color: var(--red-6);
  }
  notice-panel.success {
    --np-background: var(--green-0);
    --np-border-color: var(--green-3);
    --np-icon-color: var(--green-6);
  }
  

  button-bar {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  input-wrapper {
    display: block;
  }

  /*=== SHOELACE OVERRIDES ====*/

  sl-alert::base {
    border-radius: 12px;
  }
  sl-alert[variant='primary']::part(base) {
    border: 1px solid var(--yellow-3);
    background: var(--yellow-0);
  }
  sl-alert[variant='warning']::part(base) {
    border: 1px solid var(--teal-2);
    background: var(--teal-0);
  }
  sl-alert[variant='danger']::part(base) {
    border: 1px solid var(--red-3);
    background: var(--red-0);
  }
  sl-alert[variant='success']::part(base) {
    border: 1px solid var(--green-3);
    background: var(--green-0);
  }
  sl-alert[variant='neutral']::part(base) {
    border: 1px solid var(--gray-3);
    background: var(--gray-0);
  }

  sl-button[variant=primary]::part(base) {
    color: var(--gray-9);
  }
  
  sl-button.secondary::part(base) {
    background: var(--teal-6);
  }

  sl-button.elevated::part(base) {
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: var(--sl-shadow-medium);
  }
  sl-button.elevated:hover::part(base),
  sl-button.elevated:focus::part(base) {
    box-shadow: var(--sl-shadow-x-large);
  }

  sl-button.jumbo::part(base) {
    padding: 1rem 1.5rem;
  }
  sl-button.jumbo::part(label) {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6em;
  }

  sl-select .inner-label {
    white-space: nowrap; /* prevent prefix label from wrapping */
  }

  sl-tab {
    overflow: hidden;
  }

`;
