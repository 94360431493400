import { css } from 'lit';

export const q1VerificationInfoStyles = css`

  :host {
    display: block;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    background: white;
    border-radius: 7px;
    border: 1px solid var(--yellow-3);
  }
  
  ul#buttons {
    list-style: none;
    margin: 1rem 0 0 0;
    padding: 1rem 1.5rem;
    display: flex
;
    background: var(--yellow-1);
    border-top: 1px solid var(--yellow-3);
  }
  ul#buttons li {
    display: block;
    flex: 0 0 auto;
    padding: 0;
    margin: 0 1rem 0 0;
  }

  refreshing-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba(255, 255, 255, 0.8);
  }
  refreshing-panel > div {
    display: flex;
    width: 20rem;
    margin: 0 auto;
    text-align: center;
    padding: 1.2rem;
    background: var(--sl-color-gray-100);
    border-radius: 7px;
    font-size: 1.2rem;
    font-weight: 500;
    justify-content: center;
    align-items: center;
  }
  refreshing-panel sl-spinner {
    margin-right: 1rem;
    font-size: 1.7rem;
  }

  table {
    width: 100%;
    font-size: 1.1rem;
    line-height: 1em;
    margin: 1rem 0;
  }
  table tbody td {
    padding: 0.5rem 0.25rem;
    vertical-align: middle;
  }

  td.prefix {
    display: inline-block;
    width: 3rem;
    padding-left: 1.5rem;
    color: var(--gray-6);
  }
  td.value {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100vw - 12rem); /* makes overflow ellipsis work */
  }
  td.suffix {
    display: inline-block;
    width: 1rem;
  }
  td.actions {
    display: block;
    width: 17rem;
    padding: 0.5rem 0.25rem 1rem 1.5rem;
  }
  @media(min-width: 650px) {
    td.prefix, td.value, td.suffix, td.actions {
      display: table-cell;
    }
    td.value {
      max-width: 0; /* makes overflow ellipsis work */
    }
    td.actions {
      padding: 0.5rem 0.25rem;
    }
  }

  td.suffix sl-icon {
    color: var(--orange-6);
  }
  td.suffix sl-icon.verified {
    color: var(--green-6);
  }
  
  tr.primary td.value {
    font-weight: bold;
  }
  tr.primary td.prefix {
    color: var(--gray-9);
  }

`;
