import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { adminProjectsViewStyles } from './admin-projects-view-styles.js';
import { functions, httpsCallable } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/option/option.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/color-picker/color-picker.js';
import '../../components/q1-upload-input/q1-upload-input.js'

import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';

import {
  db, doc, updateDoc, getDoc, getDocs, deleteDoc, collection, onSnapshot, getStorage, storageRef, 
  uploadBytes, getDownloadURL, query, orderBy, limit,
} from '../../firebaseConfig.js';

export class AdminProjectsView extends ViewBase {
  static styles = [
    ...super.styles,
    adminProjectsViewStyles
  ];

  static properties = {
    ...super.properties,

    projectRecords: { type: Array },
    projectRecord: { type: Object },
    viewMode: { type: String }, // 'details' or 'edit'
    toastMessage: { type: String },
    alertVisible: { type: Boolean },
    alertType: { type: String },
    isLoading: { type: Boolean },

  };

  constructor() {
    super();
    this.projectRecords = [];
    this.projectRecord = {};
    this.viewMode = 'details'; // Default to details view
    this.toastMessage = '';
    this.alertVisible = false;
    this.alertType = 'info';
    this.isLoading = false;
  };
  
  connectedCallback() {
    super.connectedCallback();
    
    this.setDocumentTitle('Q1 ADMIN: Projects');

    this.refreshProjects();
    this.initLists();
  };

  async initLists() {
    const getGuilds = httpsCallable(functions, 'list-getGuilds');

    try {
      const guildsResult = await getGuilds();
      this.guilds = guildsResult.data.guilds || [];
      console.log('Guilds:', this.guilds);
    } catch (error) {
      console.log('Error getting Guilds:', error.message);
    }
  }

  refreshProjects() {
    this.isLoading = true;
    this.projectRecords = [];
  
    const projectsCollection = collection(db, 'projects');
    const projectsQuery = query(projectsCollection, orderBy('name'), limit(100));
    
    getDocs(projectsQuery).then((projectsSnapshot) => {
      
      this.projectRecords = projectsSnapshot.docs.map((projectDoc) => ({
        ref: projectDoc.ref,
        data: {
          id: projectDoc.id,
          ...projectDoc.data(),
        },
      }));
      
      console.log('Updated projectRecords:', this.projectRecords);
      this.isLoading = false;
      
    }).catch((error) => {
      console.error(`Error fetching projects:`, error.message);

      this.showToast('', 'Error Loading Projects', 'danger');
      this.isLoading = false;
    });
  }

  async openProjectDetails(projectRecord) {
    try {
      console.log('project record', projectRecord)
      onSnapshot(projectRecord.ref, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const freshData = docSnapshot.data();
          console.log('fresh data:', freshData);

          this.projectRecord = {
            ref: projectRecord.ref,
            data: {
              name: freshData.name|| '',
              description: freshData.description || '',
              pointNumber: freshData.pointNumber || '',
              betaOrganizersNumbers: freshData.betaOrganizersNumbers || [],
              iconNewUploadRef: freshData.iconNewUploadRef || '',
              bannerNewUploadRef: freshData.bannerNewUploadRef || '',
              color: freshData.color || '',
              visibility: freshData.visibility || '',
              guilds: freshData.guilds || [],
              guildCount: freshData.guildCount || '',
              followerCount: freshData.followerCount || '',
              point: freshData.point || '',
            },
          };
  
          this.viewMode = 'details';
          this.requestUpdate();
        } else {
          console.warn('Document does not exist for real-time listener.');
        }
      });
  
      this.shadowRoot.querySelector('#project-details-dialog').show();
    } catch (error) {
      console.error('Error setting up real-time listener for project data:', error);
    }
  }

  async updateProject() {
    try {
        await updateDoc(this.projectRecord.ref, this.projectRecord.data);
  
        this.showToast('Project updated successfully!', null, 'success');
  
        const updatedSnapshot = await getDoc(this.projectRecord.ref);
        this.projectRecord.data = {
          ...updatedSnapshot.data(),
        };
  
        this.requestUpdate();
  
        this.refreshProjects();
  
        this.viewMode = 'details';
      } catch (error) {
        this.showToast('', 'Error updating project.', 'danger', 10000);
        console.error('Error updating project:', error);
      }
  }

  switchToEditMode() {
    this.viewMode = 'edit';
  }

  handleInput(event) {
    const fieldName = event.target.name || event.target.id; // id needed for non-form data fields
    const fieldValue = event.target.value;

    if (fieldName) {
      if (fieldValue === "") {
        this.projectRecord.data[fieldName] = null;
      } else {
        this.projectRecord.data[fieldName] = fieldValue;
      }      
    }

    if (!!fieldName) {
      this.projectRecord.data[fieldName] = fieldValue;
    }
  }

  handleGuilds(event) {
    const selectedGuildIds = Array.from(event.target.selectedOptions).map(
      (option) => option.value
    );
  
    const selectedGuilds = this.guilds.filter((guild) =>
      selectedGuildIds.includes(guild.id)
    );
  
    this.projectRecord.data.guilds = selectedGuilds.map(
      (guild) => ({ id: guild.id, name: guild.name })
    );
  
    this.requestUpdate();
  }
  
  get contentTemplate() {
    return html`
      <h1>Project Update Admin Portal</h1>
      <sl-button
        @click=${this.refreshProjects}
      >
        <sl-icon library="material" name="refresh" slot="prefix"></sl-icon>
        Refresh
      </sl-button>

      <content-container>

        ${!!this.projectRecords.length ? this.renderProjectsTable() : html`
          <p class="subtitle">
            No project records yet.
          </p>
        `}
        
        <q1-spinner-panel label="Loading..." no-bg ?open=${this.isLoading}></q1-spinner-panel>

      </content-container>
      
      <sl-dialog label='Project Details' id='project-details-dialog'>
        ${this.projectRecord?.data ? this.renderDialogContent() : ''}
        ${this.viewMode === 'details' ? html`
          <sl-button slot='footer' variant='primary' @click="${this.switchToEditMode}">
            Edit
          </sl-button>
        ` : html`
          <sl-button slot='footer' variant='primary' @click="${this.updateProject}">
            Save
          </sl-button>
          <sl-button slot='footer' variant='danger' @click="${this.deleteProject}">
            Delete
          </sl-button>
        `}
        <sl-button
          slot='footer'
          variant='default'
          @click="${() => this.shadowRoot.querySelector('#project-details-dialog').hide()}"
        >
          Close
        </sl-button>
      </sl-dialog>
    `;
  }

  renderProjectsTable() {
    return html`
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Point Number</th>
            <th>Organizer Numbers(Beta)</th>
            <th>Color</th>
            <th>Visibility</th>
            <th>Guilds</th>
            <th>Guild Count</th>
            <th>Follwer Count</th>
            <th>Point</th>
            <th>Organizers (Beta)</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Icon</th>
            <th>Banner</th>
            <th>Stats Update Info</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          ${this.projectRecords.map(
            (projectRecord) => html`
              <tr>
                <td>${projectRecord?.data?.name}</td>
                <td>${projectRecord?.data?.description}</td>
                <td>${projectRecord?.data?.pointNumber}</td>
                <td>${projectRecord?.data?.betaOrganizersNumbers}</td>
                <td>${projectRecord?.data?.color}</td>
                <td>${projectRecord?.data?.visibility}</td>
                <td>${(projectRecord?.data?.guilds || [])
                  .map((guild) => guild.name)
                  .join(', ')}</td>
                <td>${projectRecord?.data?.guildCount}</td>
                <td>${projectRecord?.data?.followerCount}</td>
                <td>${projectRecord?.data?.point}</td>
                <td>${projectRecord?.data?.betaOrganizers}</td>
                <td>${projectRecord?.data?.createdAt}</td>
                <td>${projectRecord?.data?.updatedAt}</td>
                <td>${projectRecord?.data?.icon}</td>
                <td>${projectRecord?.data?.banner}</td>
                <td>${projectRecord?.data?.statsUpdateInfo}</td>
                <td>
                <sl-button
                  size="small"
                  @click="${() => this.openProjectDetails(projectRecord)}"
                >
                  View
                </sl-button>
                </td>
              </tr>
            `
          )}
        </tbody>
      </table>
    `;
  }

  renderDetailedView() {
    return html`
    <div class='form-group'>
      <table>
        <tbody>
          <h3>Basic Info</h3>
          <tr>
            <td><strong>Name:</strong></td>
            <td>${this.projectRecord?.data?.name}</td>
          </tr>
          <tr>
            <td><strong>Description:</strong></td>
            <td>${this.projectRecord?.data?.description}</td>
          </tr>
          <tr>
            <td><strong>Point Number:</strong></td>
            <td>${this.projectRecord?.data?.pointNumber}</td>
          </tr>
          <tr>
            <!-- //FIXME - what is Array[String: Member Number Format]??? -->
            <td><strong>Beta Organizer Numbers:</strong></td>
            <td>${this.projectRecord?.data?.betaOrganizersNumbers}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <h3>Settings</h3>
          <tr>
            <td><strong>Color:</strong></td>
            <td>${this.projectRecord?.data?.color}</td>
          </tr>
          <tr>
            <td><strong>Visibility:</strong></td>
            <td>${this.projectRecord?.data?.visibility}</td>
          </tr>
        </tbody>
      </table>        
      <table>
        <tbody>
          <h3>Guilds</h3>
            <tr>
              <td><strong>Guilds:</strong></td>
              <td>
                ${(this.projectRecord?.data?.guilds || [])
                  .map((guild) => guild.name)
                  .join(', ')}
              </td>
            </tr>
            <tr>
              <td><strong>Guild Count:</strong></td>
              <td>${this.projectRecord?.data?.guildCount}</td>
            </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <h3>Members</h3>
          <tr>
            <td><strong>Follower Count:</strong></td>
            <td>${this.projectRecord?.data?.followerCount}</td>
          </tr>
        </tbody>
      <table>
        <tbody>
          <h3>System Fields</h3> 
            <tr>
              <td><strong>Icon:</strong></td>
              <td>${this.projectRecord?.data?.icon}</td>
            </tr>
            <tr>
              <td><strong>Banner:</strong></td>
              <td>${this.projectRecord?.data?.banner}</td>
            </tr>
        </tbody>
      </table>
  </div>
    `;
  }

  renderEditForm() {
    return html`
      <form>

        <h3>Basic Info</h3>

        <sl-input 
          id="name" 
          name="name" 
          .value="${this.projectRecord?.data?.name}"
          @sl-input="${this.handleInput}"
          label="Name" 
          required
        ></sl-input>

        <sl-input 
          id="description" 
          name="description" 
          .value="${this.projectRecord?.data?.description}"
          @sl-input="${this.handleInput}"
          label="Description"
          required 
        ></sl-input>

        <sl-input 
          id="pointNumber" 
          name="pointNumber" 
          .value="${this.projectRecord?.data?.pointNumber}"
          @sl-input="${this.handleInput}"
          label="Point Number"
          required
        ></sl-input>

        <sl-input 
          id="betaOrganizersNumbers" 
          name="betaOrganizersNumbers" 
          .value="${this.projectRecord?.data?.betaOrganizersNumbers}"
          @sl-input="${this.handleInput}"
          label="Organizer Numbers (Beta)"
          required
        ></sl-input>

        <h3>Settings</h3>

        <q1-upload-input
          name="iconNewUploadRef"
          .projectRecord="${this.projectRecord}"
        ></q1-upload-input>

        <q1-upload-input
          name="bannerNewUploadRef"
          .projectRecord="${this.projectRecord}"
        ></q1-upload-input>

        <sl-color-picker
          id="color"
          name="color"
          format="hex" 
          label="Select Color"
          value="${this.projectRecord?.data?.color || '#000000'}" 
          @sl-change="${this.handleInput}"
        ></sl-color-picker>

        <sl-select 
          id="visibility"
          name="visibility"
          value="${this.memberRecord?.data?.visibility}"
          @sl-input="${this.handleInput}"
          label="Visibility"
          required
          >
            <sl-option value="public">Public</sl-option>
            <sl-option value="network">Network</sl-option>
            <sl-option value="link">Link</sl-option>
            <sl-option value="private">Private</sl-option>
        </sl-select>
        
        <h3>Guilds</h3>

        <label for="guildSelect">Guilds</label>
        <sl-select
          id="guildSelect"
          name="guilds"
          multiple
          clearable
          hoist
          @sl-change="${this.handleGuilds}"
        >
          ${this.guilds?.map(
            (guild) =>
              html`<sl-option value="${guild.id}">${guild.name}</sl-option>`
          )}
        </sl-select>

      </form>
  `;
  }

  renderDialogContent() {
    return this.viewMode === "details"
      ? this.renderDetailedView()
      : this.renderEditForm();
  }

  renderToast() {
    return html`
      <sl-toast id="notification-toast" duration="4000">
        ${this.toastMessage}
      </sl-toast>
    `;
  }

}

customElements.define('admin-projects-view', AdminProjectsView);
