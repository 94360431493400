import { css } from 'lit';

export const guildIndexViewStyles = css`

  :host {
    background:  
    radial-gradient(circle at top left, var(--teal-6), transparent 80vw),
    radial-gradient(circle at top right, var(--teal-7), transparent 53vw),
    radial-gradient(at bottom left, var(--teal-8), transparent 70vw),
    radial-gradient(at bottom right, var(--teal-8), transparent 100vw),
    linear-gradient(0deg, var(--teal-3), var(--teal-3));
  }

  q1-app-header {
    --background: transparent;
    --border-bottom: none ;
    --box-shadow: none;
  }

  sl-divider {
    margin: 2.5rem 0;
    --color: var(--teal-1);
  }

  notice-panel {
    margin-top: 4rem;
  }

  h1 {
    color: white;
  }


`;
