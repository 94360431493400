import { html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { classMap } from 'lit/directives/class-map.js';
import { ViewBase } from '../view-base.js';
import { memberIndexViewStyles } from './member-index-view-styles.js';
import { functions, httpsCallable } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/tree/tree.js';
import '@shoelace-style/shoelace/dist/components/tree-item/tree-item.js';
import '@shoelace-style/shoelace/dist/components/format-date/format-date.js';
import '@shoelace-style/shoelace/dist/components/radio-group/radio-group.js';
import '@shoelace-style/shoelace/dist/components/radio-button/radio-button.js';

import { q1MemberFunctions, q1PlaceFunctions } from '../../modules/q1-core-functions.js'
import { FormHostMixin } from '../../modules/form-host-mixin.js';


const DEFAULT_PROFILE_COLOR_PRIMARY = '#E2B013';
const LEVEL_ICONS = {
  network: 'graph_5',
  community: 'graph_5',
  contributor: 'communities',
  foundation: 'foundation',
  steward: 'crowdsource',
};

// IMPROVEME: Dry this up / move elsewhere

const SORT_BY_OPTIONS = [
  { label: 'Number', value: 'number' },
  { label: 'Name', value: 'nameInternal' },
  { label: 'Created At', value: 'createdAt' },
];
const SORT_BY_DEFAULT = 'nameInternal';

const SORT_DIRECTION_OPTIONS = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
];
const SORT_DIRECTION_DEFAULT = 'asc';

const PAGE_SIZE_OPTIONS = [
  { label: '5', value: '5' }, // FIXME: Delete
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];
const PAGE_SIZE_DEFAULT = 20;

const FILTER_BY_LEVELS_OPTIONS = [
  { label: 'Network', value: 'network', icon: LEVEL_ICONS['network'] },
  { label: 'Contributor', value: 'contributor', icon: LEVEL_ICONS['contributor'] },
  { label: 'Foundation', value: 'foundation', icon: LEVEL_ICONS['foundation'] },
  { label: 'Steward', value: 'steward', icon: LEVEL_ICONS['steward'] },
];
const FILTER_BY_LEVELS_DEFAULT = [];

// NOTE: We have a limit of 30 query disjunction limits, so the more levels that are selected the
//   fewer places we can select. This will apply to other array inclusion filters in the future.
const FILTER_BY_PLACE_IDS_MAX = (selectedLevelCount) => Math.floor(30 / (selectedLevelCount || 1));


/**
 * ## Member Index View ##
 * 
 * URL Params:
 * - sort_by = number, nameInternal, createdAt
 * - order = asc, desc
 * - levels = Comma-delimited list of: network, contributor, foundation, steward
 * - locations = Comma-delimited list of place ids
 */
export class MemberIndexView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    memberIndexViewStyles
  ];

  static properties = {
    ...super.properties,

    placeTree: { type: Array },
    placeCount: { type: Number },
    countryCount: { type: Number },
    
    members: { type: Array },
    memberCount: { type: Number }, // ALL members, regardless of query

    sortBy: { type: String },
    sortDirection: { type: String },
    pageSizeString: { type: String }, // used to convert to number value
    pageSize: { type: Number },
    pageHistory: { type: Array },

    querySize: { type: Number },
    firstItemIndex: { type: Number },
    lastItemIndex: { type: Number },

    showSettings: { type: Boolean }, // only used on mobile views

    filterByLevels: { type: Array },
    filterByLocations: { type: String }, // disabled OR all
    
    filterByPlaceIds_status: { type: String }, // ok, warning, error
    filterByPlaceIds_message: { type: String },
    filterByPlaceIds: { type: Array },

    priorPage: { type: String },
    nextPage: { type: String },
    
    loading: { type: Boolean }
  };

  constructor() {
    super();

    this.loading = true; // prevents auto-query until initView()

    this.placeTree = [];
    this.placeCount = 0;
    this.countryCount = 0;
    
    this.members = [];
    this.memberCount = 0;
    
    this.sortBy = SORT_BY_OPTIONS.map(o => o.value).includes(this.urlParams.sort_by)
      ? this.urlParams.sort_by
      : SORT_BY_DEFAULT;
    this.sortDirection = SORT_DIRECTION_OPTIONS.map(o => o.value).includes(this.urlParams.order)
      ? this.urlParams.order
      : SORT_DIRECTION_DEFAULT;
    this.pageSizeString = PAGE_SIZE_DEFAULT.toString();
    this.pageSize = PAGE_SIZE_DEFAULT;
    this.pageHistory = [];

    this.querySize = 0;
    this.firstItemIndex = 0;
    this.lastItemIndex = 0;

    this.showSettings = false;

    // Note: This one needs manual population (in initView) if set from urlParams
    const levelValues = FILTER_BY_LEVELS_OPTIONS.map(o => o.value);
    this.filterByLevels = this.urlParams.levels?.length
      ? this.urlParams.levels.split(',').filter(l => levelValues.includes(l))
      : FILTER_BY_LEVELS_DEFAULT;

    this.filterByLocations = 'disabled';

    this.filterByPlaceIds_status = 'ok';
    this.filterByPlaceIds_message = 'Not filtering members by location.';
    // Note: This one needs manual population (in initView) if set from urlParams
    this.filterByPlaceIds = this.urlParams.locations?.length
      ? this.urlParams.locations.split(',')
      : [];
    
    this.priorPage = null;
    this.nextPage = null;
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Members - q1 Network');

    this.initView();
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (!this.loading) {
      if (changedProperties.has('routeParams')) {
        // Since the member index doesn't link to itself the only way to get here is to click the 
        // members link in the app header bar, which should just reset the query.
        this.resetQuery();
        return;
      }
    
      const QUERY_PARAMS = [
        'sortBy',
        'sortDirection',
        'pageSize',
        'filterByLevels',
        'filterByLocations',
        'filterByPlaceIds',
      ];
      let queryParamsChanged = false;
      for (const param of QUERY_PARAMS) {
        if (changedProperties.has(param)) {
          queryParamsChanged = true;
          break;
        }
      }

      if (queryParamsChanged) this.queryMembers(); // queries page 1
    }
  }

  initView() {
    const { getPlaceTree } = q1PlaceFunctions;
    const { getAppStats } = q1MemberFunctions;

    getAppStats().then((response) => {
      const responseData = response.data || {};

      // Only use memberCount from appStats. Place & country count are more accurate in placeTree
      this.memberCount = responseData.memberCount;
    }).catch((error) => {
      console.log('queryAppStats: Error querying app stats', error);
    });

    getPlaceTree().then((response) => {
      const { placeTree, placeCount, countryCount, cacheStatus } = response.data || {};

      this.placeTree = placeTree;
      this.placeCount = placeCount;
      this.countryCount = countryCount;

      this.updateComplete.then(() => {
        // In the signed out view, expand the tree
        if (!this.isSignedIn) {
          this.locationsExpandAll();
          this.loading = false;
        } else {
          // At this point we can finish processing `level` and `locations` urlParams
          if (this.filterByLevels.length)
            this.renderRoot.querySelector('#filterByLevels').value = this.filterByLevels;

          if (this.filterByPlaceIds.length) {
            const new_filterByPlaceIds = [];
            this.filterByPlaceIds.forEach(placeId => {
              // Check the box next to each item & remove values missing from place tree
              const itemElement = [...this.renderRoot.querySelectorAll('sl-tree-item')]
                .find(i => i.dataset?.placeIds?.includes(placeId));
              
              if (itemElement) {
                itemElement.selected = true; // NOTE: This doesn't trigger sl-change event
                new_filterByPlaceIds.push(placeId);
              }
            });
            this.filterByPlaceIds = new_filterByPlaceIds;

            if (this.filterByPlaceIds.length) {
              // Only enable location filtering if at least one was matched
              this.filterByLocations = 'all';
              this.locationsExpandAll();
              this.handleTreeSelectionChange(); // updates the message
            }
          }
          
          this.queryMembers();
        }
      });
    }).catch((error) => {
      console.log('initView: Error getting place tree', error);
      this.showToast(error.message, 'Error Initializing Member Browser', 'danger');
      
      this.queryMembers();
    });
  }

  resetQuery() {
    this.loading = true; // prevents auto-query until done
    
    this.members = [];
    
    this.sortBy = SORT_BY_DEFAULT;
    this.sortDirection = SORT_DIRECTION_DEFAULT;
    this.pageSizeString = PAGE_SIZE_DEFAULT.toString();
    this.pageSize = PAGE_SIZE_DEFAULT;
    this.pageHistory = [];

    this.showSettings = false;

    this.filterByLevels = FILTER_BY_LEVELS_DEFAULT;
    this.filterByLocations = 'disabled';

    this.filterByPlaceIds_status = 'ok';
    this.filterByPlaceIds_message = 'Not filtering members by location.';
    this.filterByPlaceIds = [];
    
    this.priorPage = null;
    this.nextPage = null;

    this.locationsDeselectAll();
    this.locationsCollapseAll();
    this.renderRoot.querySelector('#filterByLevels').value =[];

    this.queryMembers();
  }

  _updateUrlParamsFromQuery() {
    const currentParams = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();
    
    if (this.sortBy !== SORT_BY_DEFAULT) newParams.set('sort_by', this.sortBy);
    if (this.sortDirection !== SORT_DIRECTION_DEFAULT) newParams.set('order', this.sortDirection);
    if (this.filterByLevels.length > 0) newParams.set('levels', this.filterByLevels.join(','));
    if (this.filterByPlaceIds.length > 0) newParams.set('locations', this.filterByPlaceIds.join(','));

    // Update the url if we have non-default params OR if we need to clear out prior params
    const newUrl = newParams.size > 0
      ? `${window.location.pathname}?${newParams.toString()}`
      : window.location.pathname;
    history.pushState(null, null, newUrl);
  }

  /**
   * The query page parameter should only be used when none of the other parameters have
   * changed. If they have changed then the cursors need to be reset and you have to go
   * back to page 1.
   */
  queryMembers(queryPage = 1) {
    if (!this.isSignedIn) {
      // unavailable when signed out
      this.loading = false;
      return;
    }

    const { browseMembers } = q1MemberFunctions;

    this.loading = true;

    browseMembers({
      queryPage: queryPage,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      pageSize: this.pageSize,
      pageHistory: this.pageHistory,
      filterByLevels: this.filterByLevels,
      filterByLocations: this.filterByLocations,
      filterByPlaceIds: this.filterByPlaceIds,
    }).then((response) => {
      const {
        members,
        pageHistory, priorPage, nextPage,
        querySize, firstItemIndex, lastItemIndex,
      } = response.data || {};
      
      this.pageHistory = pageHistory;
      this.priorPage = priorPage;
      this.nextPage = nextPage;

      this.querySize = querySize;
      this.firstItemIndex = firstItemIndex;
      this.lastItemIndex = lastItemIndex;

      this.members = members.map(member => {
        // Title case the membership level field, also set the icon
        const level = member.level || 'network';
        member.levelIcon = LEVEL_ICONS[level] || LEVEL_ICONS['network'];
        member.level = level[0].toUpperCase() + level.substring(1);

        // Set the primary color if missing
        if (!member.colorPrimary) member.colorPrimary = DEFAULT_PROFILE_COLOR_PRIMARY;
        
        // Select mini image size
        member.profile = !member.profile ? null: this.imageUrl(member.profile, 'mini');

        // Prep the locations
        member.locations = [
          ...member.locationsPrimary.map(l => ({ ...l, isPrimary: true })),
          ...member.locationsOther.map(l => ({ ...l, isPrimary: false })),
        ];
        
        return member;
      });

      this._updateUrlParamsFromQuery();
      this.loading = false;
    }).catch((error) => {
      console.log('queryMembers: Error querying members', error);
      this.showToast(error.message, 'Error Querying Members', 'danger');
      this.loading = false;
    });
  }

  toggleQuerySettings() {
    this.showSettings = !this.showSettings;
  }

  /*=== INPUT HANDLING ===*/

  get handleInputPostProcess() {
    return {
      pageSizeString: (newValue) => { this.pageSize = parseInt(newValue) || PAGE_SIZE_DEFAULT },
      filterByLevels: (newValue) => {
        // This is needed because the max number of allowed places changes based on this value
        this.handleTreeSelectionChange();
      },
      filterByLocations: (newValue) => {
        // This is needed to update the locations status and message
        this.handleTreeSelectionChange();
      },
    };
  }

  /**
   * This method processes changes to the place tree or location filters and updates:
   * `filterByPlaceIds`, `filterByPlaceIds_status`, and `filterByPlaceIds_message`.
   * 
   * It can be called with or without an event.
   */
  handleTreeSelectionChange(event) {
    const selectedItems = event?.detail?.selection
      || this.renderRoot.querySelector('#place-tree').selectedItems;

    let selectedPlaceIds = selectedItems
      .filter(itemElement => itemElement.dataset?.placeIds?.length)
      .map(itemElement => itemElement.dataset.placeIds.split('|'))
      .flat();

    // Setting this.filterByPlaceIds triggers a re-query, so we need to check the limit first
    // We'll also update the status and message now.

    const placeCount = selectedPlaceIds.length;
    const maxAllowed = FILTER_BY_PLACE_IDS_MAX(this.filterByLevels?.length || 0);
    const warningThreshold = Math.round(0.75 * maxAllowed);

    if (this.filterByLocations === 'disabled') {
      this.filterByPlaceIds_status = 'ok';
      this.filterByPlaceIds_message = 'Not filtering members by location.';
      this.filterByPlaceIds = [];
    } else if (placeCount === 0) {
      this.filterByPlaceIds_status = 'error';
      this.filterByPlaceIds_message = 'You must select at least one location.';
      this.filterByPlaceIds = selectedPlaceIds;
    } else if (placeCount <= maxAllowed) {
      this.filterByPlaceIds_message = `${placeCount} locations selected (max ${maxAllowed}).`;
      this.filterByPlaceIds_status = (placeCount < warningThreshold) ? 'ok' : 'warning';
      this.filterByPlaceIds = selectedPlaceIds;
    } else {
      this.filterByPlaceIds_message =
        `Max locations reached. Only ${maxAllowed} of the ${placeCount} locations you've `
        + `selected are being used.`;
      this.filterByPlaceIds_status = 'error';
      
      // Redo the tabulation, this time stopping at max and marking the rest as ignored
      let selectedPlaceIds = [];
      selectedItems
        .filter(itemElement => itemElement.dataset?.placeIds?.length)
        .forEach(itemElement => {
          const placeIds = itemElement.dataset.placeIds.split('|');
          
          if (selectedPlaceIds.length < maxAllowed) {
            // IMPROVEME: Could result in overflows with multi-place tree items
            selectedPlaceIds = [...selectedPlaceIds, ...placeIds];
            itemElement.classList.remove('ignored');
          } else {
            itemElement.classList.add('ignored');
          }
        });
      
      this.filterByPlaceIds = selectedPlaceIds;
    }
    
  }

  /*=== BUTTON ACTIONS ===*/

  queryPriorPage() {
    if (this.priorPage) this.queryMembers(this.priorPage);
  }

  queryNextPage() {
    if (this.nextPage) this.queryMembers(this.nextPage);
  }
  
  locationsSelectAll() {
    const allItems = this.renderRoot.querySelectorAll('sl-tree-item');
    if (allItems) allItems.forEach(item => item.selected = true);
    this.updateComplete.then(() => { this.handleTreeSelectionChange(); });
  }

  locationsDeselectAll() {
    const allItems = this.renderRoot.querySelectorAll('sl-tree-item');
    if (allItems) allItems.forEach(item => item.selected = false);
    this.updateComplete.then(() => { this.handleTreeSelectionChange(); });
  }  

  locationsExpandAll() {
    const allItems = this.renderRoot.querySelectorAll('sl-tree-item');
    if (allItems) allItems.forEach(item => item.expanded = true);
  }

  locationsCollapseAll() {
    const allItems = this.renderRoot.querySelectorAll('sl-tree-item');
    if (allItems) allItems.forEach(item => item.expanded = false);
  }

  /*=== RENDER METHODS ===*/

  // NOTE: We're using a custom template, overwriting the one in view-base.
  render() {
    return html`
      ${this.headerTemplate}

      <top-bar>
        <headline>
          <h1>q1 Network Members</h1>
          ${this.loading ? null : html`
            <span class="suffix">
              — ${this.memberCount} members ∙ ${this.countryCount} countries
            </span>
          `}
        </headline>
        <sl-button
          id="toggle-query-settings"
          @click=${this.toggleQuerySettings}
        >
          <sl-icon
            library="material"
            name="${this.showSettings ? 'keyboard_double_arrow_left':'keyboard_double_arrow_right'}"
            slot="prefix"
          ></sl-icon>
          ${this.showSettings ? 'Hide Query Settings' : 'Show Query Settings'}
          <sl-icon library="material" name="tune" slot="suffix"></sl-icon>
        </sl-button>
      </top-bar>

      <left-bar>
        ${this.queryPanelTemplate}
      </left-bar>

      <content-container class=${classMap({
        showSettings: this.showSettings,
      })}>
        ${this.memberTableTemplate}

        <q1-spinner-panel label="Loading..." ?open=${this.loading}></q1-spinner-panel>
      </content-container>
    `;
  }

  get headerTemplate() {
    return html`
      <q1-app-header
        .currentUser="${this.currentUser}"
        .currentMember="${this.currentMember}"
        .isSignedIn="${this.isSignedIn}"
        .isAdmin="${this.isAdmin}"
        variant="flat"
      ></q1-app-header>
    `;
  }

  get queryPanelTemplate() {
    return html`
      <h2>Query Settings</h2>

      <query-pair>
        <sl-select 
          id="sortBy"
          name="sortBy"
          value=${this.sortBy}
          @sl-change=${this.handleChange}
          ?disabled=${!this.isSignedIn}
          size="small"
          filled
        >
          <span class="inner-label" slot="prefix">Sort By</span>
          <small>Sort members by:</small>
          ${SORT_BY_OPTIONS.map(({ label, value }) => html`
            <sl-option value=${value}>${label}</sl-option>
          `)}
        </sl-select>
        
        <sl-select 
          id="sortDirection"
          name="sortDirection"
          value=${this.sortDirection}
          @sl-change=${this.handleChange}
          ?disabled=${!this.isSignedIn}
          size="small"
          filled
        >
          <small>Sort direction:</small>
          ${SORT_DIRECTION_OPTIONS.map(({ label, value }) => html`
            <sl-option value=${value}>${label}</sl-option>
          `)}
        </sl-select>
      </query-pair>

      <sl-select 
        id="filterByLevels"
        name="filterByLevels"
        @sl-change=${this.handleChange}
        ?disabled=${!this.isSignedIn}
        size="small"
        placeholder="All Member Levels"
        multiple clearable filled
      >
        <span class="inner-label" slot="prefix">Level</span>
        <small>Only show members at level:</small>
        ${FILTER_BY_LEVELS_OPTIONS.map(({ label, value, icon }) => html`
          <sl-option value=${value}>
            <sl-icon library="material" name=${icon} slot="prefix"></sl-icon>
            ${label}
          </sl-option>
        `)}
      </sl-select>

      <sl-divider></sl-divider>

      <h3>Filter by Location</h3>

      ${!this.isSignedIn ? null : html`
        <tree-summary class=${this.filterByPlaceIds_status}>
          ${this.filterByPlaceIds_message}
        </tree-summary>
      `}

      <sl-radio-group
        id="filterByLocations"
        name="filterByLocations"
        value=${this.filterByLocations}
        @sl-change=${this.handleChange}
        size="small"
      >
        <sl-radio-button
          value="disabled"
          ?disabled=${!this.isSignedIn}
        >
          Show All
        </sl-radio-button>
        <sl-radio-button
          value="all"
          ?disabled=${!this.isSignedIn}
        >
          Select Locations
        </sl-radio-button>
      </sl-radio-group>

      <icon-button-list>
        <sl-tooltip content="Select All" ?disabled=${this.filterByLocations === 'disabled'}>
          <sl-icon-button
            library="material"
            name="select_check_box"
            label="Select All"
            @click=${this.locationsSelectAll}
            ?disabled=${this.filterByLocations === 'disabled'}
          ></sl-icon-button>
        </sl-tooltip>  

        <sl-tooltip content="Clear Selection" ?disabled=${this.filterByLocations === 'disabled'}>
          <sl-icon-button
            library="material"
            name="remove_selection"
            label="Clear Selection"
            @click=${this.locationsDeselectAll}
            ?disabled=${this.filterByLocations === 'disabled'}
          ></sl-icon-button>
        </sl-tooltip>
        
        <sl-tooltip content="Expand All" ?disabled=${this.filterByLocations === 'disabled'}>
          <sl-icon-button
            library="material"
            name="expand_all"
            label="Expand All"
            @click=${this.locationsExpandAll}
            ?disabled=${this.filterByLocations === 'disabled'}
          ></sl-icon-button>
        </sl-tooltip>

        <sl-tooltip content="Collapse All" ?disabled=${this.filterByLocations === 'disabled'}>
          <sl-icon-button
            library="material"
            name="collapse_all"
            label="Collapse All"
            @click=${this.locationsCollapseAll}
            ?disabled=${this.filterByLocations === 'disabled'}
          ></sl-icon-button>
        </sl-tooltip>
      </icon-button-list>

      <sl-tree
        id="place-tree"
        selection="multiple"
        @sl-selection-change=${this.handleTreeSelectionChange}
      >
        ${this.placeTree.map(l1 => html`
          <sl-tree-item data-key=${l1.key} ?disabled=${this.filterByLocations === 'disabled'}>
            ${l1.longName}

            ${l1.children.map(l2 => html`
              <sl-tree-item data-key=${l2.key} ?disabled=${this.filterByLocations === 'disabled'}>
                ${l2.longName}

                ${l2.children.map(l3 => html`
                  <sl-tree-item
                    data-key=${l3.key}
                    data-place-ids=${(l3.placeIds || []).join('|')}
                    ?disabled=${this.filterByLocations === 'disabled'}
                  >
                    ${l3.longName}
                  </sl-tree-item>
                `)}
                
              </sl-tree-item>
            `)}

          </sl-tree-item>
        `)}
      </sl-tree>
    `;
  }

  get memberTableTemplate() {
    if (!this.isSignedIn) return html`
      <table-wrapper>
        <div class="empty-panel hero">
          <div class="inner">
            <sl-icon library="vectors" name="global-collaboration"></sl-icon>

            <h2>Network browsing is only available to members...</h2>

            <p>
              The good news is that we'd love to have you as a member!
              <strong>Interested in the future of work?</strong>
            </p>

            <sl-button
              variant="warning"
              size="large"
              href="/join"
              pill
            >
              Join the Quorum
            </sl-button>
          </div>
        </div>
      </table-wrapper>
    `;

    if (!this.members?.length) return html`
      <table-wrapper>
        <div class="empty-panel">
          <div class="inner">
            <sl-icon library="material" name="no_accounts"></sl-icon>

            <h2>Nothing to see here...</h2>

            There are no visible members in your current query.
          </span>
        </div>
      </table-wrapper>
    `;

    return html`
      <table-wrapper>
        <table id="members-table" class="q1">
          <thead>
            <th>Member</th>
            <th>Level</th>
            <th>Headline</th>
            <th>Locations</th>
            <th>Join Date</th>
          </thead>

          <tbody>
            ${this.members.map(member => html`
              <tr style=${styleMap({
                '--profile-color-primary': member.colorPrimary,
                '--profile-color-primary-10': `${member.colorPrimary}11`,
              })}>
                <td class="member-col">
                  <td-content>
                    <sl-avatar
                      class="profileImage"
                      label="${member.name}"
                      image=${member.profile || ''}
                    ></sl-avatar>
                    
                    <a href=${`/@${member.number}`} class="name-link">${member.name}</a>
                    <span class="divider">—</span>
                    <a href=${`/@${member.number}`} class="number-link">@${member.number}</a>
                    
                    <span class="action-links">
                      ${!member.linkedinProfileUrl ? '' : html`
                        <sl-icon-button
                          library="tabler"
                          name="brand-linkedin"
                          href="${member.linkedinProfileUrl}"
                          target="_blank"
                        ></sl-icon-button>
                      `}
                    </span>
                  </td-content>
                </td>
                <td class="level-col">
                  <span>
                    <sl-icon library="material" name=${member.levelIcon}></sl-icon>
                    ${member.level}
                  </span>
                </td>
                <td class="headline-col">${member.headline || '-'}</td>
                <td class="locations-col">
                  ${!member.locations?.length ? '-' : html`
                    <place-list>
                      ${member.locations.map(location => html`
                        <place-item class=${classMap({ primary: location.isPrimary })}>
                          <sl-icon
                            library="material"
                            name=${location.isPrimary ? 'home_pin': 'location_on'}
                          ></sl-icon>
                          <span>${location.name}</span>
                        </place-item>
                      `)}
                    </place-list>
                  `}
                </td>
                <td class="date-col">
                  ${!member.createdAt ? '-' : html`
                    <sl-format-date
                      date=${member.createdAt}
                      month="short" day="numeric" year="numeric"
                    ></sl-format-date>
                  `}
                </td>
              </tr>
            `)}
          </tbody>
        </table>
      </table-wrapper>

      ${!this.isSignedIn ? null : html`
        <table-footer>
          <page-settings>
            <sl-select 
              id="pageSizeString"
              name="pageSizeString"
              value="${this.pageSizeString}"
              @sl-change=${this.handleChange}
              filled
            >
              <span class="inner-label" slot="prefix">Page Size</span>
              <small>Members per page:</small>
              ${PAGE_SIZE_OPTIONS.map(({ label, value }) => html`
                <sl-option value=${value}>${label}</sl-option>
              `)}
            </sl-select>

            <span id="current-page-details">
              Showing ${this.firstItemIndex} - ${this.lastItemIndex}
              of ${this.querySize} matching members
            </span>
          </page-settings>
          
          <nav-buttons>
            <sl-button
              class="prior-page"
              @click="${this.queryPriorPage}"
              size="large"
              ?disabled="${!this.priorPage || this.loading}"
            >
              <sl-icon library="material" name="arrow_back" slot="prefix"></sl-icon>
              Prior <span class="hide-until-bp2">Page</span>
            </sl-button>

            <sl-button
              class="next-page"
              @click="${this.queryNextPage}"
              size="large"
              ?disabled="${!this.nextPage || this.loading}"
            >
              Next <span class="hide-until-bp2">Page</span>
              <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
            </sl-button>
          </nav-buttons>
        </table-footer>
      `}
    `;
  }

}

customElements.define('member-index-view', MemberIndexView);
