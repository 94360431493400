import { html, css, LitElement } from 'lit';
import { storage, storageRef, uploadBytes } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';

import { Q1PlacesInputStyles } from './q1-places-input-styles.js';
import { FormInputMixin } from '../../modules/form-input-mixin.js';

export class Q1PlacesInput extends FormInputMixin(LitElement) {
  static styles = [
    Q1PlacesInputStyles,
  ];

  static properties = {
    ...super.properties, // adds: label, name, value, required, valid, helpText, _internals

    input: { type: Object },
    
    // value format: JSON stringify of this.places
    places: { type: Array }, // keys: name [string], id [string], primary [boolean]

    _error: { type: String, state: true },
    _invalidityFlag: { type: String, state: true },
    _loading: { type: Boolean, state: true },
  };

  constructor() {
    super();
  
    this.input = null;
    this.places = [];

    this.uploadFolder = '';
    this.currentImageThumbnail = '';
    this._error = '';
    this._invalidityFlag = '';
    this._loading = false;
    this.valid = true;
  }

  /**
   * Rebuilds this.places from this.value
   */
  _buildPlacesFromValue() {
    this.places = JSON.parse(this.value || '[]');
  }
  
  /**
   * Rebuilds this.value from this.places
   */
  _buildValueFromPlaces(fireChangeEvent = true) {
    this.value = JSON.stringify(this.places || []);
    this.updateFormValue();
    if (this.input) this.updateValidity();
    if (fireChangeEvent)
      this.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }));
  }

  connectedCallback() {
    super.connectedCallback();

    this.updateFormValue();
    this.initPlaceInput();
  }

  updated(changedProperties) {
    if (changedProperties.has('places')) {
      this._buildValueFromPlaces(false);
    }
  }

  async initPlaceInput() {
    // Request needed libraries.
    await google.maps.importLibrary('places');
  
    this.input = new google.maps.places.PlaceAutocompleteElement({
      types: ['(cities)'],
    });
    this.input.id = 'input';
    this.renderRoot.querySelector('input-body').appendChild(this.input);

    this.input.addEventListener('gmp-placeselect', ({ place }) => this.handlePlaceSelect(place));

    this.updateValidity(); // can't do this until input is set
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  updateValidity() {
    if (this.required && !this.places?.length) {
      this._internals.setValidity(
        { valueMissing: true },
        'At least one location is required',
        this.input,
      );
    } else if (!this.valid) {
      this._internals.setValidity(
        { [this._invalidityFlag]: true },
        this._error,
        this.input,
      );
    } else {
      this._internals.setValidity({}, '', this.input);
    }
  }
  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  updateFormValue() {
    this._internals.setFormValue(this.value);
  }

  async handlePlaceSelect(place) {
    await place.fetchFields({
      fields: ['displayName'],
    });

    const dupePlace = this.places.find(p => p.id === place.id);
    if (!!dupePlace) return; // prevent dupes

    this.places.push({
      id: place.id,
      name: place.displayName,
      primary: this.places.length === 0, // set first to primary
    });
    this._buildValueFromPlaces();

    // IMPROVEME: This is a hack to clear the value of the places input. This will likely break at 
    // some point. ('Yg' is input-container in the current build.)
    const placeInternalInput = this.input?.Yg?.querySelector('input');
    if (placeInternalInput?.value) placeInternalInput.value = '';
  }
  
  handleChange() {
    console.log('in handleChange');
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  formDisabledCallback(disabled) {
    this.input.disabled = disabled;
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  formResetCallback() {
    this.places = [];
  }

  togglePrimary(placeId) {
    const place = this.places.find(p => p.id === placeId);

    // Note: This won't trigger a re-render but that's ok
    if (!!place) place.primary = !place.primary;
    this._buildValueFromPlaces();
  }

  removePlace(placeId) {
    // In this instance we *do* want to trigger a re-render, so we use the places setter
    this.places = this.places.filter(p => p.id !== placeId);
    this._buildValueFromPlaces();
  }

  render() {
    return html`
      ${!this.label?.length ? null : html`
        <label for="input">${this.label}</label>
      `}
      <input-body>
        <ul id="selected-places">
          ${!this.places.length ? html`
            <li>
              <span class="placeholder">
                Type in the search box below to start adding locations.
              </span>
            </li>
          ` : html`
            ${this.places.map(place => html`
              <li>
                <sl-tooltip content="Is this one of your current primary locations?">
                  <sl-switch
                    ?checked=${place.primary}
                    @sl-change=${() => this.togglePrimary(place.id)}
                  ></sl-switch>
                </sl-tooltip>
                <span>${place.name}</span>
                <sl-icon-button
                  library="material"
                  name="close"
                  label="Remove"
                  @click=${() => this.removePlace(place.id)}
                ></sl-icon-button>
              </li>
            `)}
            <li>
              <sl-icon library="material" name="switch_access_shortcut"></sl-icon>
              <span class="placeholder">Select your current primary location(s)</span>
            </li>
          `}
        </ul>
      </input-body>

      ${!this._error?.length ? null : html`
        <div id="error-message">${this._error}</div>
      `}
      
      ${!this.helpText?.length ? null : html`
        <div id="help-text">${this.helpText}</div>
      `}
    `;
  }
}

customElements.define('q1-places-input', Q1PlacesInput);
