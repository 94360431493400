import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import { ViewBase } from '../view-base.js';
import { memberProfileViewStyles } from './member-profile-view-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';

import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';

import { q1MemberFunctions } from '../../modules/q1-core-functions.js';

const DEFAULT_PROFILE_COLOR_PRIMARY = '#E2B013';

export class MemberProfileView extends ViewBase {
  static styles = [
    ...super.styles,
    memberProfileViewStyles,
  ];

  static properties = {
    ...super.properties,

    memberNumber: { type: String },
    profile: { type: Object },
    
    _loading: { type: Boolean },
    _isError: { type: Boolean },
    _errorMessage: { type: String },
  };

  constructor() {
    super();
    this.memberNumber = null;
    this.profile = {};
    
    this._loading = true;
    this._isError = false;
    this._errorMessage = null;
  }

  connectedCallback() {
    super.connectedCallback();

    this._initViewFromRouteParams();
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('routeParams')) {
      this._initViewFromRouteParams();
    }
  }

  _initViewFromRouteParams() {
    this._loading = true;
    this._isError = false;
    this._errorMessage = '';
    
    this.memberNumber = this.routeParams?.key?.trim()?.toUpperCase();

    if (this.memberNumber?.length) {
      this.fetchMember();
    } else {
      this._errorMessage = 'Member number is missing from URL';
      this._isError = true;
      this._loading = false;
    }
  }

  fetchMember() {
    const { getMemberProfile } = q1MemberFunctions;

    this._loading = true;
    this._isError = false;
    this._errorMessage = null;

    getMemberProfile({ memberNumber: this.memberNumber }).then((response) => {
      const responseData = response.data || {};
      
      if (responseData.success) {
        this.profile = responseData.profile;

        // Apply the colors to the css of the host
        if (!this.profile.colorPrimary) this.profile.colorPrimary = DEFAULT_PROFILE_COLOR_PRIMARY;
        this.style.setProperty('--profile-color-primary', this.profile.colorPrimary);
        this.style.setProperty('--profile-color-primary-25', `${this.profile.colorPrimary}44`);
        this.style.setProperty('--profile-color-primary-50', `${this.profile.colorPrimary}88`);
        this.style.setProperty('--profile-color-primary-75', `${this.profile.colorPrimary}cc`);

        // Select standard image sizes
        if (this.profile.bannerImage)
          this.profile.bannerImage = this.imageUrl(this.profile.bannerImage, 'standard');
        if (this.profile.profileImage)
          this.profile.profileImage = this.imageUrl(this.profile.profileImage, 'standard');
        
        // Title case the membership level field
        const level = this.profile.level || 'network';
        this.profile.level = level[0].toUpperCase() + level.substring(1);

        // Add boolean(s)
        this.profile.hasLocations = this.profile.locationsPrimary?.length 
          || this.profile.locationsPrimary?.length;

        this.setDocumentTitle(`${this.profile.name} - q1 Network`);
      } else {
        this._errorMessage = responseData.message || 'There was an error getting the profile.';
        this._isError = true;
        this.setDocumentTitle('Unavailable Member Profile  - q1 Network');
      }
      this._loading = false;
    }).catch((error) => {
      console.log('Error encountered: ', error);
      this._errorMessage = `Error Getting Member Profile: ${error.message}`;
      this._isError = true;
      this._loading = false;
      this.setDocumentTitle('Member Profile Error - q1 Network');
    });
  }

  get contentTemplate() {
    if (this._loading) return html`
      <q1-spinner-panel label="Loading..." open></q1-spinner-panel>
    `;

    if (this._isError) return html`
      <h1>q1 Network Member Profile</h1>

      <p class="subtitle">
        ${this._errorMessage}
      </p>
    `;

    return html`
      <profile-header>
        <profile-banner
          class=${classMap({ empty: !this.profile.bannerImage?.length })}
        >
          ${!this.profile.bannerImage?.length ? null : html`
            <img src=${this.profile.bannerImage}/>
          `}  
        </profile-banner>
        <profile-bar>
          <sl-avatar
            id="profileImage"
            label="${this.profile.name}"
            image=${this.profile.profileImage || ''}
          ></sl-avatar>
          <button-bar>
            ${!this.profile.linkedinProfileUrl?.length ? null : html`
              <sl-button
                href=${this.profile.linkedinProfileUrl}
                target="_blank"
                variant="primary"
                size="large"
                pill
              >Linkedin Profile</sl-button>
            `}
          </button-bar>
        </profile-bar>
        <h1>${this.profile.name}</h1>
        <div id="memberNumber">
          @${this.profile.number}
          <span>— ${this.profile.level} Member</span>
        </div>
        <div id="subtitle">
          ${this.profile.headline}
        </div>
        ${!this.profile.hasLocations ? null : html`
          <ul id="locations">
            ${this.profile.locationsPrimary.map(location => html`
              <li class="primary">
                <sl-tooltip content="Primary Location">
                  <a href="/members?locations=${location.id}">
                    <sl-icon library="material" name="home_pin"></sl-icon>
                    ${location.name}
                  </a>
                </sl-tooltip>
              </li>
            `)}
            
            ${!this.profile.locationsOther?.length ? null : html`
              <li class="other"> — </li>
            `}
            ${this.profile.locationsOther.map(location => html`
              <li class="other">
                <sl-tooltip content="Other Location">
                  <a href="/members?locations=${location.id}">
                    <sl-icon library="material" name="location_on"></sl-icon>
                    ${location.name}
                  </a>
                </sl-tooltip>
              </li>
            `)}
          </ul>
        `}
      </profile-header>

      ${this.profileBody}
    `;
  }

  get profileBody() {
    if (this.profile.profileFormat === 'hidden') return null;
    
    return html`
      <hr/>

      <h2>Projects & Guilds</h2>

      <p class="subtitle empty-list">
        <strong>Nothing to see yet</strong><br/>

        ${this.profile.name} hasn't joined any projects or guilds yet.
      </p>
    `;
  }
}

customElements.define('member-profile-view', MemberProfileView);