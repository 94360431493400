import { css } from 'lit';

export const projectIndexViewStyles = css`

  :host {
    background: linear-gradient(135deg, var(--yellow-3), var(--yellow-6), var(--yellow-0));
  }

  sl-divider {
    margin: 2.5rem 0;
    --color: var(--yellow-3);
  }

  notice-panel {
    margin-top: 4rem;
  }

  q1-app-footer {
    --border-top: 1px solid var(--yellow-4);
  }

`;
