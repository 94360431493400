import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { homeOnboardViewStyles } from './home-onboard-view-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';

import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';
import { Q1PlacesInput } from '../../components/q1-places-input/q1-places-input.js';

import { q1OnboardingFunctions } from '../../modules/q1-core-functions.js';
import { FormHostMixin } from '../../modules/form-host-mixin.js';
import { updateMemberById } from '../../modules/firestore-helpers.js';

/** Maps from current onboardStatus to what the corresponding currentStepNumber is. */
const currentStepNumberMap = {
  'none': 1,
  'step-1-complete': 2,
  'step-2-complete': 3,
  'complete': 3,
  // 'step-3-complete': 4,
  // 'complete': 4,
};

/** Maps from current step number to what onboardStatus should be after completing. */
const onboardStatusMap = {
  1: 'step-1-complete',
  2: 'step-2-complete',
  3: 'complete',
  // 3: 'step-3-complete',
  // 4: 'complete',
};

export class HomeOnboardView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    homeOnboardViewStyles,
  ];

  static properties = {
    ...super.properties,
    
    currentStepNumber: { type: Number }, // Max step from onboardStatus = 1, 2, 3, 4
    stepNumber: { type: Number }, // 1, 2, [eventually 3 & 4]
    
    hasPriorStep: { type: Boolean }, // true if there is a prior step
    hasNextStep: { type: Boolean }, // true if there is a next step
    atCurrentStep: { type: Boolean }, // true if stepNumber is the max step
    
    isProcessing: { type: Boolean },
    
    profile_newUploadPath: { type: String },

    headline: { type: String },
    colorPrimary: { type: String },
    locationsConfig: { type: Object },
    betaFeedback: { type: String },
  };

  constructor() {
    super();

    this.currentStepNumber = 1;
    this.stepNumber = 1;
    this.hasPriorStep = false;
    this.hasNextStep = true;
    this.atCurrentStep = true;

    this.profile_newUploadPath = '';

    this.headline = '';
    this.colorPrimary = '';
    this.locationsConfig = [];
    this.betaFeedback = '';
  }
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Member Onboarding - q1 Network');

    this.currentStepNumber = currentStepNumberMap[this.currentMember?.onboardStatus];
    this._setStep(this.currentStepNumber);
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('currentMember')) {
      const cm = this.currentMember || {};

      this.headline = cm.headline || '';
      this.colorPrimary = cm.colorPrimary || '';
      this.locationsConfig = cm.locationsConfig || [];
      this.betaFeedback = cm.betaFeedback || '';
    }
  }

  /**
   * Sets the stepNumber and related properties.
   * - Does NOT touch database.
   * - DOES correct stepNumber if out of range.
   * - Does NOT throw errors.
   * */
  _setStep(stepNumber) {
    stepNumber = Math.max(1, Math.min(4, Math.round(stepNumber))); // fix if out of range [1 to 4]

    this.stepNumber = stepNumber;
    this.hasPriorStep = stepNumber > 1;
    this.hasNextStep = stepNumber < 3;
    // this.hasNextStep = stepNumber < 4;
    this.atCurrentStep = stepNumber === this.currentStepNumber;

    this.updateComplete.then(() => {
      window.scrollTo({ top: 0 }); // important to snap to top of new view
    });
  }

  handleLocationsChange() {
    const locationsInput = this.renderRoot.querySelector('#locations-input');
    this.locationsConfig = locationsInput.places;
  }

  /** Goes back to the prior step. Does not touch database. */
  priorStep() {
    this._setStep(this.stepNumber - 1);
  }

  /**
   * Proceeds to the next step and touches the database if needed.
   */
  async nextStep() {
    if (!this.hasNextStep) return;

    if (!this.atCurrentStep && (this.stepNumber === 1)) {
      // No DB update needed, we can just move the UI forward
      this._setStep(this.stepNumber + 1);
      return;
    }

    // Else we need to update the DB
    this.isProcessing = true;

    // First check if there's data to save
    if (this.stepNumber === 2) {
      const step2Form = this.renderRoot.querySelector('#step-2-form');

      if (!step2Form.reportValidity()) {
        this.showToast(
          'Please fix the form validation errors to continue', null, 'danger'
        );
        this.isProcessing = false;
        return;
      }
      
      try {
        await updateMemberById(this.currentMember.id, {
          profile_newUploadPath: this.profile_newUploadPath,
          headline: this.headline,
          colorPrimary: this.colorPrimary,
          locationsConfig: this.locationsConfig,
        });
      } catch (error) {
        console.log('Error updating member:', error);
        this.showToast(
          '', 'Error updating your member record', 'danger'
        );
        this.isProcessing = false;
        return;
      }
    }

    const { updateOnboardStatus } = q1OnboardingFunctions;

    updateOnboardStatus({
      onboardStatus: onboardStatusMap[this.stepNumber]
    }).then((result) => {
      this.currentStepNumber = this.stepNumber + 1;
      this._setStep(this.stepNumber + 1);
      this.isProcessing = false;
    }).catch((error) => {
      document.querySelector('q1-home').queueToast('', 'Error updating status', 'danger');
      this.isProcessing = false;
    });
  }

  async completeOnboarding() {
    if (this.hasNextStep) return;
  
    this.isProcessing = true;

    const step3Form = this.renderRoot.querySelector('#step-3-form');
    
    if (!step3Form.reportValidity()) {
      this.showToast('Please fix the form validation errors to continue', null, 'danger');
      this.isProcessing = false;
      return;
    }
    
    try {
      await updateMemberById(this.currentMember.id, {
        betaFeedback: this.betaFeedback,
      });
    } catch (error) {
      console.log('Error updating member:', error);
      this.showToast('', 'Error updating your member record', 'danger');
      this.isProcessing = false;
      return;
    }
  
    const { updateOnboardStatus } = q1OnboardingFunctions;
  
    updateOnboardStatus({
      onboardStatus: onboardStatusMap[this.stepNumber],
      status: this.currentMember.status === 'confirmed' ? 'onboarded' : this.currentMember.status
    }).then((result) => {
      this.appElement.queueToast(
        'Congratulations and welcome to the Quorum.',
        'Onboarding Complete!',
        'success'
      );
      window.location.href = '/home';
    }).catch((error) => {
      document.querySelector('q1-home')
        .queueToast('', 'Error completing onboarding', 'danger');
      this.isProcessing = false;
    });
  }

  get contentTemplate() {
    return html`

      <content-container>
        <h1>
          <span class="prefix">Member Onboarding:</span>
          <!-- Step ${this.stepNumber} of 4 -->
          Step ${this.stepNumber} of 3
        </h1>
  
        <p class="subtitle">
          You can exit this process at any point and come back to it later.
        </p>
  
        <hr/>
  
        ${this.stepNumber == 1 ? this.renderStep1() : null}
        ${this.stepNumber == 2 ? this.renderStep2() : null}
        ${this.stepNumber == 3 ? this.renderStep3() : null}
        ${this.stepNumber == 4 ? this.renderStep4() : null}

        <q1-spinner-panel label="Saving..." no-bg ?open=${this.isProcessing}></q1-spinner-panel>
      </content-container>

      <hr/>

      <button-bar>
        <sl-button
          id="prior-step"
          @click="${this.priorStep}"
          ?disabled="${!this.hasPriorStep || this.isProcessing}"
          size="large"
        >
          <sl-icon library="material" name="arrow_back" slot="prefix"></sl-icon>
          Prior Step
        </sl-button>
        
        ${this.hasNextStep ? html`
          <sl-button
            id="next-step"
            @click="${this.nextStep}"
            variant="primary"
            size="large"
          >
            Next Step
            <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
          </sl-button>
        ` : html`
          <sl-button
            id="complete-onboarding"
            @click="${this.completeOnboarding}"
            ?disabled="${this.isProcessing}"
            variant="primary"
            size="large"
          >
            Complete Onboarding
            <sl-icon library="material" name="check_circle" slot="suffix"></sl-icon>
          </sl-button>
        `}
      </button-bar>

    `;
  }

  renderStep1() {
    return html`
      <h2>Welcome to the private beta! To begin, here's some important info.</h2>

      <p><em>(Last Updated: Tuesday, Jan 14)</em></p>
      
      <p>
        <strong>
          If you're reading this right now then you are part of something very special.
        </strong>
        Quorum1 was founded in 2021, but it's only with the launch of the q1 network that we're 
        truly beginning to create the infrastructure to support our shared dream of a new way to 
        work together.
      </p>

      <p>
        The q1 Network is under active development! New functionality is rolling out every few days.
        Here are some key things to note:
      </p>

      <ul>
        <li>
          This current release is still early. In this release you cannot join
          projects or guilds or anything like that.
        </li>
        <li>
          A lot of functionality is happening behind the scenes as the foundation team works with
          legacy members and launch partners to get things setup in the new system.
        </li>
        <li>
          The next phase of the beta will allow a lot more interaction and will be ready soon.
        </li>
        <li>
          At this time, the best way to get started with Q1 is to finish this onboarding process 
          and then keep an eye out for more messages from the foundation team. There's a spot in 
          the next step of the process to share some details about your work and we'll reach out 
          to you if there's a way to get you involved more quickly.
        </li>
        <li>
          In the meantime, if you want to get more involved / have questions / etc: Please email the 
          foundation team at <a href="mailto:team@quorum.one">team@quorum.one</a>. We can add you
          to a shared Slack channel for coordination if needed, just reach out and ask.
        </li>
      </ul>

    `;
  }

  renderStep2() {
    return html`
      <h2>Introductory Materials</h2>

      <p>
        We're still working on finalizing the new orientation videos. But in the meantime we've
        included some links below.
      </p>
      
      <ul>
        <li><a target="_blank"
          href="https://www.linkedin.com/posts/quorum1_what-is-quorum1-and-why-are-thousands-of-activity-7252743998044319744-tKHo"
        >LinkedIn Video: Intro to Quorum1</a></li>
        <li>
          q1 Network Guidebook (Coming Soon)
        </li>
          <ul>
            <li>What is Quorum1</li>
            <li>What is the q1 Network</li>
            <li>First Steps on the q1 Network</li>
          </ul>
        <li><a target="_blank"
          href="https://www.youtube.com/@quorum_1"
        >Quorum1 Youtube Channel / Future of Work Podcast Series</a></li>
      </ul>

      <hr/>

      <h2>Quorum1 Events / Orientation Calls</h2>

      <p>
        If you're interested, please follow our Luma calendar for community events,
        orientation calls, and more.
      </p>

      <p>
        <a href="https://lu.ma/quorum1" target="_blank">https://lu.ma/quorum1</a>
      </p>

      <hr/>

      <h2>Additional Profile Details</h2>

      <p style="margin-bottom: 2.5rem">
        Please fill out a few more details to help us support you better and weave you more 
        effectively into the launch!
      </p>

      <form id="step-2-form" class="q1">

        <field-pair>
          <q1-image-upload-input
            id="profile_newUploadPath"
            name="profile_newUploadPath"
            upload-folder="member-profile"
            current-image-thumbnail=${this.cmImage_profile?.mini}
            @change="${this.handleInput}"
            label="Profile Image"
            required
          ></q1-image-upload-input>

          <input-wrapper>
            <label for="colorPrimary">Profile Color</label>
            <sl-color-picker
              id="colorPrimary"
              name="colorPrimary"
              .value=${this.currentMember?.colorPrimary}
              label="Profile Color"
              @sl-change="${this.handleInput}"
              size="large"
              swatches="#D81D39; #DB3A1A; #DE6616; #E2B013; #9DBC38; #53A84D; 
                #4AAB99; #488BAD; #4864AD; #7D52A3; #A74E9A; #C62F81;"
            ></sl-color-picker>
          </input-wrapper>
        </field-pair>

        <field-pair>
          <q1-places-input
            id="locations-input"
            label="Locations"
            help-text="Add all the places you've lived, worked, or built a significant network"
            .places=${this.locationsConfig}
            @change=${this.handleLocationsChange}
            required
          ></q1-places-input>

          <sl-textarea 
            id="headline"
            name="headline"
            .value="${this.headline}"
            @sl-input="${this.handleInput}"
            label="Profile Headline"
            rows="3"
            maxlength="250"
            required
          >
            <span slot="help-text">
              Shown under your name on your profile.
            </span>
          </sl-textarea>
        </field-pair>

      </form>
    `;
  }

  renderStep3() {
    return html`
      <h2>Any feedback or questions?</h2>
      
      <p>
        This is the last step for now!
        Please log back into the app regularly and keep an eye on your email.
      </p>

      <form id="step-3-form" class="q1">
        <sl-textarea
          id="betaFeedback" 
          name="betaFeedback" 
          .value="${this.betaFeedback}"
          @sl-input="${this.handleInput}"
          label="Any questions or feedback for the Quorum1 foundation team?"
          resize="auto"
          rows="3"
          required
        ></sl-textarea>
      </form>
    `;
  }

  renderStep4() {
    return html`
      <h2>FIXME - Stub of Step 4</h2>
      
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in 
        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur 
        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id 
        est laborum. His cu probo blandit. Vivendum intellegat et qui, ei denique consequuntur vix. 
        Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque 
        quo cu, veri persius vituperata ei nec. Sonet tibique sea et. Scripta periculis ei eam, te 
        pro movet reformidans. 
      </p>
    `;
  }

}

customElements.define('home-onboard-view', HomeOnboardView);
