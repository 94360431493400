import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { adminPlacesViewStyles } from './admin-places-view-styles.js';

import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/option/option.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/format-date/format-date.js';

import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';
import { FormHostMixin } from '../../modules/form-host-mixin.js';

import {
  db, doc, updateDoc, getDoc, deleteDoc, collection, getDocs, onSnapshot,
  query, orderBy, limit, limitToLast, startAfter, startAt, endBefore, endAt,
  dbUrlTemplate,
} from '../../firebaseConfig.js';

const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_SORT_FIELD = 'path';
const DEFAULT_SORT_DIRECTION = 'asc';

export class AdminPlacesView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    adminPlacesViewStyles,
  ];

  static properties = {
    ...super.properties,
    places: { type: Array },
    place: { type: Object },
    viewMode: { type: String }, 
    isLoading: { type: Boolean },
    hasPriorPage: { type: Boolean },
    hasNextPage: { type: Boolean },

    pageSizeString: { type: String }, // used to convert to number value
    pageSize: { type: Number },
    sortBy: { type: String },
    sortDirection: { type: String }, // asc, desc
  };

  constructor() {
    super();
    this.places = [];
    this.place = {};
    this.viewMode = 'details';
    this.isLoading = false;
    this.hasPriorPage = false;
    this.hasNextPage = false;

    this.pageSizeString = DEFAULT_PAGE_SIZE.toString();
    this.pageSize = DEFAULT_PAGE_SIZE;
    this.sortBy = DEFAULT_SORT_FIELD;
    this.sortDirection = DEFAULT_SORT_DIRECTION;
  }

  connectedCallback() {
    super.connectedCallback();
    this.setDocumentTitle('Q1 ADMIN: Places');

    this.queryPlaces();
  }

  refreshPlaces() {
    this.queryPlaces(0);
  }

  priorPage() {
    this.queryPlaces(-1);
  }
  
  nextPage() {
    this.queryPlaces(1);
  }

  /** This is needed to reset the row highlights after a refresh */
  resetTableFormat() {
    this.renderRoot.querySelectorAll('table tr').forEach(tr => { tr.className = '' });
  }

  /**
   * Queries the places, either moving forward/backward in pagination or re-getting the first page.
   * 
   * @param {Number} whichPage - Indicates which page with respect to the current (if there is one)
   *   - `0` (Default) = Refresh & reset to first page
   *   - `1` = Query the previous page
   *   - `-1` = Query the next page
   */
  queryPlaces(whichPage = 0) {
    this.isLoading = true;

    const placesCollection = collection(db, 'places');
    const queryConstraints = [orderBy(this.sortBy, this.sortDirection)];
    
    // Add the pagination restraints / detect error states
    if (whichPage === 0) {
      queryConstraints.push(limit(this.pageSize));
    } else if (whichPage < 0) {
      if (!this.places.length) {
        this.showToast('Cannot load previous page, no current page', null, 'danger');
        this.isLoading = false;
        return;
      }
      
      // IMPROVEME: Something about this is causing one-off pagination issues in dev.
      //   Seems like a bug or something.
      queryConstraints.push(limitToLast(this.pageSize)); // use limitToLast() for back pagination
      queryConstraints.push(endBefore(this.places[0].data[this.sortBy]));
    } else if (whichPage > 0) {
      if (!this.places.length) {
        this.showToast('Cannot load next page, no current page', null, 'danger');
        this.isLoading = false;
        return;
      }

      queryConstraints.push(limit(this.pageSize));
      queryConstraints.push(startAfter(this.places[this.places.length-1].data[this.sortBy]));
    }

    const placesQuery = query(placesCollection, ...queryConstraints);
    
    return getDocs(placesQuery).then((querySnapshot) => {
      if (querySnapshot.empty) {
        // We queried past the edge, so leave places as is and disable the appropriate button(s)
        this.showToast('No more results!');
        
        if (whichPage <= 0) this.hasPriorPage = false;
        if (whichPage >= 0) this.hasNextPage = false;
      } else {
        // First get the results and update this.places
        const placeDocs = [];
        querySnapshot.forEach((doc) => {
          placeDocs.push({
            ref: doc.ref,
            data: {
              ...doc.data(),
              id: doc.id,
              link: dbUrlTemplate('places', doc.id),
            }
          });
        });
        this.places = placeDocs;
        this.resetTableFormat();
  
        // Then update the prior/next buttons
        // NOTE: We're using CURSORS, so we don't have perfect visibility into this
        if (whichPage === 0) {
          this.hasPriorPage = false; // we're at the first page
          this.hasNextPage = this.places.length === this.pageSize; // likely true
        } else if (whichPage < 0) {
          this.hasPriorPage = true; // no way to really know
          this.hasNextPage = true; // we moved backward, so there's definitely a next page
        } else {
          this.hasPriorPage = true; // we moved forward, so there's definitely a prior page
          this.hasNextPage = this.places.length === this.pageSize; // likely true
        }
      }
      
      this.isLoading = false;
    }).catch((error) => {
      console.error('Error fetching places:', error);
      this.showToast(error.message, 'Error Loading Places', 'danger');
      this.hasPriorPage = false;
      this.hasNextPage = false;
      this.isLoading = false;
    });
  }

  updatePlace(placeRef, placeName, actionButton) {
    console.log('updatePlace: Starting', placeName);
    
    const placeRow = actionButton.closest('tr');
    actionButton.disabled = true;
    placeRow.classList.add('updating');

    updateDoc(placeRef, { status: 'updating' }).then(() => {
      console.log('updatePlace: Successful', placeName);
      actionButton.disabled = false;
      placeRow.classList.remove('updating');
      placeRow.classList.add('updated');
    }).catch((error) => {
      actionButton.disabled = false;
      placeRow.classList.remove('updating');
      placeRow.classList.add('failed');
      console.log('updatePlace: Error', placeName, error);
      this.showToast(error.message, `Error Updating ${placeName}`, 'danger');
    });
  }

  get handleInputPostProcess() {
    return {
      pageSizeString: (newValue) => { this.pageSize = parseInt(newValue) || DEFAULT_PAGE_SIZE },
    };
  }

  get contentTemplate() {
    return html`
      <h1>Admin Panel: Places</h1>

      <query-panel>
        <sl-select 
          id="pageSizeString"
          name="pageSizeString"
          value="${this.pageSizeString}"
          @sl-change="${this.handleChange}"
          label="Page Size"
        >
          <sl-option value="10">10</sl-option>
          <sl-option value="50">50</sl-option>
          <sl-option value="100">100</sl-option>
          <sl-option value="200">200</sl-option>
        </sl-select>
        
        <sl-select 
          id="sortBy"
          name="sortBy"
          value="${this.sortBy}"
          @sl-change="${this.handleChange}"
          label="Sort By"
        >
          <sl-option value="path">Path</sl-option>
          <sl-option value="createdAt">Created At</sl-option>
          <sl-option value="updatedAt">Updated At</sl-option>
        </sl-select>
        
        <sl-select 
          id="sortDirection"
          name="sortDirection"
          value="${this.sortDirection}"
          @sl-change="${this.handleChange}"
          label="Sort Direction"
        >
          <sl-option value="asc">Ascending</sl-option>
          <sl-option value="desc">Descending</sl-option>
        </sl-select>

        <button-bar>
          <sl-button @click=${this.refreshPlaces} variant="primary">
            <sl-icon library="material" name="refresh" slot="prefix"></sl-icon>
            Refresh
          </sl-button>
        </button-bar>
      </query-panel>

      ${this.paginationBarTemplate}

      <content-container>
        ${this.places.length ? this.placesTableTemplate : html`
          <p class="subtitle">
            No place records yet.
          </p>
        `}
        
        <q1-spinner-panel label="Loading..." ?open=${this.isLoading}></q1-spinner-panel>
      </content-container>

      ${this.paginationBarTemplate}
    `;
  }

  get paginationBarTemplate() {
    return html`
      <pagination-bar>
        <sl-button
          class="prior-page"
          @click="${this.priorPage}"
          size="large"
          ?disabled="${!this.hasPriorPage || this.isLoading}"
        >
          <sl-icon library="material" name="arrow_back" slot="prefix"></sl-icon>
          Prior Page
        </sl-button>
        
        <sl-button
          class="next-page"
          @click="${this.nextPage}"
          size="large"
          ?disabled="${!this.hasNextPage || this.isLoading}"
        >
          Next Page
          <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
        </sl-button>
      </pagination-bar>
    `;
  }

  get placesTableTemplate() {
    return html`
      <table>
        <thead>
          <tr>
            <th>Path</th>
            <th>Name</th>
            <th>Formatted Address</th>

            <th>Location: Country</th>
            <th>Location: L1</th>
            <th>Location: L2</th>
            <th>Location: L3</th>
            <th>Location: Locality</th>
            <th>Vicinity</th>

            <th>Created At</th>
            <th>Updated At</th>
            <th>Status</th>

            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          ${this.places.map(({ data: place, ref: placeRef }) => html`
            <tr>
              <td>${place.path || '-'}</td>
              <td>${place.name || '-'}</td>
              <td>${place.formattedAddress || '-'}</td>
              
              <td>${place.locationComponents?.country?.shortName || '-'}</td>
              <td>${place.locationComponents?.l1?.shortName || '-'}</td>
              <td>${place.locationComponents?.l2?.shortName || '-'}</td>
              <td>${place.locationComponents?.l3?.shortName || '-'}</td>
              <td>${place.locationComponents?.locality?.shortName || '-'}</td>
              <td>${place.vicinity || '-'}</td>
              
              <td>
                ${!place.createdAt?.toDate ? '-' : html`
                  <sl-format-date
                    date=${place.createdAt?.toDate()}
                    month="numeric" day="numeric" year="numeric" hour="numeric" minute="numeric"
                  ></sl-format-date>
                `}
              </td>
              <td>
                ${!place.updatedAt?.toDate ? '-' : html`
                  <sl-format-date
                    date=${place.updatedAt?.toDate()}
                    month="numeric" day="numeric" year="numeric" hour="numeric" minute="numeric"
                  ></sl-format-date>
                `}
              </td>
              <td>${place.status}</td>

              <td class="actions" id=${`actions-${place.id}`}>
                <sl-tooltip content="Update & Rebuild">
                  <sl-icon-button
                    library="material"
                    name="refresh"
                    label="Update & Rebuild"
                    @click="${(e) => this.updatePlace(placeRef, place.name, e?.target)}"
                  ></sl-icon-button>
                </sl-tooltip>
                <sl-tooltip content="Open in Database">
                  <sl-icon-button
                    library="material"
                    name="open_in_new"
                    label="Open in Database"
                    href=${place.link}
                    target="_blank"
                  ></sl-icon-button>
                </sl-tooltip>
              </td>
            </tr>
          `)}
        </tbody>
      </table>
    `;
  }

}

customElements.define('admin-places-view', AdminPlacesView);
