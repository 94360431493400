import { css } from 'lit';

export const homePublicViewStyles = css`

  :host {
    background: var(--gray-2);
  }

  view-content {
    margin: 0;
    padding: 55vh 0 0 0;
    background: white;
    max-width: none;
  }

  q1-globe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
  }
  q1-globe::after {
    content: '';
    
    position: absolute;
    width: 100%;
    height: 8rem;
    left: 0;
    bottom: 0;
    
    background: linear-gradient(0deg, white 0%, white 30%, transparent 100%);
  }

  section {
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }
  section-content {
    display: block;
    margin: 0 auto;
    padding: 0 2rem;
    
    box-sizing: content-box;
    max-width: var(--q1-view-width);
  }
  section-content .subtitle {
    margin: 2.5rem 0;
    display: block;
    font-size: 1.1rem;
    line-height: 1.3em;
  }

  section#hero {
    display: inline-block;
    position: absolute;
    top: 20vh;
    left: 2rem;
  }
  /* Special breakpoint. We want the left to be greater than 2rem when it kicks in. */
  @media(min-width: 1014px) {
    section#hero {
      left: calc((100% - 950px) / 2);
    }
  }

  .pretitle {
    font-weight: 500;
    font-size: 1.2rem;
  }
  #hero .pretitle {
    text-shadow: white 1px 1px 1px, white -1px -1px 1px;
    width: 14rem;
  }
  
  h1 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    font-size: 2rem;
    line-height: 1em !important;
    gap: 0.25em;
    margin: 0.5rem 0 0 0;
    padding: 1rem 1.2rem;

    background: var(--teal-7);
    color: white;
    border-radius: 2px;
  }
  @media(min-width: 500px) {
    h1 {
      flex-direction: row;
      align-items: center;
    }
  }

  section#subhero {
    padding: 3rem 0;
    border-top: 1px solid var(--teal-2);
    background: linear-gradient(156deg, var(--teal-6) 0%, var(--teal-8) 50%, var(--teal-9) 100%);
    color: white;
  }
  @media(min-width: 650px) {
    section#subhero {
      padding: 5rem 0;
      text-align: center;
    }
  }

  #subhero ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 2.5rem 0;
  }
  #subhero ul li {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;

    flex: 1 0 80%;
    transition: all 0.3s;
  }
  #subhero ul li:hover {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  }
  #subhero ul li sl-icon {
    font-size: 5rem;
    flex: 0 0 auto;
    color: var(--yellow-5);
  }
  #subhero ul li span {
    display: block;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.3em;
  }
  #subhero ul li span strong {
    font-size: 1.1rem;
    line-height: 1.7em;
  }
  @media(min-width: 650px) {
    #subhero ul {
      justify-content: center;
    }
    #subhero ul li {
      max-width: calc(50% - 1rem);
      flex-basis: 16rem;
      flex-direction: column;
      align-items: center;
    }
    #subhero ul li sl-icon {
      font-size: 7rem;
    }
    #subhero button-bar {
      justify-content: center;
    }
  }
  @media(min-width: 1000px) {
    #subhero ul {
      margin: 3.5rem 0;
    }
    #subhero ul li sl-icon {
      font-size: 10rem;
    }
  }

  section#social {
    position: relative;
    padding: 3rem 0;
    background: var(--gray-1);
    text-align: center;    
  }
  #social::before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 1;

    background-image: url('/assets/textures/grain-005.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.08;
  }
  @media(min-width: 650px) {
    section#social {
      padding: 5rem 0;
    }
  }

  #social section-content {
    position: relative;
    z-index: 5;
  }
  #social ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 2.5rem 0;
    gap: 1rem;
  }
  #social ul li {
    display: block;
    flex: 0 0 auto;
    padding: 0;
    margin: 0;
  }
  #social ul li img {
    height: 2rem;
    filter: saturate(0.7) opacity(0.7);
  }
  @media(min-width: 650px) {
    #social ul {
      gap: 1.5rem;
    }
    #social ul li img {
      height: 2.5rem;
    }
  }

  section#explainer {
    padding: 0;
    background: linear-gradient(156deg, var(--yellow-4) 0%, var(--yellow-6) 50%, var(--yellow-7) 100%);
    color: white;

    --cell-border-color: var(--yellow-4);
  }
  #explainer h2 {
    margin: 1rem 0;
  }
  #explainer h3 {
    margin: 0 0 1rem 0;
  }
  
  #explainer section-row.row1 {
    border-bottom-color: transparent;
  }

  #explainer section-row.row2 {
    border-color: transparent;
  }
  #explainer section-row.row2 section-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    padding-bottom: 0;
    
    background: rgba(0, 0, 0, 0.15);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-color: var(--yellow-3);
    border-bottom-color: transparent;
  }
  #explainer section-row.row2 cell-image {
    position: relative;
    bottom: -2px;
  }
  #explainer section-row.row2 img {
    height: 12rem;
  }
  @media(min-width: 500px) {
    #explainer section-row.row2 section-cell {
      flex-direction: row;
    }
  }
  @media(min-width: 650px) {
    #explainer section-row.row2 section-cell {
      gap: 1.5rem;
    }
  }
  @media(min-width: 1000px) {
    #explainer section-row.row2 section-cell {
      gap: 2rem;
    }
  }

  #explainer .subtitle {
    margin-bottom: 1.5rem;
  }
  #explainer section-row.row3 section-cell {
    height: 30rem;
    background: rgba(0, 0, 0, 0.05);
    padding-bottom: 0;
    z-index: 5;
  }
  #explainer section-row.row3 section-cell::before {
    content: '';
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    height: 18rem;
    background: radial-gradient(100% 100% at bottom right, var(--yellow-6) 5%, transparent 100%);
    z-index: 6;
  }
  #explainer section-row.row3 p {
    display: block;
    margin: 1rem 0;
  }
  #explainer section-row.row3 img {
    max-width: calc(100% + 6rem);
    max-height: 23rem;
  }
  @media(min-width: 650px) {
    #explainer section-row.row2 cell-inner {
      height: 100%;
    }
    #explainer section-row.row3 p {
      height: 12rem;
    }
  }
  @media(min-width: 1000px) {
    #explainer .subtitle {
      margin-bottom: 3rem;
    }
    #explainer section-row.row3 p {
      height: 8rem;
    }
  }

  section#structure {
    padding: 0;
    background: linear-gradient(156deg, var(--yellow-6) 0%, var(--yellow-7) 50%, var(--yellow-5) 100%);
    color: white;

    --cell-border-color: var(--yellow-5);
  }
  #structure h2 {
    margin: 0 0 1rem 0;
  }
  #structure h3 {
    margin: 0 0 1rem 0;
  }
  #structure .subtitle {
    margin: 2.5rem 0;
  }
  
  #structure section-row.row1 {
    border-top-color: transparent;
  }
  #structure section-row.row1 section-cell.image-content {
    display: flex;
    justify-content: center;
    align-items: center;

    border-top-color: transparent;
    background: radial-gradient(var(--teal-3), var(--teal-8));
  }
  #structure section-row.row1 section-cell.image-content sl-icon {
    font-size: 15rem;
    color: var(--teal-1);
  }
  #structure section-row.row1 section-cell.text-content {
    border-top-color: transparent;
    background: rgba(0, 0, 0, 0.1);
  }
  #structure section-row.row1 sl-button::part(base) {
    color: white;
    padding-left: 1.5rem;
    padding-right: 0.6rem;
  }
  
  #structure section-row.row2 {
    background: linear-gradient(180deg, transparent, var(--yellow-4));
    border: none;
    height: 3rem;
  }

  button-panel {
    display: block;
    padding: 1rem;
    background: linear-gradient(180deg, var(--yellow-4), var(--gray-2));
    text-align: center;
  }
  
  h2 {
    font-size: 1.8rem;
    line-height: 1.3em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  }

  sl-button[variant='primary']::part(base) {
    border: 1px solid var(--yellow-4);
  }
  sl-button[variant='warning']::part(base) {
    border: 1px solid var(--teal-4);
  }

  section-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    margin: 0 0 -1px 0;
    padding: 0 2rem;
    border-top: 1px solid var(--cell-border-color);
    border-bottom: 1px solid var(--cell-border-color);
  }
  section-row:last-child {
    margin-bottom: 0;
  }
  section-row.empty {
    height: 3rem;
  }
  section-cell {
    display: block;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    padding: 1.5rem;
    border: 1px solid var(--cell-border-color);
    margin: -1px; /* overlaps row and cell borders */

    /* This is both for full and for mobile layout for half & third */
    flex: 1 1 var(--q1-view-width);
    max-width: calc(var(--q1-view-width) + 2px);
  }
  @media(min-width: 650px) {
    section-row {
      flex-wrap: nowrap;
    }
    section-row.empty {
      height: 5rem;
    }
    section-cell.half {
      flex: 1 1 calc(var(--q1-view-width) / 2);
      max-width: calc((var(--q1-view-width) / 2) + 1px);
      margin-right: 0;
    }
    section-cell.half:last-child {
      margin-right: -1px;
      max-width: calc((var(--q1-view-width) / 2) + 2px);
    }
    section-cell.third {
      flex: 1 1 calc(var(--q1-view-width) / 3);
      max-width: calc((var(--q1-view-width) / 3) + 1px);
      margin-right: 0;
    }
    section-cell.third:last-child {
      margin-right: -1px;
      max-width: calc((var(--q1-view-width) / 3) + 2px);
    }
  }
  @media(min-width: 1000px) {
    section-cell {
      padding: 3rem;
    }
  }

  section-cell img {
    display: block;
  }
  cell-inner {
    display: block;
    flex: 1 1 auto;
  }
  cell-image {
    display: block;
    flex: 1 1 auto;
  }

`;
