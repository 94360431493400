import { functions, httpsCallable } from '../firebaseConfig.js';

const q1RegistrationFunctions = {
  verifyRegCode: httpsCallable(functions, 'registration-verifyRegCode'),
  newMember: httpsCallable(functions, 'registration-newMember'),
  sendClaimCode: httpsCallable(functions, 'registration-sendClaimCode'),
  verifyClaimCode: httpsCallable(functions, 'registration-verifyClaimCode'),
  claimMember: httpsCallable(functions, 'registration-claimMember'),
};

const q1VerificationFunctions = {
  resetVerificationCode: httpsCallable(functions, 'verification-resetVerificationCode'),
  processVerificationCode: httpsCallable(functions, 'verification-processVerificationCode'),
  removeEmail: httpsCallable(functions, 'verification-removeEmail'),
  addEmail: httpsCallable(functions, 'verification-addEmail'),
  setPrimaryEmail: httpsCallable(functions, 'verification-setPrimaryEmail'),
  changePhone: httpsCallable(functions, 'verification-changePhone'),
};

const q1PasswordFunctions = {
  initiateResetByEmail: httpsCallable(functions, 'password-initiateResetByEmail'),
  initiateResetByAuth: httpsCallable(functions, 'password-initiateResetByAuth'),
  verifyResetCode: httpsCallable(functions, 'password-verifyResetCode'),
  processReset: httpsCallable(functions, 'password-processReset'),
  changePassword: httpsCallable(functions, 'password-changePassword'),
};

const q1MemberFunctions = {
  getCurrentMember: httpsCallable(functions, 'member-getCurrentMember'),
  getMemberProfile: httpsCallable(functions, 'member-getMemberProfile'),
  browseMembers: httpsCallable(functions, 'member-browseMembers'),
  getAppStats: httpsCallable(functions, 'member-getAppStats'),
};

const q1PlaceFunctions = {
  getPlaceTree: httpsCallable(functions, 'place-getPlaceTree'),
};

const q1ListFunctions = {
  getProjects: httpsCallable(functions, 'list-getProjects'),
};

const q1OnboardingFunctions = {
  updateOnboardStatus: httpsCallable(functions, 'onboarding-updateOnboardStatus'),
};

export {
  q1RegistrationFunctions,
  q1VerificationFunctions,
  q1PasswordFunctions,
  q1MemberFunctions,
  q1PlaceFunctions,
  q1ListFunctions,
  q1OnboardingFunctions,
}
