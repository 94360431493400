import { html, css, LitElement } from 'lit';

import { storageBaseUrl } from '../../firebaseConfig.js';

import { q1AppHeaderStyles } from './q1-app-header-styles.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';

// this should sync with the css styles for `.nav-link span`
const NAV_LINK_LABEL_MIN_WIDTH = 500;

export class Q1AppHeader extends LitElement {
  static styles = [ q1AppHeaderStyles ];

  static properties = {
    variant: { type: String, reflect: true }, // default, flat, white-text

    currentUser: { type: Object },
    currentMember: { type: Object },
    isSignedIn: { type: Boolean },
    isAdmin: { type: Boolean },
    
    hideCenterNav: { type: Boolean, attribute: 'hide-center-nav' },
    hideRightNav: { type: Boolean, attribute: 'hide-right-nav' },
    hideLeftNav: { type: Boolean, attribute: 'hide-left-nav' },
    enableSlot: { type: Boolean, attribute: 'enable-slot' }, // FIXME: Temp, unfinished

    cmImage_profile: { type: Object },
    cmImage_banner: { type: Object },

    appElement: { type: Object },
    _resizeObserver: { type: Object },
    _navLinkTooltipsDisabled: { type: Boolean },
  };

  constructor() {
    super();

    this.variant = 'default';

    this.currentUser = null;
    this.currentMember = null;
    this.isSignedIn = false;
    this.isAdmin = false;

    this.hideCenterNav = false;
    this.hideRightNav = false;
    this.hideLeftNav = false;
    this.enableSlot = false;

    this._navLinkTooltipsDisabled = false;
  }

  connectedCallback() {
    super.connectedCallback();

    this.appElement = document.querySelector('q1-home');

    this._resizeObserver = new ResizeObserver(entries => {
      const lastEntry = entries[entries.length - 1];
      if (lastEntry) {
        const { width } = lastEntry.contentRect;
        
        this._navLinkTooltipsDisabled = (width >= NAV_LINK_LABEL_MIN_WIDTH);
      }
    });
    this._resizeObserver.observe(this);
  }

  updated(changedProperties) {
    if (changedProperties.has('currentMember')) {
      this.cmImage_profile = {
        mini: this.docImageUrl(this.currentMember, 'profile', 'mini'),
        small: this.docImageUrl(this.currentMember, 'profile', 'small'),
        standard: this.docImageUrl(this.currentMember, 'profile', 'standard'),
      };
      this.cmImage_banner = {
        mini: this.docImageUrl(this.currentMember, 'banner', 'mini'),
        small: this.docImageUrl(this.currentMember, 'banner', 'small'),
        standard: this.docImageUrl(this.currentMember, 'banner', 'standard'),
      };
    }
  }

  handleMenuSelect(event) {
    event.preventDefault();
    const menuItemValue = event.detail?.item?.value;

    switch (menuItemValue) {
      case 'admin':
        this.appElement.navigateTo('/a');
        break;

      case 'profile':
        this.appElement.navigateTo(`/@${this.currentMember.number}`);
        break;
      
      case 'settings':
        this.appElement.navigateTo('/settings');
        break;
      
      case 'sign-out':
        this.appElement.handleSignOut();
        break;
      
      default:
        console.log(`INVALID menu select item value: ${menuItemValue}`);
    }
  }

  handleSignOut() {
    this.dispatchEvent(new CustomEvent('sign-out', {
      detail: {},
      bubbles: true,
      composed: true,
    }));
  }

  docImageUrl(doc, imageField, sizeKey) {
    const imagePath_raw = doc?.[imageField]?.[sizeKey]?.path;
    if (!imagePath_raw) return null;

    const imagePath_clean = imagePath_raw.startsWith('/')
      ? imagePath_raw.substring(1)
      : imagePath_raw;

    const imagePath_encoded = encodeURIComponent(imagePath_clean);
    
    return `${storageBaseUrl}/${imagePath_encoded}?alt=media`;
  }

  render() {
    return html`
      <div id="header-base" part="base">
        ${this.hideLeftNav ? null : html`
          <div class="left-nav">
            <sl-tooltip content="q1 Network Home">
              <a href="${this.isSignedIn ? '/home' : '/'}">
                <sl-icon
                  library="q1"
                  name="q1-logo"
                  label="Q1 Logo"
                  class="logo"
                ></sl-icon>
              </a>
            </sl-tooltip>
          </div>
        `}
  
        ${this.hideCenterNav ? null : html`
          <div class="center-nav">
            <sl-tooltip content="Guilds" ?disabled=${this._navLinkTooltipsDisabled}>
              <a href="/guilds" class="nav-link">
                <sl-icon library="material" name="hive"></sl-icon>
                <span>Guilds</span>
              </a>
            </sl-tooltip>
            <sl-tooltip content="Projects" ?disabled=${this._navLinkTooltipsDisabled}>
              <a href="/projects" class="nav-link">
                <sl-icon library="material" name="business_center"></sl-icon>
                <span>Projects</span>
              </a>
            </sl-tooltip>
            <sl-tooltip content="Members" ?disabled=${this._navLinkTooltipsDisabled}>
              <a href="/members" class="nav-link">
                <sl-icon library="material" name="diversity_3"></sl-icon>
                <span>Members</span>
              </a>
            </sl-tooltip>
          </div>
        `}
  
        ${this.hideRightNav ? null : html`
          <div class="right-nav">
            ${this.isSignedIn ? html`
  
              <!-- <sl-button
                size="small"
                pill
                href="/invite"
                variant="warning"
                outline
              >
                <sl-icon library="material" name="person_add" slot="prefix"></sl-icon>
                Invite
              </sl-button> -->
  
              <sl-dropdown distance="8" hoist>
                <sl-avatar
                  id="main-avatar"
                  slot="trigger"
                  label="${this.currentMember.nameInternal}"
                  image=${this.cmImage_profile?.mini || ''}
                ></sl-avatar>
                <sl-menu @sl-select=${this.handleMenuSelect}>
                  <sl-menu-item value="profile" class="menu-header">
                    <sl-avatar
                      id="menu-avatar"
                      slot="prefix"
                      label="${this.currentMember.nameInternal}"
                      image=${this.cmImage_profile?.mini || ''}
                    ></sl-avatar>
                    <span class="menu-title">${this.currentMember.nameInternal}</span>
                    <br/>
                    <span class="menu-subtitle">@${this.currentMember.number}</span>
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item value="profile">
                    View Profile
                  </sl-menu-item>
                  <sl-menu-item value="settings">
                    Settings
                  </sl-menu-item>
                  <sl-menu-item value="sign-out">
                    Sign Out
                  </sl-menu-item>
  
                  ${this.isAdmin ? html`
                    <sl-divider></sl-divider>
                    <sl-menu-item value="admin">
                      Admin
                    </sl-menu-item>
                  ` : ''}
                </sl-menu>
              </sl-dropdown>
  
            `:html`
  
              <sl-button size="small" pill href="/signin">Sign In</sl-button>
              <sl-button size="small" pill variant="primary" href="/join">Join</sl-button>
  
            `}
          </div>
        `}
      </div>
      
      ${!this.enableSlot ? null : html`
        <div id="slot-container" part="slot-base">
          <div id="slot-wrapper" part="slot-wrapper">
            <slot></slot>
          </div>
        </div>
      `}
    `;
  }
}

customElements.define('q1-app-header', Q1AppHeader);