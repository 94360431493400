import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { guildViewStyles } from './guild-view-styles.js';
import { functions, httpsCallable } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';

export class GuildView extends ViewBase {
  static styles = [
    ...super.styles,
    guildViewStyles
  ];

  static properties = {
    ...super.properties,
    guild: { type: Object },
    loading: { type: Boolean },
    error: { type: String }
  };

  constructor() {
    super();
    this.guild = null;
    this.loading = true;
    this.error = '';
  }

  connectedCallback() {
    super.connectedCallback();
    
    if (this.isSignedIn && this.routeParams.key) {
      this.fetchGuild();
    }
  }

  async fetchGuild() {
    const getGuild = httpsCallable(functions, 'list-getGuild');

    try {
      const result = await getGuild({ guildId: this.routeParams.key });
      this.guild = result.data.guild;
      this.setDocumentTitle(`${this.guild.name} - q1 Network Guilds`);
    } catch (error) {
      console.error('Error fetching guild:', error);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }

  get contentTemplate() {
    if (!this.isSignedIn) {
      return html`
        <sl-alert variant="primary" open>
          Please sign in to view guild details.
        </sl-alert>
      `;
    }

    if (this.loading) {
      return html`
        <div class="loading-container">
          <sl-spinner></sl-spinner>
        </div>
      `;
    }

    if (this.error) {
      return html`
        <sl-alert variant="danger" open>
          ${this.error}
        </sl-alert>
      `;
    }

    return html`
      <div class="guild-container">
        <h1>${this.guild.name}</h1>
        <div class="guild-details">
          <p><strong>Point Number:</strong> ${this.guild.pointNumber}</p>
          <p class="description">${this.guild.description}</p>
          
          ${this.guild.betaOrganizerNumbers?.length ? html`
            <p>
              <strong>Beta Organizers:</strong> 
              ${this.guild.betaOrganizerNumbers.join(', ')}
            </p>
          ` : ''}
        </div>
      </div>
    `;
  }
}

customElements.define('guild-view', GuildView);