import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { guildIndexViewStyles } from './guild-index-view-styles.js';
import { functions, httpsCallable } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';

export class GuildIndexView extends ViewBase {
  static styles = [
    ...super.styles,
    guildIndexViewStyles
  ];

  static properties = {
    ...super.properties,
    guilds: { type: Array },
  };

  constructor() {
    super();
    this.guilds = [];
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Guilds - q1 Network');
  }

  get headerTemplate() {
    return html`
      <q1-app-header
        variant="white-text"
        .currentUser="${this.currentUser}"
        .currentMember="${this.currentMember}"
        .isSignedIn="${this.isSignedIn}"
        .isAdmin="${this.isAdmin}"
      ></q1-app-header>
    `;
  }

  get footerTemplate() {
    return html`
      <q1-app-footer variant="white-text"></q1-app-footer>
    `;
  }

  get contentTemplate() {
    return html`
      <h1>q1 Network Guilds</h1>

      <sl-divider></sl-divider>

      <notice-panel class="hero secondary">
        <div class="inner">
          <sl-icon library="vectors" name="global-collaboration"></sl-icon>

          <h2>q1 Network guilds are communities of work...</h2>

          <p>
            The guild functionality of the network is not yet generally available.
          </p>

          <p>
            Guilds in the q1 Network are built around practice, purpose, and/or place.
            Some guilds are co-ventures with multiple organizations or other networks.
          </p>

          ${!!this.isSignedIn ? null : html`
            <p>
              <em>
                If you'd like to create a guild, co-venture around a guild, or join a guild...
                we recommend joining now so you can be ready for the wider release.
              </em>
            </p>

            <sl-button
              variant="primary"
              size="large"
              href="/join"
              pill
            >
              Join the Quorum
            </sl-button>
          `}
        </div>
      </notice-panel>
    `;
  }
}

customElements.define('guild-index-view', GuildIndexView);