import { css } from 'lit';

export const homeOnboardViewStyles = css`
  
  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 85%, var(--teal-2) 100%);
  }

  h1, h2, h3 {
    line-height: 1.3em;
  }
  h1 .prefix {
    display: block;
    font-weight: 100;
  }

  content-container {
    display: block;
    position: relative; /* needed to contain spinner panel */
  }

  button-bar {
    justify-content: space-between;
  }

`;
