import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { auth, signInWithCustomToken } from '../../firebaseConfig.js';

import { claimViewStyles } from './claim-view-styles.js';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

import { FormHostMixin } from '../../modules/form-host-mixin.js';
import { formatFirebaseError } from '../../modules/firebase-error-helper.js';
import { q1RegistrationFunctions } from '../../modules/q1-core-functions.js'
import { Q1TelInput } from '../../components/q1-tel-input/q1-tel-input.js';

const PHONE_HELP_TEXT = 'Used for important comms. '
  + 'By providing your phone number you are consenting to receive occasional text messages '
  + 'from Quorum1 and the q1 network, according to your preferences.';

export class ClaimView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    claimViewStyles,
  ];

  static properties = {
    ...super.properties,

    claimEmail: { type: String },
    claimCode: { type: String },
    
    // The read-only fields
    memberId: { type: String },
    emailPrimary: { type: String },
    emailsAll: { type: Array },
    level: { type: String },
    linkedinProfileUrl: { type: String },
    number: { type: String },

    // The editable fields
    nameInternal: { type: String },
    nameExternal: { type: String },
    nameLegal: { type: String },
    phoneMobile: { type: String },
    password: { type: String },

    viewMode: { type: String }, // unverified, verify-processing, ready, processing, form-error
    errorMessage: { type: String },
  };

  constructor() {
    super();

    this.claimEmail = this.urlParams.email || '';
    this.claimCode = this.urlParams.code || '';

    this.password = '';

    this.viewMode = 'unverified';
  }
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Claim Existing Membership - q1 Network');
  }

  emailClaimCode() {
    const { sendClaimCode } = q1RegistrationFunctions;

    if (!this.claimEmail?.length) {
      this.showToast('Please fill in the Member Email field first!', null, 'danger');
      return;
    }

    if (!confirm(`Would you like to email your claim code to ${this.claimEmail}?`)) return;

    this.viewMode = 'verify-processing';

    sendClaimCode({ email: this.claimEmail }).then((result) => {
      console.log('sendClaimCode result: ', result);

      this.showToast(result.data.message, null, 'success');
      this.viewMode = 'unverified';
    }).catch((error) => {
      console.error('Error calling function:', error.message);

      this.showToast('', 'Could not send claim code', 'danger');
      
      this.viewMode = 'unverified';
    });
  }

  handleVerificationFormSubmit(event) {
    const { verifyClaimCode } = q1RegistrationFunctions;
    const verificationForm = this.renderRoot.querySelector('#verification-form');
    const claimCodeInput = this.renderRoot.querySelector('#claimCode');
    
    event.preventDefault();
    
    if (!verificationForm.reportValidity()) {
      this.showToast('Please fix the form validation errors to continue', null, 'danger');
      return;
    }

    this.viewMode = 'verify-processing';
    this.claimCode = this.claimCode.trim().toLowerCase();

    const verificationFormData = { claimEmail: this.claimEmail, claimCode: this.claimCode };
    console.log('VERIFICATION SUBMIT. verificationFormData: ', verificationFormData);
    
    verifyClaimCode(verificationFormData).then((result) => {
      console.log('verifyClaimCode result: ', result);

      const memberData = result.data.member;
      this.memberId = memberData.id;
      this.emailPrimary = memberData.emailPrimary;
      this.emailsAll = memberData.emailsAll;
      this.level = memberData.level;
      this.linkedinProfileUrl = memberData.linkedinProfileUrl;
      this.number = memberData.number;
      this.nameExternal = memberData.nameExternal || '';
      this.nameInternal = memberData.nameInternal || '';
      this.nameLegal = memberData.nameLegal || '';
      this.phoneMobile = memberData.phoneMobile || '';

      this.showToast('Claim code verified!', null, 'success');
      this.viewMode = 'ready';
    }).catch((error) => {
      console.error('Error calling function:', error.message);

      this.showToast('', 'Could not verify claim code', 'danger');
      
      this.viewMode = 'unverified';
      this.updateComplete.then(() => {
        const claimCodeInput = this.renderRoot.querySelector('#claimCode');
        claimCodeInput.focus();
      });
    });
  }
  
  handleClaimFormSubmit(event) {
    const { claimMember } = q1RegistrationFunctions;
    event.preventDefault();
    const claimForm = this.renderRoot.querySelector('#claim-form');
    
    if (!claimForm.reportValidity()) {
      this.showToast('Please fix the form validation errors to continue', null, 'danger');
      return;
    }

    this.viewMode = 'processing';
    this.errorMessage = '';

    const claimFormData = this._serializeForm(claimForm);
    console.log('CLAIM SUBMIT. claimFormData: ', claimFormData);
    
    claimMember(claimFormData).then((result) => {
      console.log('claimMember result: ', result);

      const authToken = result?.data?.authToken;

      signInWithCustomToken(auth, authToken).then((userCredential) => {
        console.log('Sign in successful, userCredential: ', userCredential);
        
        document.querySelector('q1-home').queueToast(
          'The next step is to finish the onboarding steps',
          'Membership claimed & activated!', 'success'
        );
        
        // NOTE: We want to trigger a full reload
        window.location.href = '/home/onboard';
      }).catch((error => {
        error = formatFirebaseError(error);
        this.errorMessage = `Error signing in with token. Message: ${error}`;
        console.log(this.errorMessage);
        this.viewMode = 'form-error';
      }));
    }).catch((error) => {
      console.error('Error calling function:', error.message);

      this.showToast('There was an error processing your registration!', null, 'danger');
      this.errorMessage = error.message;
      this.viewMode = 'form-error';
    });
  }

  get headerTemplate() {
    return html`
      <q1-app-header
        hide-center-nav
        hide-right-nav
      ></q1-app-header>
    `;
  }

  get contentTemplate() {
    if (['unverified', 'verify-processing'].includes(this.viewMode)) return html`
      
      <h1>Claim Existing Quorum1 Membership</h1>

      <div class="subtitle">
        <strong>This page helps existing Quorum1 members get setup in the q1 Network.</strong>
        <br/><br/>
        If you are already a Quorum1 member, but haven't setup your account in the q1 Network yet,
        then this form is you! If you're a new member, please use the 
        <a href="/register">registration page</a>
        instead.
      </div>

      <hr/>

      <form
        id="verification-form"
        class="q1"
        @submit=${this.handleVerificationFormSubmit}
      >
        <field-pair>
          <sl-input 
            id="claimEmail"
            name="claimEmail"
            .value="${this.claimEmail}"
            @sl-input="${this.handleInput}"
            type="email"
            label="Member Email" 
            size="large"
            required
            autofocus
          >
            <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
            <span slot="help-text">
              Enter any of the emails associated with your existing Quorum1 membership
            </span>
          </sl-input>
          
          <sl-input 
            id="claimCode" 
            name="claimCode" 
            .value="${this.claimCode}"
            @sl-input="${this.handleInput}"
            label="Claim Code"
            size="large"
            required
          >
            <sl-icon library="material" name="password" slot="prefix"></sl-icon>
            <span slot="help-text">
              If you already have your claim code then enter it here.
              Click the button below to receive your code via email.
            </span>
          </sl-input>
        </field-pair>

        <hr/>

        <button-bar>
          <sl-button
            variant="neutral"
            size="large"
            pill
            ?disabled="${this.viewMode === 'verify-processing'}"
            @click=${this.emailClaimCode}
          >
            Send Claim Code
            <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
          </sl-button>

          <sl-button
            variant="warning"
            size="large"
            type="submit"
            pill
            ?disabled="${this.viewMode === 'verify-processing'}"
          >
            Verify & Continue
            <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
          </sl-button>
        </button-bar>
      </form>

    `;

    return html`
      <h1>Your claim code is verified!</h1>

      <p class="subtitle">
        Welcome to the shiny new q1 Network!
        Let's get your existing Quorum1 membership activated in the new system.
      </p>

      <p class="subtitle">
        Fill out the form below to finish claiming & activating your membership in the q1 Network.
      </p>

      ${this.viewMode !== 'processing' ? null : html`
        <sl-alert variant="primary" open>
          <sl-spinner slot=icon style="font-size: 1.5rem;"></sl-spinner>
          <strong>Processing your registration...</strong><br/>
        </sl-alert>
      `}

      <hr/>

      <form id="claim-form" class="q1" @submit=${this.handleClaimFormSubmit}>

        <input type="hidden" name="memberId" value=${this.memberId} />
        <input type="hidden" name="claimCode" value=${this.claimCode} />
        
        ${this.viewMode !== 'form-error' ? null : html`
          <sl-alert variant="danger" open>
            <sl-icon library="material" slot="icon" name="warning"></sl-icon>
            <strong>An error occurred claiming your membership.</strong><br/>
            
            ${this.errorMessage}
          </sl-alert>
        `}
        
        <h2>Existing Membership Details</h2>

        <info-panel>
          <p>
            <em>These details aren't editable until after you activate your membership.</em>
          </p>

          <ul>
            <li>
              <strong>Primary Email:</strong>
              ${this.emailPrimary}
            </li>
            <li>
              <strong>All Emails:</strong>
              ${this.emailsAll?.join(', ')}
            </li>
            <li>
              <strong>Membership:</strong>
              ${this.level}
            </li>
            <li>
              <strong>Linkedin Profile URL:</strong>
              ${this.linkedinProfileUrl}
            </li>
            <li>
              <strong>Member Number:</strong>
              ${this.number}
            </li>
          </ul>
        </info-panel>

        <hr/>

        <h2>Membership Activation</h2>
        
        <field-pair>
          <sl-input 
            id="nameInternal" 
            name="nameInternal" 
            .value="${this.nameInternal}"
            @sl-input="${this.handleInput}"
            label="Display Name (Internal)" 
            help-text="What other Quorum1 members see"
            required
          ></sl-input>

          <sl-input 
            id="nameExternal" 
            name="nameExternal" 
            .value="${this.nameExternal}"
            @sl-input="${this.handleInput}"
            label="Profile Name (External)" 
          >
            <span slot="help-text">
              What is shown publicly to non-members.
              Set this field to a blank value to be externally anonymous.
            </span>
          </sl-input>
        </field-pair>
        
        <field-pair>
          <q1-tel-input
            id="phoneMobile"
            name="phoneMobile"
            .value="${this.phoneMobile}"
            @input="${this.handleInput}"
            label="Mobile Phone"
            help-text=${PHONE_HELP_TEXT}
            required
          ></q1-tel-input>
          <field-spacer></field-spacer>
        </field-pair>

        <field-pair>
          <sl-input 
            id="password"
            name="password"
            .value="${this.password}"
            @sl-input="${this.handleInput}"
            label="Password" 
            type="password"
            required
            minlength="6"
            help-text="You'll use this to sign in to the q1 Network. This doesn't impact Slack yet."
            password-toggle
          ></sl-input>
          <field-spacer></field-spacer>
        </field-pair>

        <hr/>

        <h2>Legal Agreement</h2>
        
        <field-pair>
          <sl-input 
            id="nameLegal"
            name="nameLegal"
            .value="${this.nameLegal}"
            @sl-input="${this.handleInput}"
            label="Legal Name" 
            help-text="Used when you sign agreements, kept private otherwise"
            required
          >
            <sl-icon library="material" name="signature" slot="prefix"></sl-icon>
            <sl-icon library="material" name="lock" slot="suffix"></sl-icon>
          </sl-input>
          <field-spacer></field-spacer>
        </field-pair>

        <p>
          As an existing member, you've already agreed to this, but just to be extra clear:
          by being a part of Quorum1 you are agreeing to our
          <a target="_blank"
            href="https://github.com/quorum1/governance/blob/main/docs/community-agreement.md"
          >Member Agreement</a>
          and 
          <a target="_blank"
            href="https://github.com/quorum1/governance/blob/main/docs/terms-of-service.md"
          >Terms of Service</a>.
        </p>
        
        <hr/>

        <sl-button
          variant="primary"
          size="large"
          type="submit"
          pill
          ?disabled="${this.viewMode === 'processing'}"
        >
          Save &amp; Continue
          <sl-icon library="material" name="arrow_forward" slot="suffix"></sl-icon>
        </sl-button>
      </form>

    `;
  }
}

customElements.define('claim-view', ClaimView);
