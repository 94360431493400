import { css } from 'lit';

export const adminPlacesViewStyles = [
  css`

    :host {
      background: var(--blue-1);
    }
    
    view-content {
      max-width: none;
    }

    h1 {
      font-size: 1.5rem;
      font-weight: var(--sl-font-weight-semibold);
      margin-bottom: 1rem;
      color: var(--sl-color-neutral-900);
    }

    content-container {
      display: block;
      position: relative;
      min-height: 20rem;
      overflow-x: scroll;
    }

    query-panel {
      display: flex;
      gap: 0.5rem;
      margin: 1rem 0;
      align-items: center;
    }

    query-panel button-bar {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      padding: 0 0 0 1rem;
      margin: 0.5rem 0 0 0;
      flex: 1 1 auto;
      justify-content: flex-end;
    }

    pagination-bar {
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;
      align-items: center;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin: 1rem 0;
      background-color: var(--sl-color-neutral-100);
      border: 1px solid var(--sl-color-neutral-300);
    }

    thead {
      background-color: var(--sl-color-primary-50);
    }

    th {
      text-align: left;
      padding: var(--sl-spacing-medium);
      font-size: var(--sl-font-size-small);
      font-weight: var(--sl-font-weight-semibold);
      color: var(--sl-color-neutral-900);
      border-bottom: 1px solid var(--sl-color-neutral-300);
      white-space: nowrap;
    }

    tbody tr:nth-child(even) {
      background-color: var(--sl-color-neutral-50);
    }

    tbody tr.updating {
      font-style: italic;
      background-color: var(--yellow-2);
    }
    tbody tr.updated {
      background-color: var(--green-1);
    }
    tbody tr.failed {
      background-color: var(--red-1);
    }

    td {
      padding: var(--sl-spacing-medium);
      font-size: var(--sl-font-size-small);
      color: var(--sl-color-neutral-700);
      border-bottom: 1px solid var(--sl-color-neutral-200);
      
      white-space: nowrap;
      max-width: 15rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    td.actions {
      padding: var(--sl-spacing-x-small);
    }
    td.actions sl-icon-button {
      font-size: 1.3rem;
    }

  `,
];
