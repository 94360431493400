import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { homePublicViewStyles } from './home-public-view-styles.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';

import * as d3 from 'd3'; 
import 'd3-dsv';

import Globe from 'globe.gl';

const GLOBE_HEIGHT = 0.6; // as percentage of client view height

export class HomePublicView extends ViewBase {
  static styles = [
    ...super.styles,
    homePublicViewStyles,
  ];

  static properties = {
    ...super.properties,

    globe: { type: Object },
    controls: { type: Object },
  };

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle(
      'The q1 Network - Hosting the Future of Work - Entrepreneurship, Impact, Emerging Tech'
    );
  }

  firstUpdated() {
    const q1GlobeElement = this.renderRoot.querySelector('q1-globe');

    this.globe = new Globe(q1GlobeElement, { animateIn: false })
      .globeImageUrl('/assets/images/earth.png')
      .width(document.body.clientWidth)
      .height(document.body.clientHeight * GLOBE_HEIGHT)
      .backgroundColor('#FFFFFF')
      .atmosphereColor('#61F8E6')
      .hexBinPointWeight('pop')
      .hexAltitude(d => d.sumWeight * 6e-8)
      .hexBinResolution(4)
      .hexTopColor(() => '#E4B21A')
      .hexSideColor(() => '#F9C62A')
      .ringsData([])
      .ringColor(() => '#B7901C')
      .ringMaxRadius(10)
      .ringPropagationSpeed(3)
      .hexBinMerge(true)
      .enablePointerInteraction(true); // performance improvement

    fetch('assets/data/world_population.csv').then(res => res.text())
      .then(csv => d3.csvParse(csv, ({ lat, lng, pop }) => ({ lat: +lat, lng: +lng, pop: +pop })))
      .then(data => this.globe.hexBinPointsData(data));

    this.controls = this.globe.controls();
    this.controls.autoRotate = true;
    this.controls.enableZoom = false;

    this.globe.onGlobeClick(({ lat, lng }) => {
      this.globe.ringsData([...this.globe.ringsData(), { lat, lng }]);
    });

    this._resizeObserver = new ResizeObserver(entries => {
      const lastEntry = entries[entries.length - 1];
      if (lastEntry) {
        const { width } = lastEntry.contentRect;
        this.globe.width(width);
        this.globe.height(document.body.clientHeight * GLOBE_HEIGHT);
      }
    });
    this._resizeObserver.observe(this);
  }

  _handleResize() {

  }

  get contentTemplate() {
    return html`

      <section id="hero">
        <div class="pretitle">The q1 Network hosts</div>
        <h1>
          the future of
          <q1-text-morph morph-time="0.6" cooldown-time="1.5">
            work
            collaboration
            impact
            venturing
            investment
            startups
            teamwork
          </q1-text-morph>
        </h1>
      </section>

      <section id="subhero">
        <section-content>
          <h2>We are a global collective. We exist to help you...</h2>

          <ul>
            <li>
              <sl-icon library="vectors" name="growth"></sl-icon>
              <span>
                <strong>Learn & Grow</strong><br/>
                Follow inspiring projects aligned with your interests & learning goals
              </span>
            </li>
            <li>
              <sl-icon library="vectors" name="work"></sl-icon>
              <span>
                <strong>Work & Earn</strong><br/>
                Contribute your work for fun, for money, or for future returns
              </span>
            </li>
            <li>
              <sl-icon library="vectors" name="innovation"></sl-icon>
              <span>
                <strong>Build & Operate</strong><br/>
                Source help for your organization or startup something brand new
              </span>
            </li>
          </ul>
          
          <button-bar>
            <sl-button
              variant="primary"
              size="large" class="jumbo"
              href="/join"
              pill
            >
              Join the Quorum
            </sl-button>
          </button-bar>
        </section-content>
      </section>

      <section id="social">
        <section-content>
          
          <h2>The q1 network is launching soon!</h2>

          <hr/>

          <div class="subtitle">
            <strong>We're currently in private beta.</strong>
            Wider access is rolling out from Nov 2024 through Feb 2025.<br/><br/>
            
            The q1 Network is backed by a diverse global community of thousands of Quorum1 members,
            supporters, investors, clients, and partners. Here are just a few.
          </div>

          <hr/>

          <ul>
            <li><img src="assets/logos/slack.png"></li>
            <li><img src="assets/logos/republic.png"></li>
            <li><img src="assets/logos/learning-economy-foundation.png"></li>
            <li><img src="assets/logos/dict.png"></li>
            <li><img src="assets/logos/aircall.png"></li>
            <li><img src="assets/logos/aurora-solar.png"></li>
            <li><img src="assets/logos/building-beyond.png"></li>
            <li><img src="assets/logos/hubspot.png"></li>
            <li><img src="assets/logos/covalence.png"></li>
            <li><img src="assets/logos/dumele.png"></li>
            <li><img src="assets/logos/scoby-collective.png"></li>
            <li><img src="assets/logos/salesforce.png"></li>
            <li><img src="assets/logos/redwire.png"></li>
            <li><img src="assets/logos/human-potential-capital.png"></li>
            <li><img src="assets/logos/gaianet.png"></li>
          </ul>
        </section-content>
      </section>
      
      <section id="explainer">
        <section-row class="empty row1"></section-row>
        <section-row class="row2">
          <section-cell class="full">
            <cell-inner>
              <h2>Show up in all the ways that fit you.</h2>
    
              <div class="subtitle">
                The q1 network is built to support your <strong>work joy</strong>. 
                That means giving you the autonomy to choose your own adventure and the flexibility
                to dial your involvement up and down as needed.
              </div>
            </cell-inner>
            <cell-image>
              <img src="/assets/images/work-joy-balloon.png"/>
            </cell-image>
          </section-cell>
        </section-row>
        
        <section-row class="row3">
          <section-cell class="third">
            <h3>Learn & Grow</h3>
  
            <p>
              Follow projects aligned with your passion & purpose.
              Learn with people in your field of work.
              Get inspired by innovators & though leaders.
            </p>

            <img src="/assets/images/q1-network-ui-guild-member.png"/>
          </section-cell>
          
          <section-cell class="third">
            <h3>Work & Earn</h3>
  
            <p>
              Share your gifts & ideas by contributing to projects.
              Earn money while building reputation & expanding your professional network.
            </p>

            <img src="/assets/images/q1-network-ui-project-member.png"/>
          </section-cell>
          
          <section-cell class="third">
            <h3>Build & Operate</h3>
  
            <p>
              Share and manage your project with ease.
              Cultivate your idea in a caring and professional environment.
              Get the specialized help you need.
            </p>

            <img src="/assets/images/q1-network-ui-project-organizer.png"/>
          </section-cell>
        </section-row>
      </section>

      <section id="structure">
        <section-row class="row1">
          <section-cell class="half image-content">
            <cell-icon>
              <sl-icon library="vectors" name="global-collaboration"></sl-icon>
            </cell-icon>
          </section-cell>

          <section-cell class="half text-content">
            <h2>Community ownership makes all the difference.</h2>
  
            <div class="subtitle">
              We run on <strong>the Quorum Model</strong>, a business operating system designed to
              mimic the creativity, balance & resilience of living ecosystems.<br/><br/>
              
              Our approach is guided by an ever growing community of experts and is informed by the 
              most cutting edge frameworks, technologies & tools. As we co-venture with other 
              organizations to evolve this model, we are all contributing to a future of work 
              that serves both humans and the planet.
            </div>

            <button-bar>
              <sl-button
                size="large"
                href="https://github.com/quorum1/governance"
                target="_blank"
                pill
                outline
              >
                <sl-icon library="tabler" name="brand-github" slot="prefix"></sl-icon></a>
                <span class="hide-until-bp3">View</span> Our Governance Repo
              </sl-button>
            </button-bar>
          </section-cell>
        </section-row>

        <section-row class="empty row2"></section-row>
      </section>

      <button-panel>
        <h2>Interested in the movement?</h2>

        <sl-button
          variant="warning"
          size="large" class="jumbo elevated"
          href="/join"
          pill
        >
          Join the Quorum
        </sl-button>
      </button-panel>

      <q1-globe></q1-globe>
    `;
  }
}

customElements.define('home-public-view', HomePublicView);
