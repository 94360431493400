import { css } from 'lit';

export const q1AppHeaderStyles = css`
  :host {
    display: block;
    position: fixed;
    top: 0; right: 0; left: 0;
    z-index: 100;

    background: var(--background);
    backdrop-filter: blur(8px);
    box-shadow: var(--box-shadow);
    border-bottom: var(--border-bottom);

    /* Variables */
    --background: rgba(247, 251, 255, 0.50);
    --text-color: var(--gray-6);
    --text-color-focus: var(--gray-9);
    --text-color-contrast: var(--gray-2);
    --box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    --border-bottom: none;
  }
  :host([variant='white-text']) {
    --background: rgba(0, 0, 0, 0.25);
    --text-color: white;
    --text-color-focus: var(--gray-3);
    --text-color-contrast: var(--gray-6);
  }
  :host([variant='flat']) {
    --box-shadow: none;
    --border-bottom: 1px solid var(--gray-2);
  }

  #header-base {
    display: flex;
    position: relative;
    height: 50px;
    
    padding: 0 1rem;
    box-sizing: border-box;
  }
  #slot-container {
    display: flex;
    padding: 0 1rem;
  }
  @media(min-width: 650px) {
    #header-base, #slot-container {
      display: block;
    }
  }

  /*=== LEFT NAV ===*/
  
  .left-nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    height: 100%;
  }
  @media(min-width: 650px) {
    .left-nav {
      position: absolute;
      top: 0; left: 1.5rem;
    }
  }

  .left-nav a {
    display: block;
    color: var(--text-color);
    transition: all 0.2s ease-in-out;
  }
  .left-nav a:visited {
    color: var(--text-color);
  }
  .left-nav a:hover,
  .left-nav a:focus {
    color: var(--text-color-focus);
  }
  .left-nav a sl-icon.logo {
    display: block;
    font-size: 25px;
  }

  /*=== CENTER NAV & SLOTTED CONTENT ===*/

  .center-nav, #slot-wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    box-sizing: content-box;    
  }
  .center-nav {
    height: 100%;
    margin: 0 1.5rem;
    padding: 0;
    flex: 1 1 auto;
  }
  #slot-wrapper {
    margin: 0;
    padding: 1.5rem 0;
    flex: 1 1 auto;
  }
  @media(min-width: 650px) {
    .center-nav, #slot-wrapper {
      gap: 1.5rem;
      justify-content: center;

      margin: 0 auto;
      max-width: var(--q1-view-width);
    }
    .center-nav {
      padding: 0 5rem;
    }
    #slot-wrapper {
      padding: 2rem 0;
    }
  }
  @media(min-width: 1000px) {
    .center-nav, #slot-wrapper {
      justify-content: flex-start;
    }
    #slot-wrapper {
      padding: 2.5rem 0;
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 1rem;
    line-height: 1em;
    text-decoration: none;
    
    color: var(--text-color);
    font-weight: 500;
    transition: all 0.2s ease-in-out;
  }
  .nav-link:visited {
    color: var(--text-color);
  }
  .nav-link:hover, .nav-link:focus {
    color: var(--text-color-focus);
  }
  .nav-link sl-icon {
    font-size: 1.3rem;
  }
  .nav-link span {
    display: none;
  }
  @media(min-width: 500px) {
    .nav-link span {
      display: block;
    }
  }
  @media(min-width: 650px) {
    .nav-link {
      gap: 0.4rem;
    }
  }

  /*=== RIGHT NAV ===*/

  .right-nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    height: 100%;
  }
  @media(min-width: 650px) {
    .right-nav {
      position: absolute;
      top: 0; right: 1.5rem;
    }
  }

  sl-menu-item.menu-header::part(label) {
    line-height: 1.2rem;
    min-width: 9rem;
  }
  .menu-title {
    font-weight: 700;
    font-size: 1.1rem;
    display: inline-block;
    margin-bottom: 0.2rem;
  }
  .menu-subtitle {
    font-size: 0.9rem;
    color: var(--gray-5);
  }
  sl-menu-item.menu-header:focus .menu-subtitle,
  sl-menu-item.menu-header:hover .menu-subtitle {
    color: white;
  }

  sl-avatar::part(icon) {
    color: var(--text-color-contrast);
  }
  sl-avatar::part(base) {
    background: var(--text-color);
    transition: all 0.2s ease-in-out;
  }
  sl-avatar:hover::part(base),
  sl-avatar:focus::part(base) {
    background: var(--text-color-focus);
    transition: all 0.2s ease-in-out;
  }

  sl-avatar#main-avatar {
    --size: 30px;
  }
  sl-avatar#menu-avatar {
    --size: 40px;
  }

`;
