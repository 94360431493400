import { css } from 'lit';

export const settingsViewStyles = css`

  h2 {
    margin-bottom: 1.5rem;
  }
  @media(min-width: 650px) {
    h2 {
      margin-bottom: 2.5rem;
    }
  }

  sl-tab::part(base) {
    font-size: 1.1rem;
  }

  profile-visibility-panel {
    display: block;
    padding: 1.5rem;
    background: var(--teal-0);
    border-radius: 12px;
    border: 1px solid var(--teal-6);
    margin: 1.5rem 0 2.5rem 0;
    box-shadow: var(--sl-shadow-x-small);
  }

  profile-visibility-panel h3 {
    margin: 0 0 1rem 0;
    color: var(--teal-7);
  }
  profile-visibility-panel p {
    margin: 1rem 0;
  }
  profile-visibility-panel a {
    color: var(--teal-9);
    display: inline-block;
    padding: 1rem 1.5rem;
    background: var(--teal-1);
    border-radius: 7px;
    border: 1px solid var(--teal-3);
    margin: 1rem 0;
    font-size: 1.1rem;
    transition: all 0.2s ease-in-out
  }
  profile-visibility-panel a:visited {
    color: var(--teal-9);
  }
  profile-visibility-panel a:focus,
  profile-visibility-panel a:hover {
    color: var(--gray-9);
    background: var(--teal-2);
    border-color: var(--teal-5);
  }
  profile-visibility-panel sl-switch {
    display: block;
    margin-top: 1rem;
    max-width: 35rem;
  }

  sl-details {
    margin: 1rem 0;
  }
  sl-details:first-of-type {
    margin-top: 2.5rem;
  }
  sl-details::part(header) {
    font-size: 1.1rem;
    font-weight: 600;
    background: var(--yellow-1);
    border-bottom: 1px solid var(--yellow-2);

  sl-dialog#change-password-dialog {
    position: relative;
  }

`;