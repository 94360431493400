import { LitElement, html, css } from 'lit';
import { storageBaseUrl } from '../firebaseConfig.js';

import { viewStyles } from '../styles/view-styles.js';

import { Q1AppHeader } from '../components/q1-app-header/q1-app-header.js';
import { Q1AppFooter } from '../components/q1-app-footer/q1-app-footer.js';


export class ViewBase extends LitElement {

  static styles = [
    viewStyles,
  ];

  static properties = {
    isSignedIn: { type: Boolean },
    currentUser: { type: Object },
    currentMember: { type: Object },
    isAdmin: { type: Boolean },

    cmImage_profile: { type: Object },
    cmImage_banner: { type: Object },

    routeParams: { type: Object },
    urlParams: { type: Object },
    appElement: { type: Object },
  };

  constructor() {
    super();

    this.currentUser = null;
    this.currentMember = null;
    this.isSignedIn = false;
    this.isAdmin = false;

    this.routeParams = {};
    this.urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    this.appElement = null;
  }

  connectedCallback() {
    super.connectedCallback();

    this.urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    this.appElement = document.querySelector('q1-home');
  }

  updated(changedProperties) {
    if (changedProperties.has('currentMember')) {
      this.cmImage_profile = {
        mini: this.docImageUrl(this.currentMember, 'profile', 'mini'),
        small: this.docImageUrl(this.currentMember, 'profile', 'small'),
        standard: this.docImageUrl(this.currentMember, 'profile', 'standard'),
      };
      this.cmImage_banner = {
        mini: this.docImageUrl(this.currentMember, 'banner', 'mini'),
        small: this.docImageUrl(this.currentMember, 'banner', 'small'),
        standard: this.docImageUrl(this.currentMember, 'banner', 'standard'),
      };
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    if (this.selectedMember) {
      this.selectedMember = { ...this.selectedMember, [name]: value };
    }
  }

  /**
   * Use this to navigate to a particular path (`/projects/123`, `/home`, etc) from code.
   * Do not use this for direct user-click-driven navigation. For that just use `<a href="...">`.
   * This is an alternative to `window.location.href = '...'` which uses the app router and 
   * avoids a full page reload.
  */
  navigateTo(path) {
    const appElement = document.querySelector('q1-home');

    if (appElement) {
      appElement.navigateTo(path);
    }
  }

  /**
   * Adds a toast alert to the toast stack using `<sl-alert>`
   * 
   * @param {*} message - The message to display.
   * @param {*} title - Optional. If included, this is added as the first line, in bold.
   * @param {string} variant - `primary`, `success`, `neutral`, `warning`, `danger`
   * @param {*} duration - Time to display in milliseconds.
   */
  showToast(message, title = undefined, variant = undefined, duration = undefined) {
    const appElement = document.querySelector('q1-home');

    if (appElement) {
      appElement.showToast(message, title, variant, duration);
    }
  }

  /**
   * Use this method to set the title of the doc in the browser.
   * 
   * @param {string} title - The new value of the document title
   */
  setDocumentTitle(title = 'q1 Network') {
    document.title = title;
  }

  /** Returns the image URL from a doc, by providing the image field key. */
  docImageUrl(doc, imageField, sizeKey) {
    const imageFieldValue = doc?.[imageField];
    return this.imageUrl(imageFieldValue, sizeKey);
  }

  /** Returns the image URL from a image object. */
  imageUrl(imageFieldValue, sizeKey) {
    const imagePath_raw = imageFieldValue?.[sizeKey]?.path;
    if (!imagePath_raw) return null;

    const imagePath_clean = imagePath_raw.startsWith('/')
      ? imagePath_raw.substring(1)
      : imagePath_raw;

    const imagePath_encoded = encodeURIComponent(imagePath_clean);
    
    return `${storageBaseUrl}/${imagePath_encoded}?alt=media`;
  }

  get headerTemplate() {
    return html`
      <q1-app-header
        .currentUser="${this.currentUser}"
        .currentMember="${this.currentMember}"
        .isSignedIn="${this.isSignedIn}"
        .isAdmin="${this.isAdmin}"
      ></q1-app-header>
    `;
  }

  get footerTemplate() {
    return html`
      <q1-app-footer></q1-app-footer>
    `;
  }

  get contentTemplate() {
    return html``;
  }

  render() {
    return html`

      <view-header>
        ${this.headerTemplate}
      </view-header>

      <view-content>
        ${this.contentTemplate}
      </view-content>
      
      <view-footer>
        ${this.footerTemplate}
      </view-footer>

    `;
  }  
  
}