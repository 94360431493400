import { html, css, LitElement } from 'lit';
import { storage, storageRef, uploadBytes } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';

import { Q1ImageUploadInputStyles } from './q1-image-upload-input-styles.js';
import { FormInputMixin } from '../../modules/form-input-mixin.js';

const MAX_IMAGE_SIZE = 7; // in MB

export class Q1ImageUploadInput extends FormInputMixin(LitElement) {
  static styles = [
    Q1ImageUploadInputStyles,
  ];

  static properties = {
    ...super.properties, // adds: label, name, value, required, valid, helpText, _internals

    input: { type: Object },
    button: { type: Object },

    uploadFolder: { type: String, attribute: 'upload-folder' },
    currentImageThumbnail: { type: String, attribute: 'current-image-thumbnail' },

    _error: { type: String, state: true },
    _invalidityFlag: { type: String, state: true },
    _loading: { type: Boolean, state: true },
  };

  constructor() {
    super();
    
    this.input = null;
    this.button = null;

    this.uploadFolder = '';
    this.currentImageThumbnail = '';
    this._error = '';
    this._invalidityFlag = '';
    this._loading = false;
    this.valid = true;
  }

  connectedCallback() {
    super.connectedCallback();

    this.updateFormValue();
  }

  firstUpdated() {
    this.updateComplete.then(() => {
      this.input = this.renderRoot.querySelector('#input');
      this.button = this.renderRoot.querySelector('#button');

      this.updateValidity(); // can't run until this.input exists
    });
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  updateValidity() {
    if (this.required && !this.currentImageThumbnail?.length && !this.value?.length) {
      this._internals.setValidity(
        { valueMissing: true },
        'An image is required',
        this.button,
      );
    } else if (!this.valid) {
      this._internals.setValidity(
        { [this._invalidityFlag]: true },
        this._error,
        this.button,
      );
    } else {
      this._internals.setValidity({}, '', this.button);
    }
  }
  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  updateFormValue() {
    this._internals.setFormValue(this.value);
  }
  
  handleChange() {

    const file = this.input.files[0];

    if (!file) return;

    if (file.size > MAX_IMAGE_SIZE * 1024 * 1024) {
      this._error = `Max supported image size is ${MAX_IMAGE_SIZE} MB.`;
      this.valid = false;
      this._invalidityFlag = 'tooLarge';
      this.updateValidity();
      return;
    }

    this._error = '';
    this._invalidityFlag = '';
    this._loading = true;

    const timestamp = Date.now();
    const randomSuffix = Math.random().toString(36).substring(2, 8);
    const extension = file.name.split('.').pop().toLowerCase();
    const filePath = `uploads/${this.uploadFolder}/${timestamp}-${randomSuffix}.${extension}`;

    const uploadRef = storageRef(storage, filePath);

    uploadBytes(uploadRef, file).then(() => {
      this.value = filePath;
      this.updateFormValue();
      this.currentImageThumbnail = URL.createObjectURL(file);
      this.valid = true;
      this.updateValidity();
      this._loading = false;
      
      super.handleChange(); // dispatches the event
    }).catch((error) => {
      this.value = '';
      this.updateFormValue();
      this._error = 'Failed to upload the image. Please try again.';
      console.error('Upload error:', error);
      this.valid = false;
      this._invalidityFlag = 'customError';
      this.updateValidity();
      this._loading = false;
      
      super.handleChange(); // dispatches the event
    });
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  formDisabledCallback(disabled) {
    this.input.disabled = disabled;
  }

  // FIXME >> Should be moved partially to the mixin somehow. Wait for multiple examples.
  formResetCallback() {
    this.value = '';
  }

  // FIXME >> Build out a basic error validation mode.. user-facing error message
  render() {
    return html`
      ${!this.label?.length ? null : html`
        <label for="input">${this.label}</label>
      `}
      <input-body>
        ${this.currentImageThumbnail ? html`
          <img
            class="preview" 
            part="preview" 
            src=${this.currentImageThumbnail}
          />
        ` : html`
          <div
            class="placeholder preview"
            @click="${() => this.input.click()}"
          >Select an image</div>
        `}
        
        <sl-button
          id="button"
          variant="neutral" pill outline
          @click="${() => this.input.click()}"
          ?disabled="${this._loading}"
        >
          <sl-icon library="material" name="upload" slot="prefix"></sl-icon>
          Upload
        </sl-button>

        <input
          id="input"
          type="file"
          accept="image/*"
          style="display: none;"
          @change="${this.handleChange}"
        />
      </input-body>

      ${!this._error?.length ? null : html`
        <div id="error-message">${this._error}</div>
      `}
      
      ${!this.helpText?.length ? null : html`
        <div id="help-text">${this.helpText}</div>
      `}
    `;
  }
}

customElements.define('q1-image-upload-input', Q1ImageUploadInput);
