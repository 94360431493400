import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { adminIndexViewStyles } from './admin-index-view-styles.js';

export class AdminIndexView extends ViewBase {
  static styles = [
    ...super.styles,
    adminIndexViewStyles
  ];

  static properties = {
    ...super.properties,
  };
  
  connectedCallback() {
    super.connectedCallback();
    this.setDocumentTitle('Q1 ADMIN PANEL');
  }

  get contentTemplate() {
    return html`
      <h1>Admin Panel</h1>

      <p>
        Welcome to the Admin Panel. Use the links below to navigate to different sections of the admin interface.
      </p>

      <nav>
        <ul>
          <li><a href="/a/guilds">Guilds</a></li>
          <li><a href="/a/projects">Projects</a></li>
          <li><a href="/a/places">Places</a></li>
          <li><a href="/a/updates">Updates</a></li>
          <li><a href="/a/roles">Roles</a></li>
          <li><a href="/a/deliverables">Deliverables</a></li>
          <li><a href="/a/members">Members</a></li>
        </ul>
      </nav>
    `;
  }
}

customElements.define('admin-index-view', AdminIndexView);
