import { css } from 'lit';

export const signInViewStyles = css`

  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 70%, var(--yellow-4) 100%);

    --q1-view-width: var(--q1-content-width-narrow);
  }

  sign-in-panel {
    display: block;
    max-width: 500px;
    border-radius: 20px;
    padding: 3rem;
    margin: 0 auto;
    background: white;
    border: 1px solid var(--gray-3);
  }

  sl-icon.logo {
    font-size: 4rem;
  }

  h1 {
    font-size: 2rem;
    line-height: 1.3em;
    margin: 1rem 0;
  }
  .subtitle {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1.3em;
    color: var(--gray-6);
  }

  form {
    margin-top: 2.5rem;
  }
  @media(min-width: 1000px) {
    form {
      margin-top: 3.5rem;
    }
  }

  sl-input {
    width: 100%;
    margin-bottom: 1rem;
  }

  .button-group {
    display: flex;
    gap: 1.5rem;
    margin-top: 2.5rem;
  }
  .button-group sl-button {
    width: auto;
    flex: 1 1 auto;
  }
  @media(min-width: 1000px) {
    .button-group {
      margin-top: 3.5rem;
    }
  }

  .alternate-options {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .alternate-options sl-button::part(base) {
    color: var(--gray-6);
    font-size: 0.9rem;
  }

  .alternate-options sl-button:hover::part(base) {
    color: var(--gray-8);
  }
`;