import { html, css, LitElement } from 'lit';

import { q1TextMorphStyles } from './q1-text-morph-styles.js';

/**
 * Adapted from codepen here: https://codepen.io/Valgo/pen/PowZaNY
 * 
 * IMPROVEME: Note that the code is a little complex and could use simplification
 * and refactoring.
 */
export class Q1TextMorph extends LitElement {
  static styles = [ q1TextMorphStyles ];

  static properties = {
    morphTime: { type: Number, attribute: 'morph-time' },
    cooldownTime: { type: Number, attribute: 'cooldown-time' },
    
    _textItems: { type: Array },
    _text1Index: { type: Number },
    _text2Index: { type: Number },
    _time: { type: Object },
    _morph: { type: Number },
    _cooldown: { type: Number },
  };

  constructor() {
    super();

    this.morphTime = 1;
    this.cooldownTime = 2.5;

    this._textItems = [];
    this._text1Index = null;
    this._text2Index = null;
  }

  firstUpdated() {
    this.text1 = this.renderRoot.getElementById('text1');
    this.text2 = this.renderRoot.getElementById('text2');

    // Set the text items by parsing the slot content and turning each line into an item
    const slot = this.renderRoot.querySelector('slot');
    const assignedNodes = slot.assignedNodes({ flatten: true });
    
    this._textItems = assignedNodes
      .map(node => node.textContent)
      .join('\n')
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length); 
    
    this.startAnimation();
  }

  startAnimation() {
    this._text1Index = this._textItems.length - 1;
    this._text2Index = 0;

    this._time = new Date();
    this._morph = 0;
    this._cooldown = this.cooldownTime;
  
    this.text1.textContent = this._textItems[this._text1Index];
    this.text2.textContent = this._textItems[this._text2Index];

    this.animate();
  }

  // Animation loop, which is called every frame.
  animate() {
    requestAnimationFrame(() => this.animate());
    
    const newTime = new Date();
    const incrementIndexIfCooldownOver = this._cooldown > 0;
    const delta = (newTime - this._time) / 1000;
    
    this._time = newTime;
    this._cooldown -= delta;
    
    if (this._cooldown <= 0) {
      if (incrementIndexIfCooldownOver) {
        const lastIndex = this._textItems.length - 1;
        this._text1Index = (this._text1Index == lastIndex) ? 0 : this._text1Index + 1;
        this._text2Index = (this._text2Index == lastIndex) ? 0 : this._text2Index + 1;
      }
      this.doMorph();
    } else {
      this.doCooldown();
    }
  }
  
  doMorph() {
    this._morph -= this._cooldown;
    this._cooldown = 0;
    
    let fraction = this._morph / this.morphTime;
    
    if (fraction > 1) {
      this._cooldown = this.cooldownTime;
      fraction = 1;
    }
    
    this.setMorph(fraction);
  }
  
  // A lot of the magic happens here, this is what applies the blur filter to the text.
  setMorph(fraction) {
    // fraction = Math.cos(fraction * Math.PI) / -2 + .5;
    
    this.text2.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    this.text2.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
    
    fraction = 1 - fraction;
    this.text1.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    this.text1.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;
    
    this.text1.textContent = this._textItems[this._text1Index];
    this.text2.textContent = this._textItems[this._text2Index];
  }
  
  doCooldown() {
    this._morph = 0;
    
    this.text2.style.filter = '';
    this.text2.style.opacity = '100%';
    
    this.text1.style.filter = '';
    this.text1.style.opacity = '0%';
  }


  render() {
    return html`
      <text-container>
        <span id="text1"></span>
        <span id="text2"></span>
      </text-container>

      <slot></slot>

      <svg id="filters" style="display: none;">
        <defs>
          <filter id="threshold">
            <!--
              Basically just a threshold effect - pixels with a high enough opacity are 
              set to full opacity, and all other pixels are set to completely transparent.
            -->
            <feColorMatrix in="SourceGraphic"
                type="matrix"
                values="1 0 0 0 0
                        0 1 0 0 0
                        0 0 1 0 0
                        0 0 0 255 -140" />
          </filter>
        </defs>
      </svg>
    `;
  }
}

customElements.define('q1-text-morph', Q1TextMorph);