import { css } from 'lit';

export const memberIndexViewStyles = css`

  :host {
    background: var(--q1-home-background, var(--gray-0));

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    --top-bar-height: 155px;
    --left-bar-width: 21rem;
  }
  @media(min-width: 1000px) {
    :host {
      --top-bar-height: 100px;
      --left-bar-width: 23rem;
    }
  }

  /*=== LAYOUT ELEMENTS ===*/

  q1-app-header {
    --background: var(--gray-0);
    --border-bottom: none;
  }

  top-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    
    
    position: fixed;
    box-sizing: border-box;
    top: 50px;
    right: 0;
    left: 0;

    padding: 0.5rem 1.5rem 0 1.5rem;
  }
  @media(min-width: 1000px) {
    top-bar {
      flex-direction: row;
      align-items: center;
      height: 50px;
      padding-top: 0;
    }
    #toggle-query-settings {
      display: none;
    }
  }

  left-bar {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 1rem 1.5rem 1.5rem;
    margin-top: 1rem;
    border-top: 1px solid var(--gray-2);

    position: fixed;
    box-sizing: border-box;
    top: var(--top-bar-height);
    bottom: 0;
    left: 0;
    width: var(--left-bar-width);

    overflow-y: scroll;
  }

  content-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    position: fixed;
    box-sizing: border-box;
    top: var(--top-bar-height);
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 0 1rem 1rem;
    background: var(--gray-0);

    transition: all 0.3s ease-in-out;
  }
  content-container.showSettings {
    left: var(--left-bar-width);
    right: -100vw;
  }
  @media(min-width: 1000px) {
    content-container {
      left: var(--left-bar-width);
      right: 0;
      padding: 0 1rem 1rem 0;
    }
  }

  table-wrapper {
    display: block;
    position: relative;
    width: 100%;
    flex: 1 1 auto;
    overflow: scroll;

    border-radius: 7px;
    box-shadow: var(--sl-shadow-x-small);
    background: white;
  }

  table-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    flex: 0 0 auto;
  }

  /*=== STANDARD ELEMENTS ===*/

  h1 {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.5em;
    font-weight: 600;
  }
  @media(min-width: 650px) {
    h1 {
      font-size: 1.3rem;
    }
  }
  
  h2 {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5em;
    font-weight: 500;
  }
  
  h3 {
    margin: 0;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 500;
  }

  /*=== TOP BAR ===*/

  headline {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  headline .suffix {
    display: none;
    color: var(--gray-6);
    font-size: 1rem;
    line-height: 1.3em;
  }
  @media(min-width: 500px) {
    headline .suffix {
      display: inline-block;
    }
  }

  /*=== FILTER BAR ===*/

  query-pair {
    display: flex;
    gap: 0.5rem;
  }
  query-pair sl-select {
    flex: 1 1 auto;
  }

  icon-button-list {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
    icon-button-list sl-icon-button {
      font-size: 1.3rem;
      color: var(--gray-7);
    }

  left-bar sl-divider {
    margin-left: -1.5rem;
    margin-right: -1rem;
  }

  sl-select#filterByLevels::part(display-input) {
    --sl-input-placeholder-color: var(--gray-9);
  }

  tree-summary {
    display: block;
    color: var(--gray-5);
    transition: color 0.2s ease-in;
  }
  tree-summary.warning {
    color: var(--yellow-6);
  }
  tree-summary.error {
    color: var(--red-8);
    font-weight: 500;
  }

  sl-tree-item.ignored[selected]::part(label) {
    font-style: italic;
    color: var(--red-8);
  }

  /*=== MEMBERS TABLE FOOTER ===*/

  sl-select#pageSizeString .inner-label {
    display: none;
  }
  sl-select#pageSizeString {
    width: 7rem;
  }
  @media(min-width: 500px) {
    sl-select#pageSizeString .inner-label {
      display: block;
    }
    sl-select#pageSizeString {
      width: 11rem;
    }
  }
  @media(min-width: 650px) {
    sl-select#pageSizeString {
      width: 12rem;
    }
  }

  page-settings {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 0 0 auto;
  }
  #current-page-details {
    display: none;
    padding: 0 0.5rem;
    color: var(--gray-5);
  }
  @media(min-width: 650px) {
    #current-page-details {
      display: block;
    }
  }

  nav-buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 0 0 auto;
    padding-right: 1rem;
  }
  @media(min-width: 1000px) {
    nav-buttons {
      padding-right: 0;
    }
  }

  /*=== TABLE EMPTY PANELS ===*/

  .empty-panel {
    display: flex;
    margin: 4rem 1rem 0 1rem;
    align-items: center;
    justify-content: center;
  }
    .empty-panel .inner {
      padding: 1.5rem;
      width: 20rem;
      text-align: center;
      border-radius: 12px;
      border: 1px solid var(--yellow-3);
      background: var(--yellow-0);
      box-shadow: var(--sl-shadow-medium);
      color: var(--gray-7);
    }
    .empty-panel.hero .inner {
      width: 22rem;
    }
      .empty-panel .inner sl-icon {
        font-size: 5rem;
        color: var(--yellow-3);
      }
      .empty-panel.hero .inner sl-icon {
        color: var(--teal-6);
        font-size: 7rem;
      }
      .empty-panel .inner h2 {
        margin: 1rem 0;
        font-size: 1.5rem;
      }
      .empty-panel .inner sl-button {
        margin: 1rem 0;
      }

  /*=== MEMBERS TABLE ===*/

  table#members-table {
    margin: 0;
    
    --table-th-color: var(--gray-5);
    --table-th-bg-color: white;
    --table-th-font-size: var(--sl-font-size-small);
    --table-th-font-weight: var(--sl-font-weight-light);
    
    --table-td-bg-color: white;
    --table-td-font-size: var(--sl-font-size-medium);
  }

  table#members-table tr {
    position: relative;
    --table-td-bg-focus-color: var(--profile-color-primary-10);
  }
  table#members-table tr::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 1px;
    left: 0;
    width: 5px;
    background: var(--profile-color-primary);
    opacity: 0.7;

    transition: all 0.2s ease-in;
  }
  table#members-table tr:focus::after,
  table#members-table tr:hover::after {
    width: 8px;
    opacity: 1;
  }
  table#members-table tr:last-child::after {
    border-bottom-left-radius: var(--table-border-radius);
  }

  td.member-col {
    max-width: none !important;
    width: 10rem;
  }
  td.member-col td-content {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  td.member-col sl-avatar {
    --size: 1.5rem;
  }
  td.member-col sl-avatar::part(base) {
    border: 1px solid var(--yellow-0);
  }
  td.member-col a.name-link  {
    --link-color-default: var(--gray-8);
    --link-color-visited: var(--gray-7);
    --link-color-focus: var(--gray-9);
    --link-text-decoration-default: none;
    font-weight: 500;
  }
  td.member-col a.number-link  {
    --link-color-default: var(--gray-6);
    --link-color-visited: var(--gray-5);
    --link-color-focus: var(--gray-9);
    --link-text-decoration-default: none;
  }
  td.member-col .divider {
    color: var(--gray-5);
  }
  td.member-col .action-links {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.3rem;
  }
  td.member-col .action-links sl-icon-button::part(base) {
    color: var(--blue-7);
  }

  td.level-col {
    width: 6rem;
  }
  td.level-col span {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  td.locations-col place-list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  td.locations-col place-item {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--gray-6);
  }
  td.locations-col place-item.primary {
    font-weight: 500;
    color: var(--gray-9);
  }

  td.date-col {
    width: 6rem;
  }

`;
