import { initializeApp } from 'firebase/app';
import {
  getAuth, signOut, signInWithCustomToken, signInWithEmailAndPassword, connectAuthEmulator,
} from 'firebase/auth';
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import {
  getFirestore, connectFirestoreEmulator,
  doc, updateDoc, getDoc, getDocs, deleteDoc, 
  collection,
  onSnapshot,
  query, limit, limitToLast, orderBy,
  startAfter, startAt, endBefore, endAt,
} from 'firebase/firestore';
import {
  connectStorageEmulator, getStorage, ref as storageRef, uploadBytes, getDownloadURL
} from 'firebase/storage';


const firebaseConfig = {
  apiKey: 'AIzaSyCv0wKBZs4GUy4DxU9eTI05qelTrGiH_nI',
  authDomain: 'q1-home-prod-74dd4.firebaseapp.com',
  projectId: 'q1-home-prod-74dd4',
  storageBucket: 'q1-home-prod-74dd4.appspot.com',
  messagingSenderId: '116054675714',
  appId: '1:116054675714:web:0a017f332ca6f13970b21b',
  measurementId: 'G-764QYJQ95H',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

const isLocalhost = (location.hostname === 'localhost') || (location.hostname === '127.0.0.1');

if (isLocalhost) {
  console.log('firebaseConfig: Dev environment detected.');
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
}

const pid = firebaseConfig.projectId;

const storageBaseUrl = isLocalhost
  ? `http://${location.hostname}:9199/v0/b/${pid}.appspot.com/o`
  : `https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o`;

const dbUrlTemplate = isLocalhost
  ? (collection, docId) => `http://127.0.0.1:4000/firestore/default/data/${collection}/${docId}`
  : (collection, docId) => `https://console.firebase.google.com/project/${pid}/firestore` 
    + `/databases/-default-/data/~2F${collection}~${docId}`;

// Initialize Firebase Analytics (only if supported, as it's browser-specific)
let analytics;
isSupported().then((supported) => {
  if (supported) {
    analytics = getAnalytics(app);
  }
});

export {
  app, isLocalhost, dbUrlTemplate,
  
  auth, signOut, signInWithCustomToken, signInWithEmailAndPassword,

  analytics, logEvent,
  
  db, doc, getDoc, getDocs, updateDoc, deleteDoc, collection,
  query, limit, limitToLast, orderBy, onSnapshot,
  startAfter, startAt, endBefore, endAt,
  
  functions, httpsCallable,
  
  storage, getStorage, storageRef, uploadBytes, getDownloadURL, storageBaseUrl,
};
