import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { joinViewStyles } from './join-view-styles.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';

export class JoinView extends ViewBase {
  static styles = [
    ...super.styles,
    joinViewStyles,
  ];

  static properties = {
    ...super.properties,
  };

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle(
      'Join the q1 Network - Hosting the Future of Work - Entrepreneurship, Impact, Emerging Tech'
    );
  }

  get headerTemplate() {
    return html`
      <q1-app-header
        variant="white-text"
        .currentUser="${this.currentUser}"
        .currentMember="${this.currentMember}"
        .isSignedIn="${this.isSignedIn}"
        .isAdmin="${this.isAdmin}"
      ></q1-app-header>
    `;
  }
  
  get footerTemplate() {
    return html`
      <q1-app-footer variant="white-text"></q1-app-footer>
    `;
  }

  get contentTemplate() {
    return html`
      
      <section id="hero">
        <section-content>
          <h1>Join the q1 Network</h1>

          <div class="subtitle">
            The q1 Network is part of Quorum1, a member-owned collective pioneering
            the future of work.<br/><br/>
            
            The network is a high-trust community of work. A safe space for all forms of
            professional collaboration. Membership is free.
          </div>

          <button-bar>
            <sl-button
              variant="warning"
              size="large"
              href="/register"
              pill
            >
              Register Now
            </sl-button>
          </button-bar>
        </section-content>
      </section>

      <section id="explainer">

        <section-content>

          <h2>Who is the q1 Network for?</h2>

          <div class="subtitle">
            Are you looking for a community that blends purpose, collaboration, and impact?
          </div>

          <ul>
            <li>
              <icon-frame>
                <sl-icon library="material" name="rocket_launch"></sl-icon>
              </icon-frame>
              <span>
                <strong>You are a changemaker.</strong><br/>
                You believe in working together to deliver exceptional value, 
                live meaningful lives, and create a positive impact on the world.
              </span>
            </li>
            <li>
              <icon-frame>
                <sl-icon library="material" name="plant"></sl-icon>
              </icon-frame>
              <span>
                <strong>You seek growth.</strong><br/>
                A space to explore the skills and topics that spark your curiosity, while growing 
                professionally and personally.
              </span>
            </li>
            <li>
              <icon-frame>
                <sl-icon library="material" name="volunteer_activism"></sl-icon>
              </icon-frame>
              <span>
                <strong>You want to contribute.</strong><br/>
                Sharing your talents and expertise to support meaningful projects, building 
                reciprocal, valued relationships.
              </span>
            </li>
            <li>
              <icon-frame>
                <sl-icon library="material" name="lightbulb"></sl-icon>
              </icon-frame>
              <span>
                <strong>You are ready to innovate.</strong><br/>
                Testing your ideas and nurturing them into thriving, impactful ventures.
              </li>
              </span>
            <li>
              <icon-frame>
                <sl-icon library="material" name="accessibility_new"></sl-icon>
              </icon-frame>
              <span>
                <strong>You value autonomy.</strong><br/>
                A place where you can fully show up, maintain your independence, and engage 
                at your own pace, stepping in and out as it suits you.
              </span>
            </li>
          </ul>

          <div class="subtitle">
            If this resonates with you, the q1 Network welcomes you. Together, we're re-imagining
            the future of work, collaboration, and impactful ventures.
          </div>

        </section-content>
      </section>

      <section id="process">
        <section-content>
          <h2>How Does It Work?</h2>

          <content-inner>
            <h3><span>1</span> Create Your Profile</h3>
            
            <p>
              Bring all of your talents, interests, and expertise together in one place. 
              Your profile is your gateway to engaging with the q1 Network.
            </p>
            
            <h3><span>2</span> Complete Onboarding</h3>
  
            <p>
              Dive into a short onboarding journey with helpful videos and documents. 
              These resources explain how you can engage with the network effectively and make 
              the most of your membership.
            </p>
    
            <h3><span>3</span> Join Guilds</h3>
  
            <p>
              Join guilds focused on professional practice areas, industries, and bioregions which 
              align with your goals and interests. Guilds provide community, connection, and curated
              lists of interesting projects.
            </p>
    
            <h3><span>4</span> Contribute to Projects</h3>

            <p>
              As you discover projects that interest you, you can follow them to receive play
              by play updates on the details of their work. When you're ready, you can join 
              one or more projects as a supporter or contributor.
            </p>
          </content-inner>

          <cta-wrapper>
            <cta-arrow>
              <cta-arrow-head></cta-arrow-head>
            </cta-arrow>
            
            <cta-panel>
              <h3>The q1 Network is your space to connect, grow, and make a meaningful impact.</h3>
              
              <p>Are you ready to join the movement?</p>
  
              <sl-button
                variant="warning"
                size="large"
                href="/register"
                pill
              >
                Register Now
              </sl-button>
            </cta-panel>
          </cta-wrapper>
        </section-content>
      </section>
      
    `;
  }
}

customElements.define('join-view', JoinView);
