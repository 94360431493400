/**
 * Formats Firebase auth error messages into readable text
 * @param {Error} error - The Firebase auth error object
 * @returns {string} Cleaned and formatted error message
 */
export const formatFirebaseError = (error) => {
  if (!error?.code) {
    return 'An unexpected error occurred';
  }

  const errorCode = error.code.match(/auth\/(.+?)\)?$/)?.[1] || error.code;

  return errorCode
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
