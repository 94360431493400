import { css } from 'lit';

export const claimViewStyles = css`

  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 70%, var(--teal-2) 100%);

    --q1-view-width: var(--q1-content-width-narrow);
  }

  h1 {
    line-height: 1em;
  }

  .subtitle {
    font-size: 1.1rem;
    line-height: 1.3em;
    margin: 1.5rem 0;
  }
  @media(min-width: 650px) {
    .subtitle {
      margin: 2.5rem 0;
    }
  }
  @media(min-width: 1000px) {
    .subtitle {
      margin: 3rem 0;
    }
  }

  info-panel {
    display: block;
    background: white;
    border-radius: 12px;
    padding: 2.5rem;
    border: 1px solid var(--gray-3);
    margin: 2.5rem 0;
    box-shadow: var(--sl-shadow-small);
  }

  info-panel p {
    display: block;
    margin: 0 0 1.5rem 0;
  }

  info-panel ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 2.5rem 0 0 0;
  }
  info-panel ul li {
    display: block;
    padding: 0;
    margin: 0.5rem 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

`;
