import { css } from 'lit';

export const homeConfirmViewStyles = css`
  
  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 70%, var(--yellow-2) 100%);
    --q1-view-width: var(--q1-content-width-narrow);
  }

  q1-verification-info {
    margin: 2.5rem 0;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0;
    margin: 1.5rem 0;
  }
  ul li {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    flex: 1 0 80%;
  }
  ul li icon-frame {
    flex: 0 0 auto;
  }
  ul li span {
    display: block;
    flex: 1 1 auto;
    font-size: 1rem;
    line-height: 1.3em;
    font-weight: 100;
  }
  ul li span strong {
    font-size: 1.1rem;
    line-height: 1.5em;
    font-weight: 600;
  }
  @media(min-width: 650px) {
    ul {
      margin: 2.5rem 0;
    }
  }
  @media(min-width: 1000px) {
    ul {
      margin: 3.5rem 0;
    }
  }

  icon-frame {
    display: flex;
    position: relative;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;

    border: 1px solid var(--teal-5);
    border-radius: 100%;
  }
  icon-frame::before {
    content: '';
    position: absolute;
    background: var(--teal-3);
    border-radius: 100%;
    opacity: 0.3;
    
    width: 4rem; height: 4rem;
    top: 0; left: 0;
    z-index: 2;
  }
  icon-frame sl-icon {
    font-size: 2rem;
    display: block;
    color: var(--teal-5);
    z-index: 3;
  }

`;
