import { css } from 'lit';

export const memberProfileViewStyles = css`
  :host {
    background: linear-gradient(
      180deg,
      var(--profile-color-primary-25) 0%,
      var(--gray-1) 130px,
      var(--gray-1) 100%);
  }

  view-content {
    padding-top: 50px;
  }
  @media(min-width: 1000px) {
    view-content {
      padding-top: 5rem;
    }
  }

  profile-header {
    display: block;
  }
  
  profile-banner {
    display: block;
    overflow: hidden;
    margin: 0 -2rem;
  }
  profile-banner.empty {
    height: 12rem;
    background:
      linear-gradient(150deg, var(--profile-color-primary), var(--profile-color-primary-25));
  }
  profile-banner img {
    display: block;
    width: 100%;
  }
  @media(min-width: 1000px) {
    profile-banner {
      border-radius: 16px;
    }
  }
  
  profile-bar {
    --profile-image-width: 6rem;
    --profile-border-width: 5px;
    
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    margin-top: calc(-0.3 * var(--profile-image-width) - var(--profile-border-width));
  }
  sl-avatar#profileImage {
    --size: var(--profile-image-width);
    margin-left: calc(-1 * var(--profile-border-width));
  }
  sl-avatar#profileImage::part(base) {
    border: var(--profile-border-width) solid var(--gray-1);
    background: var(--gray-6);
  }
  button-bar {
    display: flex;
    gap: 0.5rem;
    margin-bottom: var(--profile-border-width);
  }
  @media(min-width: 650px) {
    profile-bar {
      --profile-image-width: 7rem;
      --profile-border-width: 6px;

      margin-top: calc(-0.35 * var(--profile-image-width) - var(--profile-border-width));
    }
  }
  @media(min-width: 1000px) {
    profile-bar {
      --profile-image-width: 8rem;
      --profile-border-width: 7px;
    }
  }

  profile-header h1 {
    margin: 0.75rem 0 1rem 0;
  }

  #memberNumber {
    margin: 0.75rem 0;
    font-size: 1.1rem;
  }
  #memberNumber span {
    color: var(--gray-6);
  }

  #subtitle {
    margin: 0.75rem 0;
  }

  ul#locations {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0;
    display: flex;

    flex-wrap: wrap;
    gap: 0.5rem;
  }
  #locations li {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    --link-color-focus: var(--gray-9);
    --link-text-decoration-default: none;
  }
  #locations li.primary {
    font-weight: 600;
    
    --link-color-default: var(--gray-8);
    --link-color-visited: var(--gray-8);
  }
  #locations li.other {
    font-weight: 300;

    --link-color-default: var(--gray-6);
    --link-color-visited: var(--gray-6);
  }
  #locations li a {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  #locations li sl-icon {
    font-size: 1.5rem;
  }
  #locations li.other sl-icon {
    font-size: 1.2rem;
  }

  p.subtitle.empty-list {
    line-height: 2rem;
  }

  sl-button[variant='primary']::part(base) {
    background: var(--profile-color-primary);
    color: white;
    border: none;
    align-items: center;
  }

  
`;