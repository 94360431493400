import { css } from 'lit';

export const homeSignedInViewStyles = css`
  
  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 85%, var(--teal-2) 100%);
    position: relative;
  }

  :host:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    z-index: 0;

    background-image: url(/assets/textures/grain-054.png);
    background-size: cover;
    opacity: 0.1;
  }

  view-content {
    z-index: 2;
    position: relative;
  }

`;
