import { css } from 'lit';

export const q1Vars = css`
  :root, :host {
    /*=== SIZING ===*/

    --q1-content-width-narrow: 800px;
    --q1-content-width-normal: 950px;
    --q1-content-width-wide: 1200px;

    /* Just for documentation, they can't actually be used in media queries */
    --q1-responsive-breakpoint-0: 400px;
    --q1-responsive-breakpoint-1: 500px;
    --q1-responsive-breakpoint-2: 650px;
    --q1-responsive-breakpoint-3: 1000px;
    --q1-responsive-breakpoint-4: 1500px;

    /*=== COLORS ===*/

    /* GRAY */
    --gray-0: #F8F9FA;
    --gray-1: #F1F3F5;
    --gray-2: #E9ECEF;
    --gray-3: #DEE2E6;
    --gray-4: #CED4DA;
    --gray-5: #ADB5BD;
    --gray-6: #868E96;
    --gray-7: #495057;
    --gray-8: #343A40;
    --gray-9: #212529;
    
    /* YELLOW */
    --yellow-0: #FFFDF6;
    --yellow-1: #FFF7DD;
    --yellow-2: #FFF0C0;
    --yellow-3: #FFE697;
    --yellow-4: #FFD75D;
    --yellow-5: #F9C62A;
    --yellow-6: #E4B21A;
    --yellow-7: #B7901C;
    --yellow-8: #745A0A;
    --yellow-9: #4A3906;
    
    /* BLUE */
    --blue-0: #F7FBFF;
    --blue-1: #DAECFF;
    --blue-2: #B0D8FF;
    --blue-3: #91C8FF;
    --blue-4: #6FB7FF;
    --blue-5: #2691FB;
    --blue-6: #0E74DA;
    --blue-7: #226EBA;
    --blue-8: #284D73;
    --blue-9: #203952;
    
    /* TEAL */
    --teal-0: #F2F9F8;
    --teal-1: #DBF8F4;
    --teal-2: #A8F7EE;
    --teal-3: #89F8EA;
    --teal-4: #61F8E6;
    --teal-5: #30EDD7;
    --teal-6: #1DC9B4;
    --teal-7: #1B9F8F;
    --teal-8: #207B70;
    --teal-9: #154943;
    
    /* GREEN */
    --green-0: #F6F8F0;
    --green-1: #ECF4D4;
    --green-2: #DDECB3;
    --green-3: #D5E6A2;
    --green-4: #C9DC90;
    --green-5: #B5CF66;
    --green-6: #90AF35;
    --green-7: #759519;
    --green-8: #546C12;
    --green-9: #2D3909;

    /* ORANGE (IMPROVEME - Build out custom) */

    --orange-0: hsl(48 100% 96.1%);
    --orange-1: hsl(48 96.5% 88.8%);
    --orange-2: hsl(48 96.6% 76.7%);
    --orange-3: hsl(45.9 96.7% 64.5%);
    --orange-4: hsl(43.3 96.4% 56.3%);
    --orange-5: hsl(37.7 92.1% 50.2%);
    --orange-6: hsl(32.1 94.6% 43.7%);
    --orange-7: hsl(26 90.5% 37.1%);
    --orange-8: hsl(22.7 82.5% 31.4%);
    --orange-9: hsl(21.7 77.8% 26.5%);
  
    /* RED (IMPROVEME - Build out custom) */

    --red-0: hsl(0 85.7% 97.3%);
    --red-1: hsl(0 93.3% 94.1%);
    --red-2: hsl(0 96.3% 89.4%);
    --red-3: hsl(0 93.5% 81.8%);
    --red-4: hsl(0 90.6% 70.8%);
    --red-5: hsl(0 84.2% 60.2%);
    --red-6: hsl(0 72.2% 50.6%);
    --red-7: hsl(0 73.7% 41.8%);
    --red-8: hsl(0 70% 35.3%);
    --red-9: hsl(0 62.8% 30.6%);
  
    /* PROFILE COLORS */
    --profile-red: #D81D39;
    --profile-tangerine: #DB3A1A;
    --profile-orange: #DE6616;
    --profile-yellow: #E2B013;
    --profile-lime: #9DBC38;
    --profile-green: #53A84D;
    --profile-teal: #4AAB99;
    --profile-sky: #488BAD;
    --profile-blue: #4864AD;
    --profile-purple: #7D52A3;
    --profile-raspberry: #A74E9A;
    --profile-magenta: #C62F81;
  
  }
`;
