import { html } from 'lit';
import { ViewBase } from '../view-base.js';

import { homeConfirmViewStyles } from './home-confirm-view-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

import { Q1VerificationInfo } from '../../components/q1-verification-info/q1-verification-info.js';

export class HomeConfirmView extends ViewBase {
  static styles = [
    ...super.styles,
    homeConfirmViewStyles,
  ];
  
  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Confirm Your Membership - q1 Network');
  }

  get contentTemplate() {
    return html`
      <h1>One more step...</h1>
      <p>
        Verification codes &amp; links have been emailed to you 
        (text message verification will be enabled soon).
        To confirm your member registration, you can either follow the verification links or
        manually enter the verification codes below.
      </p>
      
      <hr/>

      <q1-verification-info
        .verificationInfo=${this.currentMember?.verificationInfo}
        .emailPrimary=${this.currentMember?.emailPrimary}
        no-change-primary
      ></q1-verification-info>

      <hr/>

      <h2>How your contact info makes the q1 Network more useful to you</h2>

      <ul>
        <li>
          <icon-frame>
            <sl-icon library="material" name="mail"></sl-icon>
          </icon-frame>
          <span>
            <strong>Your work email addresses</strong><br/>
            By linking and verifying all of the emails you use for work or professional
            development, you're able to:
            Direct email updates to the most relevant address,
            gain access to guilds & projects based on prior relationships,
            and help prior collaborators find you.
          </span>
        </li>
        
        <li>
          <icon-frame>
            <sl-icon library="material" name="smartphone"></sl-icon>
          </icon-frame>
          <span>
            <strong>Your mobile phone number</strong><br/>
            Text messages are used sparingly, but they are a key channel for delivering
            short updates about the projects you flag as being most important to you.
          </span>
        </li>
        <li>
          <icon-frame>
            <sl-icon library="material" name="block"></sl-icon>
          </icon-frame>
          <span>
            <strong>We don't spam or market to you.</strong><br/>
            Quorum1 is a member-owned collective working to bring professionals into mutually
            beneficial collaboration. We don't spam or send out marketing messages.
          </span>
        </li>
      </ul>
    `;
  }
}

customElements.define('home-confirm-view', HomeConfirmView);
