import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { projectIndexViewStyles } from './project-index-view-styles.js';

import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';

export class ProjectIndexView extends ViewBase {
  static styles = [
    ...super.styles,
    projectIndexViewStyles
  ];

  static properties = {
    ...super.properties,
    projects: { type: Array },
  };

  constructor() {
    super();
    this.projects = [];
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Projects - q1 Network');
  }

  get contentTemplate() {
    return html`
      <h1>q1 Network Projects</h1>

      <sl-divider></sl-divider>

      <notice-panel class="hero primary">
        <div class="inner">
          <sl-icon library="vectors" name="innovation"></sl-icon>

          <h2>The q1 Network hosts & supports projects...</h2>

          <p>
            The project functionality of the network is not yet generally available.
          </p>

          <p>
            Projects in the q1 Network are for active work and collaboration, supporting
            various compensation & volunteer structures.
            Projects can be for startups, established companies, consulting, learning,
            research, or more.
          </p>

          ${!!this.isSignedIn ? null : html`
            <p>
              <em>
                If you'd like to get support for your project or participate in other projects, 
                we recommend joining now so you can be ready for the wider release.
              </em>
            </p>

            <sl-button
              variant="warning"
              size="large"
              href="/join"
              pill
            >
              Join the Quorum
            </sl-button>
          `}
        </div>
      </notice-panel>
    `;
  }
}

customElements.define('project-index-view', ProjectIndexView);