import { css } from 'lit';

export const verifyEmailViewStyles = css`

  :host {
    --q1-view-width: var(--q1-content-width-narrow);
  }
  
  .logo {
    height: 5rem;
  }

  spinner-panel {
    display: block;
    font-size: 1.5rem;
    margin: 4rem 0;
  }
  spinner-panel sl-spinner {
    margin-right: 1rem;
  }

`;
