import { css } from 'lit';

export const Q1PlacesInputStyles = css`
  :host, :root {
    display: block;
    box-sizing: border-box;
    line-height: 1.1rem;
  }

  label {
    display: inline-block;
    font-size: var(--sl-input-label-font-size-medium);
    color: var(--sl-input-label-color);
    margin-bottom: calc(2 * var(--sl-spacing-3x-small));
    box-sizing: inherit;
  }
  :host([required]) label::after {
    content: var(--sl-input-required-content);
    /* margin-inline-start: var(--sl-input-required-content-offset); */
    margin-left: 0.15rem;
    color: var(--sl-input-required-content-color);
  }
  
  input-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    border: solid var(--sl-input-border-width) transparent;
    border-radius: var(--sl-input-border-radius-medium);
    font-size: var(--sl-input-font-size-medium);
    background-color: transparent;
    transition:
      var(--sl-transition-fast) color,
      var(--sl-transition-fast) border,
      var(--sl-transition-fast) box-shadow,
      var(--sl-transition-fast) background-color;
  }

  :host(:focus) input-body {
    background-color: var(--sl-input-background-color-focus);
    border-color: var(--sl-input-border-color-focus);
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-input-focus-ring-color);
  }

  ul#selected-places {
    padding: 0;
    margin: 0;
    list-style: none;
    
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  ul#selected-places li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
  }
  ul#selected-places li span {
    flex: 1 1 auto;
  }
  ul#selected-places li span.placeholder {
    font-style: italic;
    font-size: 0.8rem;
    color: var(--gray-6);
  }

  #help-text,
  #error-message {
    display: block;
    color: var(--sl-input-help-text-color);
    margin-top: var(--sl-spacing-3x-small);
    font-size: var(--sl-input-help-text-font-size-medium);
    box-sizing: inherit;
  }

  #error-message {
    color: var(--red-6);
  }

`;
