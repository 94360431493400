import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { resetPasswordViewStyles } from './reset-password-view-styles.js';
import { auth } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';

import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';

import { FormHostMixin } from '../../modules/form-host-mixin.js';
import { q1PasswordFunctions } from '../../modules/q1-core-functions.js';

export class ResetPasswordView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    resetPasswordViewStyles,
  ];

  static properties = {
    ...super.properties,

    email: { type: String },
    code: { type: String },
    password: { type: String },
    
    errorMessage: { type: String },
    viewMode: { type: String }, // initial, link-sent, verifying, password
    processing: { type: Boolean },
  };

  constructor() {
    super();

    this.email = this.urlParams.email || '';
    this.code = this.urlParams.code || '';
    this.password = '';

    this.errorMessage = null;
    this.viewMode = 'initial';
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Reset Password - q1 Network');

    if (this.isSignedIn && this.currentMember) {
      this.email = this.currentMember.emailPrimary;
    }

    if (this.email?.length && this.code?.length) this.verifyCode();
  }

  verifyCode() {
    const { verifyResetCode } = q1PasswordFunctions;
  
    this.viewMode = 'verifying';
    this.processing = true;
    this.errorMessage = null;

    const verifyCodeData = { email: this.email, code: this.code };
    console.log('verifyCodeData', verifyCodeData);

    verifyResetCode(verifyCodeData).then((result) => {
      this.showToast(result.data?.message, 'Reset Link Verified', 'primary');
      this.viewMode = 'password';
      this.processing = false;
    }).catch((error) => {
      console.error('Error verifying link', error);

      this.showToast('Error verifying link', null, 'danger');
      this.errorMessage = error.message;
      this.processing = false;
    });
  }

  submitInitiateFormEmail(event) {
    const { initiateResetByEmail } = q1PasswordFunctions;
    const initiateForm = this.renderRoot.querySelector('#initiate-form-email');

    event.preventDefault();

    if (!initiateForm.reportValidity()) {
      this.showToast('Please fix the form validation errors to continue', null, 'danger');
      return;
    }
  
    this.processing = true;
    this.errorMessage = null;

    const initiateFormData = { email: this.email };
    console.log('initiateFormData', initiateFormData);

    initiateResetByEmail(initiateFormData).then((result) => {
      this.showToast(result.data?.message, 'Password Reset Initiated', 'primary');
      this.viewMode = 'link-sent';
      this.processing = false;
    }).catch((error) => {
      console.error('Error initiating reset', error);

      this.showToast('Error initiating reset', null, 'danger');
      this.errorMessage = error.message;
      this.processing = false;
      
      this.updateComplete.then(() => {
        const emailInput = this.renderRoot.querySelector('#email');
        emailInput.focus();
      });
    });
  }

  submitInitiateFormAuth(event) {
    const { initiateResetByAuth } = q1PasswordFunctions;
    const initiateForm = this.renderRoot.querySelector('#initiate-form-auth');

    event.preventDefault();

    if (!confirm(`Would you like to send a password reset email to ${this.email}?`)) return;
  
    this.processing = true;
    this.errorMessage = null;

    initiateResetByAuth({}).then((result) => {
      this.showToast(result.data?.message, 'Password Reset Initiated', 'primary');
      this.viewMode = 'link-sent';
      this.processing = false;
    }).catch((error) => {
      console.error('Error initiating reset', error);

      this.showToast('Error initiating reset', null, 'danger');
      this.errorMessage = error.message;
      this.processing = false;
    });
  }

  submitPasswordForm(event) {
    const { processReset } = q1PasswordFunctions;
    const passwordForm = this.renderRoot.querySelector('#password-form');

    event.preventDefault();

    if (!passwordForm.reportValidity()) {
      this.showToast('Please fix the form validation errors to continue', null, 'danger');
      return;
    }
  
    this.processing = true;
    this.errorMessage = null;

    const passwordFormData = {
      email: this.email,
      code: this.code,
      password: this.password,
    };

    processReset(passwordFormData).then((result) => {
      this.showToast(result.data?.message, 'Password Reset Complete', 'success');
      if (this.isSignedIn) this.navigateTo('/home');
      else this.navigateTo('/signin');
    }).catch((error) => {
      console.error('Error saving new password', error);

      this.showToast('Error saving new password', null, 'danger');
      this.errorMessage = error.message;
      this.processing = false;
    });
  }

  get headerTemplate() {
    return null;
  }

  get footerTemplate() {
    return null;
  }

  get contentTemplate() {
    return html`

      <img src="/assets/images/q1-logo-boy.png" alt="q1 Logo" class="logo"/>

      <h1>q1 Network: Reset Password</h1>

      <hr/>

      ${!this.errorMessage?.length ? null : html`
        <sl-alert variant="danger" open>
          <sl-icon slot="icon" name="warning"></sl-icon>
          <strong>An error occurred.</strong>
          ${this.errorMessage}
        </sl-alert>
      `}

      ${this.viewMode === 'initial' ? this.initiateForm : null}
      
      ${this.viewMode === 'password' ? this.passwordForm : null}

      ${this.viewMode !== 'link-sent' ? null : html`
        <p class="subtitle">
          A password reset link has been sent to <em>${this.email}</em>.
          <br/><br/>
          Please click the link in the email to continue.
        </p>
      `}

      <q1-spinner-panel label="Processing..." ?open=${this.processing}></q1-spinner-panel>
    `;
  }

  get initiateForm() {
    if (this.isSignedIn) return this.initiateForm_auth;
    return this.initiateForm_email;
  }

  get initiateForm_email() {
    return html`
      <p class="subtitle">
        Enter one of the email addresses associated with your q1 Network membership in the box 
        below to request a password reset link.
      </p>

      <form id="initiate-form-email" @submit="${this.submitInitiateFormEmail}">
        <sl-input 
          id="email" 
          name="email" 
          type="email"
          .value="${this.email}"
          @sl-input="${this.handleInput}"
          label="Email"
          required
          autocomplete="email"
          exportparts="input"
        >
          <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
        </sl-input>

        <hr/>

        <sl-button type="submit" variant="primary">Request Reset Link</sl-button>
      </form>
    `;
  }

  get initiateForm_auth() {
    return html`
      <p class="subtitle">
        You are already signed in. To reset your password without knowing your current password, 
        just click the button below and you'll be sent a password reset link at your primary email.
      </p>

      <form id="initiate-form-email" @submit="${this.submitInitiateFormAuth}">
        <sl-input 
          id="email" 
          name="email" 
          type="email"
          .value="${this.email}"
          @sl-input="${this.handleInput}"
          label="Email"
          disabled
        >
          <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
        </sl-input>

        <hr/>

        <sl-button type="submit" variant="primary">Request Reset Link</sl-button>
      </form>
    `;
  }
  
  get passwordForm() {
    return html`
      <p class="subtitle">
        Your password reset link is verified! Please create a new password using the form below.
      </p>

      <form id="password-form" @submit="${this.submitPasswordForm}">
        <sl-input 
          id="email" 
          name="email" 
          type="email"
          .value="${this.email}"
          label="Email"
          disabled
        >
          <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
        </sl-input>

        <sl-input
          id="password"
          name="password"
          type="password"
          label="Password"
          @sl-input="${this.handleInput}"
          ?disabled="${this.processing}"
          minlength="8"
          required
          clearable
          password-toggle
        >
          <sl-icon library="material" name="key" slot="prefix"></sl-icon>
        </sl-input>

        <hr/>

        <sl-button type="submit" variant="primary">Save New Password</sl-button>
      </form>
    `;
  }
}

customElements.define('reset-password-view', ResetPasswordView);
