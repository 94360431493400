import { css } from 'lit';

export const registerViewStyles = css`

  :host {
    background: linear-gradient(0deg, var(--gray-1) 0%, var(--gray-1) 70%, var(--yellow-2) 100%);

    --q1-view-width: var(--q1-content-width-narrow);
  }

  .form-footer {
    margin: 2.5rem 0;
    font-style: italic;
  }

`;
