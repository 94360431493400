import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';

export const FormHostMixin = (SuperClass) => class extends SuperClass {

  // FIXME >> Refactor other parts of code base to use this. Contemplate renaming this file.

  /**
   * Overwrite this getter to add post processing steps fired after input based on field name.
   * These are invoked after handleChange() AND after handleInput().
   */
  get handleInputPostProcess() {
    return {
      // EXAMPLE:
      // emailsAdditionalText: (newValue, targetElement)
      //   => this.handleEmailsAdditionalTextChange(newValue),
    };
  }

  /**
   * Base event form handler. Maps input name to component instance variable.
   * Use EITHER handleChange() or handleInput(). They are equivalent and using
   * both would be redundant.
   * 
   * Example Usage:
   * ```html
   * <sl-input name="nameInternal" \@sl-change="${this.handleChange}"></sl-input>
   * ```
   */
  handleChange(event) {
    this.handleInput(event);
  }

  /**
   * Base event form handler. Maps input name to component instance variable.
   * 
   * Example Usage:
   * ```html
   * <sl-input name="nameInternal" \@sl-input="${this.handleInput}"></sl-input>
   * ```
   */
  handleInput(event) {
    const fieldName = event.target.name || event.target.id; // id needed for non-form data fields
    const fieldValue = event.target.value;

    if (!!fieldName) {
      this[fieldName] = fieldValue;

      if (this.handleInputPostProcess[fieldName]) {
        this.handleInputPostProcess[fieldName](fieldValue, event.target);
      }
    }
  }

  /**
   * Serializes a form and returns a plain object. If a form control with the same name appears 
   * more than once, the property will be converted to an array.
   */
  _serializeForm(formElement) {
    return serialize(formElement);
  }

}
