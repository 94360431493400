import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { settingsViewStyles } from './settings-view-styles.js';

import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';
import '@shoelace-style/shoelace/dist/components/color-picker/color-picker.js';
import '@shoelace-style/shoelace/dist/components/switch/switch.js';
import '@shoelace-style/shoelace/dist/components/details/details.js';
import '@shoelace-style/shoelace/dist/components/tab/tab.js';
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js';
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js';

import { FormHostMixin } from '../../modules/form-host-mixin.js';
import { Q1VerificationInfo } from '../../components/q1-verification-info/q1-verification-info.js';
import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';
import { q1PasswordFunctions } from '../../modules/q1-core-functions.js';
import { updateMemberById } from '../../modules/firestore-helpers.js';
import { Q1ImageUploadInput } from '../../components/q1-image-upload-input/q1-image-upload-input.js';
import { Q1PlacesInput } from '../../components/q1-places-input/q1-places-input.js';

const TAB_SETTINGS = {
  profile: { path: '/settings', title: 'Profile Settings - q1 Network' },
  contact: { path: '/settings/contact', title: 'Contact Settings - q1 Network' },
  membership: { path: '/settings/membership', title: 'Membership Settings - q1 Network' },
  security: { path: '/settings/security', title: 'Account Security - q1 Network' },
};

export class SettingsView extends FormHostMixin(ViewBase) {
  static styles = [
    ...super.styles,
    settingsViewStyles,
  ];

  static properties = {
    ...super.properties,
    
    isLoading: { type: Boolean },
    profileLink: { type: String },
    isProfilePrivate: { type: Boolean }, // set automatically based on nameExternal
    
    nameInternal: { type: String },
    nameExternal: { type: String },
    headline: { type: String },
    profile_newUploadPath: { type: String },
    banner_newUploadPath: { type: String },
    colorPrimary: { type: String },
    locationsConfig: { type: Array },
    
    memberLevel: { type: String },
    newPassword: { type: String },
    initialTab: { type: String },
  };

  constructor() {
    super();
    this.isLoading = false;
    this.profileLink = '';
    this.isProfilePrivate = true; 

    this.nameInternal = '';
    this.nameExternal = '';
    this.headline = '';
    this.profile_newUploadPath = '';
    this.banner_newUploadPath = '';
    this.colorPrimary = '';
    this.locationsConfig = [];

    this.memberLevel = '';
    this.newPassword = '';
    this.initialTab = 'profile';
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('Your Settings - q1 Network');

    if (this.routeParams.key && TAB_SETTINGS[this.routeParams.key]) {
      this.initialTab = this.routeParams.key;
    }
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    if (changedProperties.has('currentMember')) {
      const cm = this.currentMember || {};

      this.profileLink = `${window.location.origin}/@${cm.number}`;
      this.nameInternal = cm.nameInternal || '';
      this.nameExternal = cm.nameExternal || '';
      this.headline = cm.headline || '';
      this.colorPrimary = cm.colorPrimary || '';
      this.locationsConfig = cm.locationsConfig || [];

      if (!cm.level?.length) cm.level = 'network';
      this.memberLevel = cm.level[0].toUpperCase() + cm.level.substring(1);

      this.isProfilePrivate = !this.nameExternal.trim().length;
    }
  }

  /** Update the location bar & document title based on selected tab. */
  handleTabShow(event) {
    const newTabName = event.detail.name;
    const { path, title } = TAB_SETTINGS[newTabName];

    history.pushState(null, null, path);
    this.setDocumentTitle(title);
  }

  handleSettingsFormSubmit(event) {
    event.preventDefault();
    this.isLoading = true;

    const settingsForm = this.renderRoot.querySelector('#settings-form');
    if (!settingsForm.reportValidity()) {
      this.showToast(
        'Please fix the form validation errors to continue', null, 'danger'
      );
      this.isLoading = false;
      return;
    }

    const settingsFormData = {
      ...this._serializeForm(settingsForm),
      locationsConfig: this.locationsConfig,
    };
  
    updateMemberById(this.currentMember.id, settingsFormData).then(() => {
      this.showToast('Profile updated successfully!', null, 'success');
      this.dispatchEvent(new CustomEvent('refresh-current-member', {
        bubbles: true, composed: true,
      }));
      this.isLoading = false;
    }).catch((error) => {
      console.error('Error updating profile:', error);
      this.showToast(error.message, 'Failed to update profile', 'danger');
      this.isLoading = false;
    });
  }

  handleLocationsChange() {
    const locationsInput = this.renderRoot.querySelector('#locations-input');
    this.locationsConfig = locationsInput.places;
  }

  handleIsProfilePrivateChange() {
    const isProfilePrivateInput = this.renderRoot.querySelector('#isProfilePrivate');
    
    this.nameExternal = isProfilePrivateInput.checked ? '' : this.nameInternal;
  }

  changePasswordShow(){
    this.newPassword = '';
    this.renderRoot.querySelector('#change-password-dialog').show().then(() => {
      this.renderRoot.querySelector('#change-password-input').focus();
    });
  }

  changePasswordSave() {
    const { changePassword } = q1PasswordFunctions;
    
    // First verify the input and report to user if invalid
    const passwordInput = this.renderRoot.querySelector('#change-password-input');
    if (!passwordInput.reportValidity()) return;

    const changePasswordDialog = this.renderRoot.querySelector('#change-password-dialog');
    this.isLoading = true;

    changePassword({
      password: this.newPassword,
    }).then((result) => {
      const { message } = result.data || {};

      changePasswordDialog.hide();
      this.showToast(message, 'Change Password Success.', 'success');
      this.isLoading = false;
    }).catch((error) => {
      console.log('ERROR: ' + error.message);
      
      this.isLoading = false;      
      this.showToast(error.message, 'Change Password Error.', 'danger');
      passwordInput.focus();
    });
  }
  
  changePasswordClose() {
    this.renderRoot.querySelector('#change-password-dialog').hide();
  }

  get handleInputPostProcess() {
    return {
      nameExternal: (newValue) => {
        this.isProfilePrivate = !newValue?.length;
      },
    };
  }

  get contentTemplate() {
    return html`
      <h1>Settings</h1>

      <sl-tab-group id="tab-group" @sl-tab-show=${this.handleTabShow}>
        <sl-tab slot="nav" panel="profile" ?active=${this.initialTab === 'profile'}>
          Profile
        </sl-tab>
        <sl-tab slot="nav" panel="contact" ?active=${this.initialTab === 'contact'}>Contact
           Info
          </sl-tab>
        <sl-tab slot="nav" panel="membership" ?active=${this.initialTab === 'membership'}>
          Membership
        </sl-tab>
        <sl-tab slot="nav" panel="security" ?active=${this.initialTab === 'security'}>
          Security
        </sl-tab>
        
        
        <sl-tab-panel name="profile">
          <profile-visibility-panel>
            <h3>Your q1 Network Member Profile</h3>
            
            <p>Your profile is accessible at the below URL. </p>

            <a href=${this.profileLink}>${this.profileLink}</a>

            <sl-switch
              id="isProfilePrivate"
              name="isProfilePrivate"
              ?checked=${this.isProfilePrivate}
              @sl-change=${this.handleIsProfilePrivateChange}
            >
              Private Profile Mode
              <span slot="help-text">
                Hides your name & guild/project memberships externally,
                only visible to other registered q1 Network members.
              </span>
            </sl-switch>
          </profile-visibility-panel>

          <hr/>

          <form id="settings-form" class="q1" @submit=${this.handleSettingsFormSubmit}>

            <h2>Profile Settings</h2>

            <field-pair>
              <sl-input 
                id="nameInternal"
                name="nameInternal"
                .value="${this.nameInternal}"
                @sl-input="${this.handleInput}"
                label="Internal Name"
                ?disabled=${this.isLoading}
              >
                <span slot="help-text">
                  What other Quorum1 members see.
                </span>
              </sl-input>

              <sl-input 
                id="nameExternal"
                name="nameExternal"
                .value="${this.nameExternal}"
                @sl-input="${this.handleInput}"
                label="External Name"
                ?disabled=${this.isLoading}
              >
                <span slot="help-text">
                  What is shown publicly to non-members.
                  Set this field to a blank value to be externally anonymous.
                </span>
              </sl-input>
            </field-pair>

            <field-pair>
              <sl-textarea 
                id="headline"
                name="headline"
                .value="${this.headline}"
                @sl-input="${this.handleInput}"
                label="Profile Headline"
                rows="3"
                maxlength="250"
                ?disabled=${this.isLoading}
              >
                <span slot="help-text">
                  Shown under your name on your profile.
                </span>
              </sl-textarea>

              <q1-image-upload-input
                id="profile_newUploadPath"
                name="profile_newUploadPath"
                upload-folder="member-profile"
                current-image-thumbnail=${this.cmImage_profile?.mini}
                @change="${this.handleInput}"
                label="Profile Image"
                ?disabled=${this.isLoading}
              ></q1-image-upload-input>
            </field-pair>

            <field-pair>
              <input-wrapper>
                <label for="colorPrimary">Profile Color</label>
                <sl-color-picker
                  id="colorPrimary"
                  name="colorPrimary"
                  .value=${this.colorPrimary}
                  label="Profile Color"
                  size="large"
                  swatches="#D81D39; #DB3A1A; #DE6616; #E2B013; #9DBC38; #53A84D; 
                    #4AAB99; #488BAD; #4864AD; #7D52A3; #A74E9A; #C62F81;"
                ></sl-color-picker>
              </input-wrapper>

              <q1-image-upload-input
                id="banner_newUploadPath"
                name="banner_newUploadPath"
                upload-folder="member-banner"
                current-image-thumbnail=${this.cmImage_banner?.mini}
                @change="${this.handleInput}"
                label="Banner Image"
                ?disabled=${this.isLoading}
              ></q1-image-upload-input>
            </field-pair>

            <field-pair>
              <q1-places-input
                id="locations-input"
                label="Locations"
                help-text="Add all the places you've lived, worked, or built a significant network"
                .places=${this.locationsConfig}
                @change=${this.handleLocationsChange}
              ></q1-places-input>
              
              <field-spacer></field-spacer>
            </field-pair>

            <hr/>
            
            <sl-button
              variant="primary"
              type="submit"
              size="large"
              ?disabled=${this.isLoading}
            >Save Changes</sl-button>
          </form>
        </sl-tab-panel>
        
        <sl-tab-panel name="contact">
          <h2>Email & Phone Details</h2>

          <q1-verification-info
            .verificationInfo=${this.currentMember?.verificationInfo}
            .emailPrimary=${this.currentMember?.emailPrimary}
            no-redirects
          ></q1-verification-info>
        </sl-tab-panel>
        
        <sl-tab-panel name="membership">
          <h2>Your Quorum1 Membership</h2>

          <div class="subtitle">
            All members of the q1 Network are also members of the Quorum1 collective.
            <br/><br/>
            You are currently a <strong>${this.memberLevel} Level</strong> member.
            <br/><br/>
            If you have any questions, please reach out to the Foundation Team at
            <a href="mailto:team@quorum.one">team@quorum.one</a>.
          </div>

          <hr/>

          <h3>Membership Levels</h3>

          <div class="subtitle">
            The expandable sections below explain the different levels of membership.
            Quorum1 does not charge membership fees in the traditional sense, 
            the levels below are driven primarily by agreements, engagement, and commitment.
          </div>

          <p>
            As part of our commitment to transparency, trust, and collaborative decision-making,
            all of the agreements & docs linked to in the sections below are defined in the
            <a target="_blank" href="https://github.com/quorum1/governance"
              >Quorum1 Governance Repo</a> (aka "the Gov Repo"),
            managed as an open source repository on Github.
            The Gov Repo is far from perfect and members at all levels are encouraged to help us
            continue to improve & evolve it.
          </p>

          <sl-details summary="All Members">
            <p>
              Members at <strong>All Levels</strong> are considered part of the Quorum1 Community
              and are participants in the following base agreements.
            </p>
            <p><strong>Terms & Agreements:</strong></p>
            <ul>
              <li>
                <a target="_blank"
                  href="https://github.com/quorum1/governance/blob/main/docs/community-agreement.md"
                >Community Member Agreement</a>
              </li>
              <li>
                <a target="_blank"
                  href="https://github.com/quorum1/governance/blob/main/docs/rules-and-regs.md"
                >Rules & Regs</a>
              </li>
              <li>
                <a target="_blank"
                  href="https://github.com/quorum1/governance/blob/main/docs/terms-of-service.md"
                >Terms of Service</a>
              </li>
              <li>
                <a target="_blank"
                  href="https://github.com/quorum1/governance/blob/main/docs/privacy-policy.md"
                >Privacy Policy</a>
              </li>
            </ul>
          </sl-details>

          <sl-details summary="Network Members">
            <p>
              The <strong>Network Level</strong> of membership is the simplest level, and it is 
              where most members begin. Members at this level get access to more basic systems and 
              are able to follow and support Projects & Guilds, but cannot participate in 
              Contributor or Organizer roles.
            </p>
            <p>
              Network Members do not actively participate in collective governance.
            </p>
            <p><strong>Terms & Agreements:</strong></p>
            <ul>
              <li>
                There are no agreements specific to this membership level.
              </li>
            </ul>
          </sl-details>
          
          <sl-details summary="Contributor Members">
            <p>
              The <strong>Contributor Level</strong> of membership involves signing a simplified 
              version of an independent contractor agreement, as an individual or as both an 
              individual and a corporate entity. Members at this level can get access to more
              systems, can earn compensation, and can engage in all types of Project & Guild roles.
            </p>
            <p>
              Contributor Members are able to fully participate in collective governance.
            </p>
            <p><strong>Terms & Agreements:</strong></p>
            <ul>
              <li>
                <a target="_blank"
                  href="https://github.com/quorum1/governance/blob/main/docs/ic-agreement.md"
                >Independent Contractor Agreement</a> - Importantly, the base version of this 
                agreement is <strong>IP Neutral</strong>, meaning that it does not grant IP 
                ownership in either direction.
              </li>
              <li>
                <strong>Project-Specific SOWs</strong> - As members join Projects, they do so by
                agreeing to short "Statements of Work" which clarify important details like 
                work product ownership and compensation. These SOWs vary from project to project.
              </li>
            </ul>
          </sl-details>

          <sl-details summary="Foundation Members">
            <p>
              The <strong>Foundation Level</strong> of membership is for members helping with
              projects related to the Quorum1 collective as a whole.
              Foundation membership is still fairly informal and is open to anyone interested 
              in getting more involved, though there are requirements around commitment level
              and certain roles may require a good deal of learning.
            </p>
            <p>
              Foundation Members are able to fully participate in collective governance, and may
              also help to organize and administer certain governance processes.
            </p>
            <p><strong>Terms & Agreements:</strong></p>
            <ul>
              <li>
                There are no agreements specific to this membership level (yet).
              </li>
            </ul>
          </sl-details>

          <sl-details summary="Steward Members">
            <p>
              The <strong>Steward Level</strong> holds the deepest level of expertise and trust 
              in the operation and evolution of the core functioning of Quorum1. Membership at this
              level is also open to all, but has more stringent requirements. Reach out to the 
              existing stewards if interested.
            </p>
            <p>
              Steward Members serve critical, high trust roles in the collective governance
              processes and help to steward the ongoing evolution of the governance structure
              itself.
            </p>
            <p><strong>Terms & Agreements:</strong></p>
            <ul>
              <li>
                The Steward Member agreements are in the process of being refactored.
              </li>
            </ul>
          </sl-details>
          
        </sl-tab-panel>

        <sl-tab-panel name="security">
          <h2>Account Security Settings</h2>

          <sl-button
            @click=${this.changePasswordShow}
            variant="primary"
            size="large"
          >
            <sl-icon library="material" name="key" slot="prefix"></sl-icon>
            Change Password
          </sl-button>
        </sl-tab-panel>
      </sl-tab-group>
      ${this.renderChangePasswordDialog()}
    `;
  }

  renderChangePasswordDialog() {
    return html`
      <sl-dialog label="Change Password" id="change-password-dialog">
        <p>
          Type a new password below.
        </p>

        <sl-input
          id="change-password-input"
          name="newPassword"
          type="password"
          .value="${this.newPassword}"
          @sl-input="${this.handleInput}"
          label="Password"
          minlength="8"
          required
          clearable
          password-toggle
        ></sl-input>

        <button-list slot="footer">
          <sl-button
            variant="primary"
            @click="${this.changePasswordSave}"
          >Save</sl-button>
          <sl-button
            variant="default"
            @click="${this.changePasswordClose}"
          >Cancel</sl-button>
        </button-list>

        <q1-spinner-panel label="Processing..." ?open=${this.isLoading}></q1-spinner-panel>
      </sl-dialog>
    `;
  }
}

customElements.define('settings-view', SettingsView);