import {
  db, doc, updateDoc, getDoc, deleteDoc, collection, onSnapshot
} from '../firebaseConfig.js';

/** Generates the member ref instance. Does not ping the db. */
const getMemberRef = (memberId) => {
  return doc(db, `members/${memberId}`);
};

/**
 * Gets member by id.
 * 
 * @param {string} memberId - Firestore id of the member record
 * @param {boolean} throwErrors - Defaults to true. If true, throws HttpsError for no match.
 *   If false, returns null in error state.
 * 
 * @returns {Object} - Returns "memberRecord" with keys: `ref` and `data`.
 */
const getMemberById = async (memberId, throwErrors = true) => {
  const memberRef = getMemberRef(memberId);
  const memberSnapshot = await getDoc(memberRef);

  if (!memberSnapshot.exists) {
    if (throwErrors) throw new Error(`Member id not found: ${memberId}`);

    return null;
  }

  return {
    ref: memberSnapshot.ref,
    data: { id: memberSnapshot.id, ...memberSnapshot.data() },
  };
};

/**
 * Updates the specified member. Does not capture errors.
 */
const updateMemberById = async (memberId, memberData) => {
  const memberRef = getMemberRef(memberId);

  await updateDoc(memberRef, memberData);
};

export {
  getMemberRef,
  getMemberById,
  updateMemberById,
}
