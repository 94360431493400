import { css } from 'lit';

export const Q1ImageUploadInputStyles = css`
  :host, :root {
    display: block;
    box-sizing: border-box;
    line-height: 1.1rem;
  }

  label {
    display: inline-block;
    font-size: var(--sl-input-label-font-size-medium);
    color: var(--sl-input-label-color);
    margin-bottom: var(--sl-spacing-3x-small);
    box-sizing: inherit;
  }
  :host([required]) label::after {
    content: var(--sl-input-required-content);
    /* margin-inline-start: var(--sl-input-required-content-offset); */
    margin-left: 0.15rem;
    color: var(--sl-input-required-content-color);
  }
  
  input-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 4rem;

    border: solid var(--sl-input-border-width) transparent;
    border-radius: var(--sl-input-border-radius-medium);
    font-size: var(--sl-input-font-size-medium);
    background-color: transparent;
    transition:
      var(--sl-transition-fast) color,
      var(--sl-transition-fast) border,
      var(--sl-transition-fast) box-shadow,
      var(--sl-transition-fast) background-color;
  }

  :host(:focus) input-body {
    background-color: var(--sl-input-background-color-focus);
    border-color: var(--sl-input-border-color-focus);
    box-shadow: 0 0 0 var(--sl-focus-ring-width) var(--sl-input-focus-ring-color);
  }

  .preview {
    height: 4rem;
    width: auto;
    overflow: hidden;
    border: solid var(--sl-input-border-width) var(--sl-input-border-color);
    border-radius: var(--sl-input-border-radius-medium);
  }
  .preview.placeholder {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
    cursor: pointer;
    
    background: var(--gray-0);
    color: var(--gray-5);
    font-weight: 300;
    font-style: italic;
  }

  #help-text,
  #error-message {
    display: block;
    color: var(--sl-input-help-text-color);
    margin-top: var(--sl-spacing-3x-small);
    font-size: var(--sl-input-help-text-font-size-medium);
    box-sizing: inherit;
  }

  #error-message {
    color: var(--red-6);
  }

`;
