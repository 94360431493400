import { html, css, LitElement } from 'lit';
import { auth, signInWithEmailAndPassword, signInWithCustomToken } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';

import { q1VerificationInfoStyles } from './q1-verification-info-styles.js';
import { q1VerificationFunctions } from '../../modules/q1-core-functions.js'
import { FormHostMixin } from '../../modules/form-host-mixin.js'
import { Q1TelInput } from '../q1-tel-input/q1-tel-input.js';

const PHONE_HELP_TEXT = 'Used for important comms. '
  + 'By providing your phone number you are consenting to receive occasional text messages '
  + 'from Quorum1 and the q1 network, according to your preferences.';

export class Q1VerificationInfo extends FormHostMixin(LitElement) {
  static styles = [ q1VerificationInfoStyles ];

  static properties = {
    verificationInfo: { type: String },
    viewMode: { type: String }, // default, refreshing, processing
    noRedirects: { type: Boolean, reflect: true, attribute: 'no-redirects' },
    noChangePrimary: { type: Boolean, reflect: true, attribute: 'no-change-primary' },
    
    emailPrimary: { type: String },
    keyType: { type: String },
    keyType_label: { type: String },
    keyValue: { type: String },
    code: { type: String },
    newPhone: { type: String },
    newEmail: { type: String },
    confirmPassword: { type: String },
    
    _verificationItems: { type: Array, state: true },
  };

  constructor() {
    super();

    this.viewMode = 'default';
    this.verificationInfo = {};
    this.noRedirects = false;
    this.noChangePrimary = false;

    this.emailPrimary = undefined;
    this.keyType = undefined;
    this.keyType_label = undefined;
    this.keyValue = undefined;
    this.code = '';
    this.newPhone = '';
    this.newEmail = '';
    this.confirmPassword = '';

    this._verificationItems = [];
  }

  willUpdate(changedProperties) {
    if (changedProperties.has('verificationInfo')) {
      if (this.viewMode === 'refreshing') {
        this.viewMode = 'default';

        // Check for newly confirmed
        const appElement = document.querySelector('q1-home');
        const newlyConfirmed = appElement.currentMember.status === 'confirmed';

        if (newlyConfirmed) {
          appElement.queueToast(
            'Your membership is now confirmed.', 'Confirmation Complete!', 'success'
          );
          if (!this.noRedirects) window.location.href="/home";
        }
      }

      this._verificationItems = Object
        .entries(this.verificationInfo)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([key, body]) => {
          const { keyType, keyValue } = this.parseVerificationKey(key);
          const { status } = body;
          const isPrimary = (keyType === 'phone') || (this.emailPrimary === keyValue);
          const isVerified = (status === 'verified');

          const typeIcon = keyType === 'email' ? 'mail' : 'smartphone';
          let typeLabel = 'Additional Email';
          if (isPrimary) typeLabel = 'Primary Email';
          if (keyType === 'phone') typeLabel = 'Primary Phone';
          
          const statusIcon = isVerified ? 'check_circle' : 'error';
          const statusLabel = (status === 'verified') ? 'Verified' : 'Unverified';
          const resendIcon = keyType === 'email' ? 'forward_to_inbox' : 'send_to_mobile';

          return {
            keyType, keyValue, status, isPrimary, isVerified,
            typeIcon, statusIcon, resendIcon, typeLabel, statusLabel,
          };
        });
    }
  }

  refresh() {
    this.viewMode = 'refreshing';

    this.dispatchEvent(new CustomEvent('refresh-current-member', {
      bubbles: true, composed: true,
    }));
  }

  changePhone() {
    this.renderRoot.querySelector('#change-phone-dialog').show().then(() => {
      this.renderRoot.querySelector('#change-phone-input').focus();
    });
  }

  changePhoneSave() {
    const { changePhone } = q1VerificationFunctions;

    const self = this;
    const appElement = document.querySelector('q1-home');
    
    // First verify the input and report to user if invalid
    const phoneInput = this.renderRoot.querySelector('#change-phone-input');
    if (!phoneInput.reportValidity()) return;

    // Else we can assume this.newPhone contains a valid phone number
    this.viewMode = 'processing';
    const hideDialogComplete = this.renderRoot.querySelector('#change-phone-dialog').hide();

    changePhone({
      phone: this.newPhone,
    }).then((result) => {
      const { message, resultCode } = result.data || {};

      self.viewMode = 'default';
      appElement.showToast(message, 'Change Phone Success.', 'success');
      self.refresh();

      self.newPhone = ''; phoneInput.value = '';
    }).catch((error) => {
      console.log('ERROR: ' + error.message);
      
      self.viewMode = 'default';
      appElement.showToast(error.message, 'Change Phone Error.', 'danger');
      
      hideDialogComplete.then(() => {
        this.renderRoot.querySelector('#change-phone-dialog').show();
      });
    });
  }
  
  changePhoneClose() {
    this.renderRoot.querySelector('#change-phone-dialog').hide();
  }

  addEmail() {
    this.renderRoot.querySelector('#add-email-dialog').show().then(() => {
      this.renderRoot.querySelector('#add-email-input').focus();
    });
  }

  addEmailSave() {
    const { addEmail } = q1VerificationFunctions;

    // First verify the inputs and report to user if invalid
    const emailInput = this.renderRoot.querySelector('#add-email-input');
    const passwordInput = this.renderRoot.querySelector('#add-email-password-input');
    emailInput.setCustomValidity(''); // reset if needed
    passwordInput.setCustomValidity(''); // reset if needed
    if (!emailInput.reportValidity() || !passwordInput.reportValidity()) return;

    // Else we can assume this.newEmail and this.confirmPassword are set
    this.viewMode = 'processing';
    const hideDialogComplete = this.renderRoot.querySelector('#add-email-dialog').hide();

    const self = this;
    const appElement = document.querySelector('q1-home');

    // Before we move forward we need to verify that the password is correct, which can only be
    // done on the frontend client by attempting to sign in.
    signInWithEmailAndPassword(
      auth, this.emailPrimary, this.confirmPassword
    ).then((userCredential) => {
      console.log('Confirmed password sign in successful, userCredential: ', userCredential);
      
      // Now we know that the password is correct and we can pass it to the backend function
      addEmail({
        email: this.newEmail,
        password: this.confirmPassword,
      }).then((result) => {
        const { message, resultCode } = result.data || {};
  
        self.viewMode = 'default';
        appElement.showToast(message, 'Add Email Complete.', 'success');
        self.refresh();
  
        self.newEmail = ''; emailInput.value = '';
        self.confirmPassword = ''; passwordInput.value = '';
      }).catch((error) => {
        console.log('ERROR: ' + error.message);
        
        self.viewMode = 'default';
        appElement.showToast(error.message, 'Add Email Failure.', 'danger');

        hideDialogComplete.then(() => {
          self.renderRoot.querySelector('#add-email-dialog').show().then(() => {
            emailInput.setCustomValidity('Email address already in use');
            emailInput.reportValidity();
          });
        });
      });
    }).catch((error => {
      console.log(`Error signing in with confirmed password. Message: ${error.message}`);

      self.viewMode = 'default';
      appElement.showToast(
        'The password you entered did not match your current password.',
        'Error Confirming Password.',
        'danger'
      );
      
      hideDialogComplete.then(() => {
        self.renderRoot.querySelector('#add-email-dialog').show().then(() => {
          passwordInput.setCustomValidity('Did not match your current password');
          passwordInput.reportValidity();
        });
      });
    }));
  }
  
  addEmailClose() {
    this.renderRoot.querySelector('#add-email-dialog').hide();
  }

  verify(keyType, keyValue) {
    this.keyType = keyType;
    this.keyType_label = keyType[0].toLocaleUpperCase() + keyType.substring(1);
    this.keyValue = keyValue;

    this.renderRoot.querySelector('#verify-dialog').show().then(() => {
      this.renderRoot.querySelector('#verify-code-input').focus();
    });
  }
  
  verifySave() {
    const { processVerificationCode } = q1VerificationFunctions;

    // First verify the input pattern and report to user if invalid
    const codeInput = this.renderRoot.querySelector('#verify-code-input');
    if (!codeInput.reportValidity()) return;

    // Else we can assume this.code contains a code
    this.viewMode = 'processing';
    this.renderRoot.querySelector('#verify-dialog').hide();

    const self = this;
    const appElement = document.querySelector('q1-home');
    
    processVerificationCode({
      keyType: this.keyType,
      keyValue: this.keyValue,
      verificationCode: this.code,
      generateAuthToken: false,
    }).then((result) => {
      const { message, resultCode, newlyConfirmed } = result.data || {};

      self.viewMode = 'default';
      appElement.showToast(message, 'Verify Code Complete.', 'success');
      self.refresh();

      self.code = ''; codeInput.value = '';

      if (newlyConfirmed) {
        appElement.queueToast(
          'Your membership is now confirmed.', 'Confirmation Complete!', 'success'
        );
        if (!this.noRedirects) window.location.href="/home";
      }
    }).catch((error) => {
      console.log('ERROR: ' + error.message);
      
      self.viewMode = 'default';
      appElement.showToast(error.message, 'Verification Failure.', 'danger');

      self.code = ''; codeInput.value = '';
    });
  }
  
  verifyClose() {
    this.renderRoot.querySelector('#verify-dialog').hide();
  }
  
  resendCode(keyType, keyValue) {
    const { resetVerificationCode } = q1VerificationFunctions;

    if (!confirm(`Reset and resend verification code for ${keyValue}?`)) return;

    this.viewMode = 'processing';

    const self = this;
    const appElement = document.querySelector('q1-home');
    
    resetVerificationCode({
      keyType,
      keyValue,
    }).then((result) => {
      const { message, resultCode } = result.data || {};

      self.viewMode = 'default';
      appElement.showToast(message, 'Resend Verification Code Success.', 'success');
    }).catch((error) => {
      console.log('ERROR: ' + error.message);
      
      self.viewMode = 'default';
      appElement.showToast(error.message, 'Resend Verification Code Error.', 'danger');
    });
  }

  removeEmail(email) {
    const { removeEmail } = q1VerificationFunctions;

    if (!confirm(`Are you sure you want to remove ${email} from your member account?`)) return;

    this.viewMode = 'processing';

    const self = this;
    const appElement = document.querySelector('q1-home');
    
    removeEmail({
      email,
    }).then((result) => {
      const { message, resultCode } = result.data || {};

      self.viewMode = 'default';
      appElement.showToast(message, 'Remove Email Success.', 'success');
      self.refresh();
    }).catch((error) => {
      console.log('ERROR: ' + error.message);
      
      self.viewMode = 'default';
      appElement.showToast(error.message, 'Remove Email Error.', 'danger');
    });
  }

  setPrimaryEmail(email) {
    const { setPrimaryEmail } = q1VerificationFunctions;

    if (!confirm(`Do you want to set ${email} as your primary email?`)) return;

    this.viewMode = 'processing';

    const self = this;
    const appElement = document.querySelector('q1-home');
    
    setPrimaryEmail({
      email,
    }).then((result) => {
      const { message, resultCode } = result.data || {};

      self.viewMode = 'default';
      appElement.showToast(message, 'Primary Email Update', 'success');
      self.refresh();
    }).catch((error) => {
      console.log('ERROR: ' + error.message);
      
      self.viewMode = 'default';
      appElement.showToast(error.message, `Could not set ${email} as primary`, 'danger');
    });
  }

  // FIXME: This is duplicated from the function modules. DRY this up.
  decodeAsFirebaseKey(string) {
    return string.replace(/\%25/g, '%')
      .replace(/\%2E/g, '.')
      .replace(/\%23/g, '#')
      .replace(/\%24/g, '$')
      .replace(/\%2F/g, '/')
      .replace(/\%5B/g, '[')
      .replace(/\%5D/g, ']');
  };

  // FIXME: This is duplicated from the function modules. DRY this up.
  parseVerificationKey(verificationKey) {
    const decodedKey = this.decodeAsFirebaseKey(verificationKey);
    const [keyType, keyValue] = decodedKey.split('/');
  
    return { keyType, keyValue };
  };

  render() {
    return html`
      <table id="verification-table">
        <tbody>
          ${this._verificationItems.map(({
            keyType, keyValue, isPrimary, typeIcon, statusIcon, resendIcon,
            typeLabel, statusLabel, isVerified, status,
          }) => html`
            <tr class="${keyType} ${isPrimary ? 'primary' : ''}">
              <td class="prefix">
                <sl-tooltip content="${typeLabel}">
                  <sl-icon library="material" name="${typeIcon}"></sl-icon>
                  ${!isPrimary ? null : html`
                    <sl-icon library="material" name="star"></sl-icon>
                  `}
                </sl-tooltip>
              </td>
              
              <td class="value">
                <span class="key-value">${keyValue}</span>
              </td>
              
              <td class="suffix">
                <sl-tooltip content="${statusLabel}">
                  <sl-icon library="material" name="${statusIcon}" class="${status}"></sl-icon>
                </sl-tooltip>
              </td>
              
              <td class="actions">

                ${isVerified ? null : html`
                  <sl-tooltip content="Enter verification code now">
                    <sl-button
                      size="small"
                      pill
                      outline
                      @click="${() => this.verify(keyType, keyValue)}"
                    >
                      <sl-icon library="material" name="check"></sl-icon>
                      Verify
                    </sl-button>
                  </sl-tooltip>
                  
                  <sl-tooltip content="Send new verification code">
                    <sl-button
                      size="small"
                      pill
                      outline
                      @click="${() => this.resendCode(keyType, keyValue)}"
                    >
                      <sl-icon library="material" name="${resendIcon}"></sl-icon>
                      Resend
                    </sl-button>
                  </sl-tooltip>
                `}

                ${isPrimary ? null : html`
                  ${(!isVerified || this.noChangePrimary) ? null : html`
                    <sl-tooltip content="Set this email as primary">
                      <sl-button
                        size="small"
                        pill
                        outline
                        @click="${() => this.setPrimaryEmail(keyValue)}"
                      >
                        <sl-icon library="material" name="star"></sl-icon>
                        Set Primary
                      </sl-button>
                    </sl-tooltip>
                  `}
                  <sl-tooltip content="Remove this email from your account">
                    <sl-button
                      size="small"
                      pill
                      outline
                      @click="${() => this.removeEmail(keyValue)}"
                    >
                      <sl-icon library="material" name="delete"></sl-icon>
                      Remove
                    </sl-button>
                  </sl-tooltip>
                `}

                ${(keyType !== 'phone') ? null : html`
                  <sl-tooltip content="Change your mobile phone number">
                    <sl-button
                      size="small"
                      pill
                      outline
                      @click="${this.changePhone}"
                    >
                      <sl-icon library="material" name="edit"></sl-icon>
                      Change
                    </sl-button>
                  </sl-tooltip>
                `}

              </td>
            </tr>
          `)}
        </tbody>
      </table>

      <ul id="buttons">
        <li>
          <sl-button @click="${this.refresh}" variant="primary" pill outline>
            <sl-icon library="material" name="refresh" slot="prefix"></sl-icon>
            Refresh Info
          </sl-button>
        </li>
        <li>
          <sl-button @click="${this.addEmail}" variant="primary" pill outline>
            <sl-icon library="material" name="add" slot="prefix"></sl-icon>
            Add Email
          </sl-button>
        </li>
      </ul>
      
      ${!['refreshing', 'processing'].includes(this.viewMode) ? null : html`
        <refreshing-panel>
          <div>
            <sl-spinner></sl-spinner>
            ${this.viewMode === 'refreshing' ? 'Refreshing' : 'Processing'}
          </div>
        </refreshing-panel>
      `}

      ${this.renderVerifyDialog()}
      ${this.renderAddEmailDialog()}
      ${this.renderChangePhoneDialog()}
    `;
  }

  renderVerifyDialog() {
    return html`
      <sl-dialog label="Verify ${this.keyType_label}" id="verify-dialog">
        <p>
          Enter the verification code that was sent to
          <strong>${this.keyValue}</strong>.
        </p>

        <sl-input
          id="verify-code-input"
          name="code"
          @sl-input="${this.handleInput}"
          required
          .value="${this.code}"
          pattern="\\d{6,6}"
          help-text="Six digit numeric code (Ex: '123456')"
        >
          <sl-icon library="material" name="pin" slot="prefix"></sl-icon>
        </sl-input>

        <button-list slot="footer">
          <sl-button
            variant="primary"
            @click="${this.verifySave}"
          >Verify</sl-button>
          <sl-button
            variant="default"
            @click="${this.verifyClose}"
          >Cancel</sl-button>
        </button-list>
      </sl-dialog>
    `;
  }

  renderAddEmailDialog() {
    return html`
      <sl-dialog label="Add Email" id="add-email-dialog">
        <p>
          To link another email that you use for work or professional development,
          please enter it below.
        </p>

        <sl-input 
          id="add-email-input"
          name="newEmail"
          .value="${this.newEmail}"
          @sl-input="${this.handleInput}"
          type="email"
          label="Additional Email" 
          required
        >
          <sl-icon library="material" name="mail" slot="prefix"></sl-icon>
        </sl-input>

        <sl-input 
          id="add-email-password-input"
          name="confirmPassword"
          .value="${this.confirmPassword}"
          @sl-input="${this.handleInput}"
          label="Confirm Password" 
          type="password"
          required
          minlength="6"
          password-toggle
          help-text="Confirm your password to add another email to your account"
        >
          <sl-icon library="material" name="key" slot="prefix"></sl-icon>
        </sl-input>

        <button-list slot="footer">
          <sl-button
            variant="primary"
            @click="${this.addEmailSave}"
          >Save</sl-button>
          <sl-button
            variant="default"
            @click="${this.addEmailClose}"
          >Cancel</sl-button>
        </button-list>
      </sl-dialog>
    `;
  }

  renderChangePhoneDialog() {
    return html`
      <sl-dialog label="Change Phone" id="change-phone-dialog">
        <p>
          To change your primary phone please enter it below.
        </p>

        <q1-tel-input
          id="change-phone-input"
          name="newPhone"
          .value="${this.newPhone}"
          @input="${this.handleInput}"
          label="Mobile Phone"
          help-text=${PHONE_HELP_TEXT}
          required
        ></q1-tel-input>

        <button-list slot="footer">
          <sl-button
            variant="primary"
            @click="${this.changePhoneSave}"
          >Save</sl-button>
          <sl-button
            variant="default"
            @click="${this.changePhoneClose}"
          >Cancel</sl-button>
        </button-list>
      </sl-dialog>
    `;
  }

}

customElements.define('q1-verification-info', Q1VerificationInfo);
