import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { homeSignedInViewStyles } from './home-signed-in-view-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import { Q1TextMorph } from '../../components/q1-text-morph/q1-text-morph.js';

const firstGreeting = 'Welcome to the q1 network!';
const allGreetings = [
  "Welcome back to the network!",
  "Howdy! It's great to see you again.",
  "Glad to have you with us today!",
  "Welcome! Let's get things moving.",
  "Welcome back! Let's make it count.",
  "Good to see you back in action!",
  "Hello! Let's dive in.",
  "Welcome! Let's create something amazing.",
  "Greetings! Your network awaits.",
  "It's wonderful to have you back.",
  "Hello again! Time for some work joy.",
  "It's great to have you here!",
  "Welcome back! Let's make progress.",
  "Good to see you! Let's get started.",
  "Welcome! Let's make great things happen.",
  "Hi! Let's connect and collaborate.",
];

export class HomeSignedInView extends ViewBase {
  static styles = [
    ...super.styles,
    homeSignedInViewStyles,
  ];

  static properties = {
    ...super.properties,
    
    greeting: { type: String },
  };

  constructor() {
    super();

    this.greeting = firstGreeting;
  }

  connectedCallback() {
    super.connectedCallback();

    this.setDocumentTitle('q1 Network Home');

    this.setupDayAndGreeting();
  }

  setupDayAndGreeting() {
    const today = this._dayAsNumber();
    let firstDay = JSON.parse(localStorage.getItem('firstDay'));

    // First check for the first day
    if (!firstDay) {
      firstDay = today;
      localStorage.setItem('firstDay', firstDay);
    }
    if (today === firstDay) return; // keep firstGreeting value

    // Then check for same day
    let lastDay = JSON.parse(localStorage.getItem('lastDay')) || today;
    let lastGreeting = localStorage.getItem('lastGreeting');
    if ((lastDay === today) && lastGreeting?.length) {
      this.greeting = lastGreeting;
      return;
    }

    // Else today is a new day! Time for a new greeting.
    lastDay = today;
    let remainingGreetings;
    try {
      remainingGreetings = JSON.parse(localStorage.getItem('remainingGreetings') || '[]');
    } catch (error) {
      console.log(`Error selecting random greeting: ${error.message}`);
    }
    if (!remainingGreetings?.length) remainingGreetings = [...allGreetings];

    const randomIndex = Math.floor(Math.random() * remainingGreetings.length);
    lastGreeting = remainingGreetings.splice(randomIndex, 1)[0];
    this.greeting = lastGreeting;

    // Now update local storage
    localStorage.setItem('lastDay', lastDay);
    localStorage.setItem('lastGreeting', lastGreeting);
    localStorage.setItem('remainingGreetings', JSON.stringify(remainingGreetings));
  }

  _dayAsNumber() {
    return Math.floor(Date.now() / (1000 * 60 * 60 * 24));
  }

  get contentTemplate() {
    return html`
      <h1>${this.greeting}</h1>

      <hr/>

      <p class="subtitle">
        The q1 Network is under active development! New functionality is rolling out every few days.
        This current release is still early and has limited functionality.
        <br/><br/>
        <strong>We're getting closer to the "public beta"!</strong>
        It should be live by early February. In the meantime, please start inviting your 
        collaborators to get setup in the network.
        <br/><br/>
        If you have any questions please reach out to 
        <a href="mailto:team@quorum.one">team@quorum.one</a>.
      </p>
      
      <hr/>

      <h2>Latest Beta Update (Tuesday, Jan 14):</h2>

      <ul>
        <li>
          <strong>✨ New Feature:</strong> The <a href="/members">member browser</a> is live! 
          For now you can browse other members by location, member level, and join date. 
          Please head over to <a href="/settings">your profile settings</a> 
          and add any of the locations where you've lived, worked, or built a significant network.
        </li>
        <li>
          <strong>✨ New Feature:</strong> Member profiles are now working.
          You can configure your profile by going to <a href="/settings">settings</a>,
          and view your profile <a href="/@${this.currentMember.number}">here</a>.
        </li>
        <li>
          <strong>✨ New Feature:</strong> Improved member settings view.
          Click your avatar in the upper right corner and select "Settings".
        </li>
        <li>
          This current release is still early. In this release you cannot join
          projects or guilds or anything like that.
        </li>
        <li>
          A lot of functionality is happening behind the scenes as the foundation team works with
          legacy members and launch partners to get things setup in the new system.
        </li>
        <li>
          The next phase of the beta will allow a lot more interaction and will be ready soon.
        </li>
        <li>
          In the meantime, if you want to get more involved / have questions / etc: Please email the 
          foundation team at <a href="mailto:team@quorum.one">team@quorum.one</a>. We can add you
          to a shared Slack channel for coordination if needed, just reach out and ask.
        </li>
      </ul>
    `;
  }
}

customElements.define('home-signed-in-view', HomeSignedInView);
