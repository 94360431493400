import { html } from 'lit';
import { ViewBase } from '../view-base.js';
import { adminMembersViewStyles } from './admin-members-view-styles.js';
import { functions, httpsCallable } from '../../firebaseConfig.js';

import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/select/select.js';
import '@shoelace-style/shoelace/dist/components/option/option.js';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js';
import '@shoelace-style/shoelace/dist/components/menu/menu.js';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item.js';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js';

import { Q1SpinnerPanel } from '../../components/q1-spinner-panel/q1-spinner-panel.js';

import { q1AdminMemberFunctions } from '../../modules/q1-admin-functions.js';

import {
  db, doc, updateDoc, getDoc, deleteDoc, collection, onSnapshot,
  query, orderBy, limit,
} from '../../firebaseConfig.js';

export class AdminMembersView extends ViewBase {
  static styles = [
    ...super.styles,
    adminMembersViewStyles,
  ];

  static properties = {
    ...super.properties,
    memberRecords: { type: Array },
    memberRecord: { type: Object },
    viewMode: { type: String }, 
    toastMessage: { type: String },
    alertVisible: { type: Boolean },
    alertType: { type: String },
    isLoading: { type: Boolean },
    projects: { type: Array },
    guilds: { type: Array },
    isSuper: { type: Boolean },
  };

  constructor() {
    super();
    this.memberRecords = [];
    this.memberRecord = {};
    this.viewMode = 'details';
    this.toastMessage = '';
    this.alertVisible = false;
    this.alertType = 'info';
    this.isLoading = false;
    this.projects = [];
    this.guilds = [];
    this.isSuper = false;
  }

  getNameById(id, list) {
    const item = list.find((item) => item.id === id);
    return item ? item.name : 'None';
  }

  connectedCallback() {
    super.connectedCallback();
    this.setDocumentTitle('Q1 ADMIN: Members');

    const appElement = document.querySelector('q1-home');
    this.isSuper = appElement?.currentMember?.adminLevel === 'super';

    this.refreshMembers();
    this.initLists();
  }

  async initLists() {
    const getProjects = httpsCallable(functions, 'list-getProjects');
    const getGuilds = httpsCallable(functions, 'list-getGuilds');

    try {
      const projectsResult = await getProjects();
      this.projects = projectsResult.data.projects || [];

      const guildsResult = await getGuilds();
      this.guilds = guildsResult.data.guilds || [];
    } catch (error) {
      console.log('Error getting lists:', error.message);
      this.showToast('', 'Error loading reference data', 'danger');
    }
  }

  async resendVerificationCode(keyType, keyValue) {
    const resetVerificationCode = httpsCallable(
      functions, 'registration-resetVerificationCode'
    );

    if (!window.confirm(
      `This will reset the verification code for ${keyValue} and send the member the new code via ${keyType}.`
      + `\n\nYou'll get a copy of the code and can share it directly if desired.`
    )) return;

    try {
      const result = await resetVerificationCode({ keyType, keyValue });
      this.showToast(
        'Here is the verification link, if you\'d like to share it directly: '
        + result?.data?.verificationLink,
        result?.data?.message,
        'success',
        15000
      );
    } catch (error) {
      this.showToast('', 'Error sending verification email!', 'warning');
    }
  }

  refreshMembers() {
    this.isLoading = true;
    this.memberRecords = [];

    const membersCollection = collection(db, 'members');
    const membersQuery = query(membersCollection, orderBy('nameExternal'), limit(100));
    
    onSnapshot(membersQuery, (snapshot) => {
      this.memberRecords = snapshot.docs.map((doc) => ({
        ref: doc.ref,
        data: {
          id: doc.id,
          ...doc.data(),
        },
      }));
      
      // console.log('Updated memberRecords:', this.memberRecords);
      this.isLoading = false;
      this.requestUpdate();
    }, (error) => {
      console.error('Error fetching members:', error);
      this.showToast('', 'Error Loading Members', 'danger');
      this.isLoading = false;
    });
  }

  async openMemberDetails(memberRecord) {
    try {
      onSnapshot(memberRecord.ref, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const freshData = docSnapshot.data();

          this.memberRecord = {
            ref: memberRecord.ref,
            data: {
              nameInternal: freshData.nameInternal || '',
              nameExternal: freshData.nameExternal || '',
              number: freshData.number || '',
              level: freshData.level || '',
              nameLegal: freshData.nameLegal || '',
              emailPrimary: freshData.emailPrimary || '',
              phoneMobile: freshData.phoneMobile || '',
              linkedinProfileUrl: freshData.linkedinProfileUrl || '',
              linkedinProfileKey: freshData.linkedinProfileKey || '',
              emailsAll: freshData.emailsAll || [],
              status: freshData.status || '',
              onboardStatus: freshData.onboardStatus || '',
              adminLevel: freshData.adminLevel || '',
              referrerMember: freshData.referrerMember || '',
              referrerProject: freshData.referrerProject || '',
              referrerGuild: freshData.referrerGuild || '',
              referrerProjectName: this.getNameById(freshData.referrerProject, this.projects),
              referrerGuildName: this.getNameById(freshData.referrerGuild, this.guilds),
              emailsVerified: freshData.emailsVerified || [],
              emailsUnverified: freshData.emailsUnverified || [],
              agreementMemberTimestamp: freshData.agreementMemberTimestamp || '',
              agreementMemberMeta: freshData.agreementMemberMeta || {},
              authUserPrimaryId: freshData.authUserPrimaryId || '',
              authUserIds: freshData.authUserIds || [],
            },
          };
  
          this.viewMode = 'details';
          this.requestUpdate();
        } else {
          console.warn('Document does not exist for real-time listener.');
        }
      });
  
      this.shadowRoot.querySelector('#member-details-dialog').show();
    } catch (error) {
      console.error('Error setting up real-time listener for member data:', error);
      this.showToast('', 'Error loading member details', 'danger');
    }
  }

  async updateMember() {
    try {

      await updateDoc(this.memberRecord.ref, this.memberRecord.data);
      this.showToast('Member updated successfully!', null, 'success');

      const updatedSnapshot = await getDoc(this.memberRecord.ref);
      this.memberRecord.data = {
        ...updatedSnapshot.data(),
      };

      this.requestUpdate();
      this.viewMode = 'details';
    } catch (error) {
      this.showToast('', 'Error updating member.', 'danger', 10000);
      console.error('Error updating member:', error);
    }
  }

  async deleteMember() {
    if (!window.confirm('Are you sure you want to delete this member? This action cannot be undone.')) {
      return;
    }

    try {
      await deleteDoc(this.memberRecord.ref);
      this.showToast('Member deleted successfully!', null, 'success');
      this.shadowRoot.querySelector('#member-details-dialog').hide();
    } catch (error) {
      this.showToast('', 'Error deleting member.', 'danger', 10000);
    }
  }

  handleMenuSelect(event) {
    event.preventDefault();
    const menuItemValue = event.detail?.item?.value;

    switch (menuItemValue) {
      case 'refreshLocations':
        this.refreshLocationsForAllMembers();
        break;
      
      default:
        console.log(`INVALID menu select item value: ${menuItemValue}`);
    }
  }

  refreshLocationsForAllMembers() {
    if ('refresh all' !== prompt(
      'ARE YOU SURE you want to refresh the location info for all members?\n\n'
      + 'This is not a destructive action, but it does update every record.\n\n'
      + 'To confirm, please type "refresh all" below.'
    )) {
      alert('Location refresh aborted!');
      return;
    }

    const { allMemberLocationRefresh } = q1AdminMemberFunctions;

    this.showToast('All Member Location Refresh Started');
    this.isLoading = true;

    allMemberLocationRefresh({}).then((response) => {
      const { message, resultCode } = response.data || {};
      this.showToast(message, `Location Refresh Complete (${resultCode})`, 'success');
      this.isLoading = false;
    }).catch((error) => {
      console.log('allMemberLocationRefresh: Error', error);
      this.showToast(error.message, 'Location Refresh Error', 'danger');
      this.isLoading = false;
    });
  }

  switchToEditMode() {
    this.viewMode = 'edit';
  }

  handleInput(event) {
    const fieldName = event.target.name || event.target.id;
    const fieldValue = event.target.value;

    if (fieldName) {
      if (fieldValue === "") {
        this.memberRecord.data[fieldName] = null;
      } else {
        this.memberRecord.data[fieldName] = fieldValue;
      }

      if (fieldName === 'phoneMobile') {
        this.memberRecord.data[fieldName] = fieldValue.trim();
      }
    }
  }

  get contentTemplate() {
    return html`
      <h1>Member Update Admin Portal</h1>

      <button-bar>
        <sl-button @click=${this.refreshMembers}>
          <sl-icon library="material" name="refresh" slot="prefix"></sl-icon>
          Refresh
        </sl-button>
  
        <sl-dropdown>
          <sl-button slot="trigger" caret>
            <sl-icon library="material" name="settings" slot="prefix"></sl-icon>
            Admin Functions
          </sl-button>
          <sl-menu @sl-select=${this.handleMenuSelect}>
            <sl-menu-item value="refreshLocations">All Members: Refresh Locations</sl-menu-item>
          </sl-menu>
        </sl-dropdown>
      </button-bar>


      <content-container>
        ${!!this.memberRecords.length ? this.renderMembersTable() : html`
          <p class="subtitle">
            No member records yet.
          </p>
        `}
        
        <q1-spinner-panel label="Loading..." no-bg ?open=${this.isLoading}></q1-spinner-panel>
      </content-container>
      
      <sl-dialog label='Member Details' id='member-details-dialog'>
        ${this.memberRecord?.data ? this.renderDialogContent() : ''}
        ${this.viewMode === 'details' ? html`
          <sl-button slot='footer' variant='primary' @click="${this.switchToEditMode}">
            Edit
          </sl-button>
        ` : html`
          <sl-button slot='footer' variant='primary' @click="${this.updateMember}">
            Save
          </sl-button>
          <sl-button slot='footer' variant='danger' @click="${this.deleteMember}">
            Delete
          </sl-button>
        `}
        <sl-button
          slot='footer'
          variant='default'
          @click="${() => this.shadowRoot.querySelector('#member-details-dialog').hide()}"
        >
          Close
        </sl-button>
      </sl-dialog>
    `;
  }

  renderMembersTable() {
    return html`
      <table>
        <thead>
          <tr>
            <th>Profile Name</th>
            <th>Number</th>
            <th>Level</th>
            <th>Status</th>
            <th>Primary Email</th>
            <th>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          ${this.memberRecords.map(
            (memberRecord) => html`
              <tr>
                <td>${memberRecord?.data?.nameExternal}</td>
                <td>${memberRecord?.data?.number}</td>
                <td>${memberRecord?.data?.level}</td>
                <td>${memberRecord?.data?.status}</td>
                <td>${memberRecord?.data?.emailPrimary}</td>
                <td>${memberRecord?.data?.betaLocation}</td>
                <td>
                  <sl-button
                    size="small"
                    @click="${() => this.openMemberDetails(memberRecord)}"
                  >
                    View
                  </sl-button>
                </td>
              </tr>
            `
          )}
        </tbody>
      </table>
    `;
  }

  renderDetailedView() {
    return html`
    <div class='form-group'>
      <table>
        <tbody>
          <h3>Basic Info</h3>
          <tr>
            <td><strong>Display Name:</strong></td>
            <td>${this.memberRecord?.data?.nameInternal}</td>
          </tr>
          <tr>
            <td><strong>Profile Name:</strong></td>
            <td>${this.memberRecord?.data?.nameExternal}</td>
          </tr>
          <tr>
            <td><strong>Number:</strong></td>
            <td>${this.memberRecord?.data?.number}</td>
          </tr>
          <tr>
            <td><strong>Level:</strong></td>
            <td>${this.memberRecord?.data?.level}</td>
          </tr>
          <tr>
            <td><strong>Legal Name:</strong></td>
            <td>${this.memberRecord?.data?.nameLegal}</td>
          </tr>
          <tr>
            <td><strong>Primary Email:</strong></td>
            <td>
              ${this.memberRecord?.data?.emailPrimary}
              <sl-tooltip content="New Verification Code & Email">
                <sl-icon-button
                  library="material"
                  name="published_with_changes"
                  @click="${() => this.resendVerificationCode(
                    'email', this.memberRecord?.data?.emailPrimary
                  )}"
                  class="admin-view-button"
                ></sl-icon-button>
              </sl-tooltip>
            </td>
          </tr>
          <tr>
            <td><strong>Mobile Phone:</strong></td>
            <td>${this.memberRecord?.data?.phoneMobile}</td>
          </tr>
          <tr>
            <td><strong>LinkedIn Profile URL:</strong></td>
            <td>${this.memberRecord?.data?.linkedinProfileUrl}</td>
          </tr>
          <tr>
            <td><strong>LinkedIn Profile Key:</strong></td>
            <td>${this.memberRecord?.data?.linkedinProfileKey}</td>
          </tr>
          <tr>
            <td><strong>All Emails:</strong></td>
            <td><ul>${(this.memberRecord?.data?.emailsAll || []).map(email => html`
              <li>
                ${email}
                <sl-tooltip content="New Verification Code & Email">
                  <sl-icon-button
                    library="material"
                    name="published_with_changes"
                    @click="${() => this.resendVerificationCode('email', email)}"
                    class="admin-view-button"
                  ></sl-icon-button>
                </sl-tooltip>
              </li>
            `)}</ul></td>
          </tr>
        </tbody>
      </table>    
      <table>
        <tbody>
          <h3>Admin Details</h3>
            <tr>
              <td><strong>Admin Level:</strong></td>
              <td>${this.memberRecord?.data?.adminLevel}</td>
            </tr>
            <tr>
              <td><strong>Status:</strong></td>
              <td>${this.memberRecord?.data?.status}</td>
            </tr>
            <tr>
              <td><strong>Onboard Status:</strong></td>
              <td>${this.memberRecord?.data?.onboardStatus}</td>
            </tr>
            <tr>
              <td><strong>Referrer Member:</strong></td>
              <td>${this.memberRecord?.data?.referrerMember}</td>
            </tr>
            <tr>
              <td><strong>Referrer Project:</strong></td>
              <td>${this.getNameById(this.memberRecord?.data?.referrerProject, this.projects)}</td>
            </tr>
            <tr>
              <td><strong>Referrer Guild:</strong></td>
              <td>${this.getNameById(this.memberRecord?.data?.referrerGuild, this.guilds)}</td>
            </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <h3>System Fields</h3> 
            <tr>
              <td><strong>Emails Verified:</strong></td>
              <td>${(this.memberRecord?.data?.emailsVerified || []).join(', ')}</td>
            </tr>
            <tr>
              <td><strong>Unverified Emails:</strong></td>
              <td>${(this.memberRecord?.data?.emailsUnverified || []).join(', ')}</td>
            </tr>
            <tr>
              <td><strong>Member Agreement Timestamp:</strong></td>
              <td>${this.memberRecord?.data?.agreementMemberTimestamp}</td>
            </tr>
            <tr>
              <td><strong>Member Agreement Metadata:</strong></td>
              <td>${JSON.stringify(this.memberRecord?.data?.agreementMemberMeta)}</td>
            </tr>
            <tr>
              <td><strong>Primary Auth User ID:</strong></td>
              <td>${this.memberRecord?.data?.authUserPrimaryId}</td>
            </tr>
            <tr>
              <td><strong>Auth User IDs:</strong></td>
              <td>${(this.memberRecord?.data?.authUserIds || []).join(', ')}</td>
            </tr>
        </tbody>
      </table>
  </div>
    `;
  }

  renderEditForm() {
    return html`
      <form>

        <h3>Basic Info</h3>

        <sl-input 
          id="nameInternal" 
          name="nameInternal" 
          .value="${this.memberRecord?.data?.nameInternal}"
          @sl-input="${this.handleInput}"
          label="Display Name (Internal)" 
          required
        ></sl-input>

        <sl-input 
          id="nameExternal" 
          name="nameExternal" 
          .value="${this.memberRecord?.data?.nameExternal}"
          @sl-input="${this.handleInput}"
          label="Profile Name (External)" 
        ></sl-input>

        <sl-select 
          id="level"
          name="level"
          value="${this.memberRecord?.data?.level}"
          @sl-input="${this.handleInput}"
          label="Level"
          required
          >
            <sl-option value="network">Network</sl-option>
            <sl-option value="contributor">Contributor</sl-option>
            <sl-option value="foundation">Foundation</sl-option>
            <sl-option value="steward">Steward</sl-option>
        </sl-select>

        <sl-input 
          id="nameLegal" 
          name="nameLegal" 
          .value="${this.memberRecord?.data?.nameLegal}"
          @sl-input="${this.handleInput}"
          label="Legal Name"
          required
        ></sl-input>

        <sl-input 
          id="phoneMobile" 
          name="phoneMobile" 
          .value="${this.memberRecord?.data?.phoneMobile}"
          @sl-input="${this.handleInput}"
          label="Mobile Phone"
          required
        ></sl-input>

        <sl-input
          id="linkedinProfileUrl"
          name="linkedinProfileUrl"
          .value="${this.memberRecord?.data?.linkedinProfileUrl}"
          @sl-input="${this.handleInput}"
          label="LinkedIn Profile URL"
          required
        ></sl-input>

        <h3>Admin Details</h3>

        ${this.isSuper ? html`
        <sl-select 
          id="adminLevel"
          name="adminLevel"
          value="${this.memberRecord?.data?.adminLevel}"
          @sl-input="${this.handleInput}"
          label="Admin Level"
          required
        >
          <sl-option value="none">None</sl-option>
          <sl-option value="admin">Admin</sl-option>
          <sl-option value="super">Super</sl-option>
        </sl-select>
        ` : ''}

        <sl-select 
          id="status"
          name="status"
          value="${this.memberRecord?.data?.status}"
          @sl-input="${this.handleInput}"
          label="Status"
          required
          >
            <sl-option value="provisioned">Provisioned</sl-option>
            <sl-option value="registered">Registered</sl-option>
            <sl-option value="confirmed">Confirmed</sl-option>
            <sl-option value="onboarded">Onboarded</sl-option>
            <sl-option value="deactivated">Deactivated</sl-option>
            <sl-option value="deleted">Deleted</sl-option>
        </sl-select>

        <sl-select 
          id="onboardStatus"
          name="onboardStatus"
          value="${this.memberRecord?.data?.onboardStatus}"
          @sl-input="${this.handleInput}"
          label="Onboard Status"
          required
          >
            <sl-option value="none">None</sl-option>
            <sl-option value="step-1-complete">Step 1 Complete</sl-option>
            <sl-option value="step-2-complete">Step 2 Complete</sl-option>
            <sl-option value="step-3-complete">Step 3 Complete</sl-option>
            <sl-option value="complete">Complete</sl-option>
        </sl-select>

        <!-- <sl-input
          id="referrerMember"
          name="referrerMember"
          .value="${this.memberRecord?.data?.referrerMember}"
          @sl-input="${this.handleInput}"
          label="Referrer Member"
          required
        ></sl-input>

        <sl-select 
          id="referrerProject"
          name="referrerProject"
          value="${this.memberRecord?.data?.referrerProject}"
          @sl-input="${this.handleInput}"
          label="Referrer Project"
          required
        >
        <sl-option value="">None</sl-option>
        ${this.projects.map(
          (project) => html`<sl-option value="${project.id}">${project.name}</sl-option>`
        )}
        </sl-select>

        <sl-select
          id="referrerGuild"
          name="referrerGuild"
          value="${this.memberRecord?.data?.referrerGuild}"
          @sl-input="${this.handleInput}"
          label="Referrer Guild"
          required
        >
        <sl-option value="">None</sl-option>
        ${this.guilds.map(
          (guild) => html`<sl-option value="${guild.id}">${guild.name}</sl-option>`
        )}
        </sl-select> -->

      </form>
  `;
  }

  renderDialogContent() {
    return this.viewMode === "details"
      ? this.renderDetailedView()
      : this.renderEditForm();
  }

  renderToast() {
    return html`
      <sl-toast id="notification-toast" duration="4000">
        ${this.toastMessage}
      </sl-toast>
    `;
  }

}

customElements.define('admin-members-view', AdminMembersView);
