import { html, css, LitElement } from 'lit';

import { q1AppFooterStyles } from './q1-app-footer-styles.js';

import '@shoelace-style/shoelace/dist/components/icon/icon.js';

export class Q1AppFooter extends LitElement {
  static styles = [ q1AppFooterStyles ];

  static properties = {
    variant: { type: String, reflect: true }, // default, white-text
  };

  constructor() {
    super();

    this.variant = 'default';
  }

  render() {
    return html`
      <footer-inner part="base">
        <sl-icon library="q1" name="q1-logo-text-r" class="logo"></sl-icon>
        <p>
          The q1 Network is a part of <a href="https://quorum.one" target="_blank">Quorum1</a>,
          a member-owned collective pioneering the future of work.
        </p>
        <ul id="footer-links">
          <li><a target="_blank"
            href="https://github.com/quorum1/governance/blob/main/docs/privacy-policy.md"
          >Privacy</a></li>

          <li><a target="_blank"
            href="https://github.com/quorum1/governance/blob/main/docs/terms-of-service.md"
          >Terms</a></li>

          <li><a target="_blank"
            href="https://github.com/quorum1/governance"
          >Governance</a></li>
        </ul>
        
        <ul id="social-links">
          <li><a target="_blank"
            href="https://linkedin.com/company/quorum1"
          ><sl-icon library="tabler" name="brand-linkedin"></sl-icon></a></li>
          
          <li><a target="_blank"
            href="https://www.youtube.com/@quorum_1"
          ><sl-icon library="tabler" name="brand-youtube"></sl-icon></a></li>
          
          <li><a target="_blank"
            href="https://lu.ma/quorum1"
          ><sl-icon library="material" name="calendar_month"></sl-icon></a></li>
          
          <li><a target="_blank"
            href="https://github.com/quorum1"
          ><sl-icon library="tabler" name="brand-github"></sl-icon></a></li>
          
          <li><a target="_blank"
            href="https://open.spotify.com/show/6hIYwyBBCDmzOMob1sKInu"
          ><sl-icon library="tabler" name="brand-spotify"></sl-icon></a></li>
          
          <li><a target="_blank"
            href="https://podcasts.apple.com/us/podcast/quorum1-future-of-work-talks/id1755046941"
          ><sl-icon library="tabler" name="brand-apple-podcast"></sl-icon></a></li>
        </ul>
      </footer-inner>
    `;
  }
}

customElements.define('q1-app-footer', Q1AppFooter);
