import { css } from 'lit';

export const q1TextMorphStyles = css`

  :host {
    display: inline-block;
  }

  slot {
    display: none;
  }
  
  text-container {
    /* Center the text in the viewport. */
    display: inline-block;
    position: relative;
    margin: auto;
    
    filter: url(#threshold);
  }

  /* Your average text styling */
  #text1, #text2 {
    display: inline-block;
    user-select: none;
  }
  #text1 {
    position: absolute;
  }

`;
